export const stringUtils = {
  methods: {
    formatDate (dateNumber) {
      return new Date(dateNumber).toLocaleDateString('it')
    },
    formatDateYearFirst (dateNumber) {
      const d = new Date(dateNumber)
      d.setHours(d.getHours() + 2)
      return d.toISOString().slice(0, 10)
    },
    formatMoney (moneyNumber) {
      moneyNumber /= 100
      return new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      }).format(moneyNumber)
    },
    formatAddress (address) {
      if (address?.address == null) return '-'
      return `${address.address}, ${address.city} (${address.province}), ${address.cap}`
    }
  }
}
