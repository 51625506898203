import Vue from 'vue'
import router from '@/router'
import { preventNextErrDialog } from '@/plugins/axios'

const state = {
  email: null,
  id: null,
  name: null,
  roleCode: null,
  surname: null,
  token: null,
  tokenExpiration: null,
  isMember: null,
  isBusiness: null,
  isIndividualCompany: null,
  registrationComplete: null,
  userRegistrationComplete: false,
  memberRegistrationComplete: false,
  businessRegistrationComplete: false
}

const getters = {
  isLogged: (state) => {
    return state.token !== null
  },
  isAdmin: (state) => {
    return state.roleCode === 'admin'
  },
  registrationComplete: (state) => {
    return state.registrationComplete
  },
  token: (state) => {
    return state.token
  },
  name: (state) => {
    return state.name
  },
  fullName: (state) => {
    return state.name ? `${state.name} ${state.surname}` : ''
  },
  email: (state) => {
    return state.email
  },
  id: (state) => {
    return state.id
  },
  isMember: (state) => {
    return state.isMember
  },
  isBusiness: (state) => {
    return state.isBusiness
  },
  isIndividualCompany: (state) => {
    return state.isIndividualCompany
  },
  userRegistrationComplete: (state) => {
    return state.userRegistrationComplete
  },
  memberRegistrationComplete: (state) => {
    return state.memberRegistrationComplete
  },
  businessRegistrationComplete: (state) => {
    return state.businessRegistrationComplete
  }
}

const mutations = {
  loginUser (state, data) {
    Object.assign(state, data)

    Vue.prototype.$http.defaults.headers = state.token ? {
      Authorization: 'Bearer ' + state.token
    } : {}
  },
  async logoutUser (state) {
    Vue.prototype.$http.defaults.headers = state.token ? {
      Authorization: 'Bearer ' + ''
    } : {}

    Object.keys(state).forEach(key => {
      state[key] = null
    })
    await router.push('/login')
  },

  chooseMember (state, chosen) {
    state.isMember = chosen
  },
  chooseBusiness (state, chosen) {
    state.isBusiness = chosen
  },
  chooseIndividualCompany (state, chosen) {
    state.isIndividualCompany = chosen
  },
  userRegistered (state) {
    state.userRegistrationComplete = true
  },
  memberRegistered (state) {
    state.memberRegistrationComplete = true
  },
  businessRegistered (state) {
    state.businessRegistrationComplete = true
  }
}

const actions = {
  async login ({ state, commit }, { loginData, redirect }) {
    const res = await Vue.prototype.$http.post('/v1/authentication/login', loginData)
    commit('loginUser', res.data)
    await router.push(redirect ?? '/')
  },
  async renewToken ({ getters, commit }) {
    if (!getters.isLogged) return

    try {
      preventNextErrDialog()
      const res = await Vue.prototype.$http('/v1/authentication/renew')
      commit('loginUser', res.data)
    } catch (e) {
      if (e.response.status === 403) commit('logoutUser')
    }
  },
  async chooseMember ({ state, commit }, chosen) {
    await Vue.prototype.$http.get('/v1/users/register/member/' + chosen)
    commit('chooseMember', chosen)
  },
  async chooseBusiness ({ state, commit }, chosen) {
    await Vue.prototype.$http.get('/v1/users/register/business/' + chosen)
    commit('chooseBusiness', chosen)
  },
  async chooseIndividualCompany ({ state, commit }, chosen) {
    await Vue.prototype.$http.get('/v1/users/register/individualCompany/' + chosen)
    commit('chooseIndividualCompany', chosen)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
