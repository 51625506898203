<template>
  <div id="create-product" class="mx-10">
    <h1 class="text-center display-3 mt-6 mb-10"
        v-text="editMode ? 'Modifica paniere' : 'Crea un paniere'"
    />

    <v-card max-width="90em" class="mx-auto" outlined>
      <v-row class="spacing-playground pa-6">
        <v-col cols="12" lg="12">
          <span class="ml-1 text-left font-weight-bold">Nome</span>
          <v-text-field
              class="mt-1"
              v-model="paniere.name"
              label=""
              solo
              placeholder="Nome"
              cols="12"
              md="3"
              lg="4"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <span class="ml-1 text-left font-weight-bold">Data ultimo giorno per ordinare</span>
          <v-menu
              ref="expirationDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
              solo
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mt-1"
                  v-model="paniere.expirationDate"
                  hint="Formato YYYY-MM-DD"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="paniere.expirationDate = parseDate(paniere.expirationDate)"
                  v-on="on"
                  solo
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="paniere.expirationDate"
                no-title
                :first-day-of-week="1"
                locale="it-it"
                @input="expirationDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" lg="6">
          <span class="ml-1 text-left font-weight-bold">Data consegna</span>
          <v-menu
              ref="deliveryDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mt-1"
                  v-model="paniere.deliveryDate"
                  hint="Formato YYYY-MM-DD"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="paniere.deliveryDate = parseDate(paniere.deliveryDate)"
                  v-on="on"
                  solo
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="paniere.deliveryDate"
                no-title
                :first-day-of-week="1"
                locale="it-it"
                @input="deliveryDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" lg="12" v-if="suppliers">
          <span class="ml-1 text-left font-weight-bold">Seleziona fornitori</span>
          <v-alert v-if="!suppliers.length">
            Non sono presenti fornitori con almeno un prodotto! Aggiungi prima tutti i prodotti necessari e riprovare.
          </v-alert>
          <v-list>
            <v-list-item
                v-for="(item, id) in suppliers"
                :key="id"
                @click="clickSupplierRow(item)"
            >
              <template>
              </template>
              <v-list-item-content>
                <v-list-item-title v-text="item.businessName"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip v-if="getSupplierSelectedProductsNum(item.id)"
                        color="green"
                        outlined
                        class="font-weight-bold">
                  {{ getSupplierSelectedProductsNum(item.id) }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn :disabled="!isActive" color="primary" class="mx-auto mb-4" @click="savePaniere">
          Salva paniere
        </v-btn>
      </v-card-actions>
    </v-card>

    <SelectSupplierProducts v-model="supplierProductToShow" :edit-mode="editMode" :paniere-to-edit-id="paniereToEditId"/>
  </div>
</template>

<script>

import SelectSupplierProducts from '@/components/PaniereSelectSupplierProducts/SelectSupplierProducts'
import { stringUtils } from '@/mixins/stringUtils'

export default {
  name: 'CreatePaniere',
  components: { SelectSupplierProducts },
  mixins: [stringUtils],
  data () {
    return {
      expirationDateMenu: false,
      deliveryDateMenu: false,
      paniereToEditId: null,
      paniere: {
        deliveryDate: null,
        expirationDate: null,
        name: null,
        supplierProducts: []
      },
      // This variable contains the suppliers that have at least one product selected.
      // Each supplier has a map of supplierProducts (with the id as key) -> it is used in the SelectSupplierProducts component
      supplierProductsBySupplier: {},
      supplierProductToShow: null,
      suppliers: null,
      isActive: true
    }
  },
  computed: {
    editMode () {
      return !!this.$route.meta.editMode
    }
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    }
  },
  async mounted () {
    if (this.editMode)
      await this.getPaniereBaseInfo()

    // Get suppliers method is returning the list instead of setting component data because when the list of suppliers
    // is rendered, the method usePaniereAsTemplate() must have finished.
    // In order to improve performance, both methods are called in parallel.
    const panAsTemplatePromise = this.usePaniereAsTemplate()
    const suppliers = this.getSuppliers()

    await panAsTemplatePromise
    this.suppliers = await suppliers
  },
  methods: {
    async getPaniereBaseInfo () {
      const paniereId = this.$route.params.paniereId
      const res = await this.$http.get(`v1/panieri/${paniereId}/base-info`)
      const paniere = res.data
      this.paniereToEditId = paniere.id
      this.paniere.name = paniere.name
      this.paniere.expirationDate = this.parseDate(paniere.expirationDate)
      this.paniere.deliveryDate = this.parseDate(paniere.deliveryDate)
    },
    async usePaniereAsTemplate () {
      const paniereId = this.$route.params.paniereId
      if (!paniereId) return

      const res = await this.$http.get(`v1/panieri/${paniereId}/supplier-products`)
      const paniereProducts = res.data
      paniereProducts.forEach(sp => {
        this.supplierProductsBySupplier[sp.supplierId] = this.supplierProductsBySupplier[sp.supplierId] || {}
        this.supplierProductsBySupplier[sp.supplierId][sp.id] = sp
      })
    },
    async getSuppliers () {
      const res = await this.$http.get('v1/suppliers/with-products')
      return res.data
    },
    clickSupplierRow (supplier) {
      if (!this.supplierProductsBySupplier[supplier.id]) {
        this.supplierProductsBySupplier[supplier.id] = {}
      }

      this.supplierProductToShow = {
        id: supplier.id,
        supplierName: supplier.businessName,
        supplierProducts: this.supplierProductsBySupplier[supplier.id]
      }
    },
    getSupplierSelectedProductsNum (supplierId) {
      return Object.keys(this.supplierProductsBySupplier[supplierId] || {}).length
    },
    parseDate (date) {
      if (!date) return null

      date = this.formatDate(date)
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    buildSupplierProducts () {
      const list = []
      for (const supplierId in this.supplierProductsBySupplier) {
        for (const spId in this.supplierProductsBySupplier[supplierId]) {
          list.push(this.supplierProductsBySupplier[supplierId][spId])
        }
      }
      this.paniere.supplierProducts = list
    },
    async savePaniere () {
      this.isActive = false

      try {
        if (this.editMode)
          await this.$http.put(`/v1/panieri/${this.paniereToEditId}/base-info`, {
            name: this.paniere.name,
            expirationDate: this.paniere.expirationDate,
            deliveryDate: this.paniere.deliveryDate
          })
        else {
          this.buildSupplierProducts()
          await this.$http.post('/v1/panieri', this.paniere)
        }
        await this.$router.push('/amministrazione/panieri/attuali')
      } catch (e) {
        this.isActive = true
      }
    }
  }
}
</script>

<style>
</style>
