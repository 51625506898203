export const usersDataCardFields = {
  methods: {
    nameField (user) {
      if (user?.id == null) return '-'
      const name = user.surname + ' ' + user.name
      if (!user.isBusiness) return name
      else {
        if (user.isIndividualCompany) return name
        else return user.businessName || user.business?.businessName
      }
    },
    businessField (user) {
      if (!user.isBusiness) return '-'
      else {
        if (user.isIndividualCompany) return user.businessName || user.business?.businessName
        else return user.surname + ' ' + user.name
      }
    }
  }
}
