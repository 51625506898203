import store from '@/store'
import axios from 'axios'
import Vue from 'vue'

const headers = store.getters.token ? {
  Authorization: 'Bearer ' + store.getters.token
} : {}

// ---------------- Use localhost setting ----------------
const useApiLocalhostUrl = true

const isLocalhost = location.hostname === 'localhost'
const isDev = location.hostname.startsWith('test.')

const baseURL = (!isLocalhost ? isDev
  ? 'https://test.api.pdo.giize.com' : 'https://api.pdobassogardabio.it'
  : useApiLocalhostUrl
    // ? 'http://192.168.1.132:8000'
    ? 'http://localhost:8000'
    : 'https://test.api.pdo.giize.com'
) + '/api'

const axiosInstance = axios.create({
  baseURL,
  headers
})

const noCodeCallback = () => {}

function showError (text, callback) {
  Vue.prototype.$dialog.confirm({
    title: 'Attenzione!',
    text,
    icon: false,
    type: 'error',
    actions: ['Chiudi']
  })
    .then(callback || noCodeCallback)
    .catch(callback || noCodeCallback)
}

function preventNextErrDialog () {
  preventNextErrorDialog = true
}

let preventNextErrorDialog = false
let errorOpen = false

axiosInstance.interceptors.response.use(
  value => value,
  error => {
    if (!error.response) {
      if (!errorOpen) {
        errorOpen = true
        showError('Nessuna connessione al server!', () => { errorOpen = false })
      }
    } else if (error.response.status === 401 && store.getters.isLogged) {
      // it never happens
      if (!errorOpen) {
        errorOpen = true
        showError("Sessione scaduta! Per favore, rieffettua l'accesso.", () => { errorOpen = false })
        store.commit('logoutUser')
      }
    } else if (error.response.status === 403) {
      if (!errorOpen) {
        errorOpen = true
        showError('Non hai i permessi per eseguire questa azione!', () => {
          errorOpen = false
        })
      }
    } else {
      if (preventNextErrorDialog) {
        preventNextErrorDialog = false
      } else {
        showError(error.response.data.message || 'C\'è stato un errore inaspettato, ci scusiamo per l\'inconveniente!')
      }
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
export { preventNextErrDialog, isLocalhost }
