<template>
  <v-container v-if="payment">
    <h1 class="text-center display-2 font-weight mt-8 mb-6">
      Pagamento del
      <span class="font-weight-bold">{{ formatDate(payment.date) }}</span>
    </h1>

    <v-card v-if="payment.status === 'NOT_PAID'" class="mx-auto my-4 lighten-3" color="grey"
            style="border-radius: 25px; max-width: 70rem" outlined>
      <v-card-title>
        Pagamento non ancora effettuato
      </v-card-title>
      <v-card-text>
        Il pagamento non è ancora stato effettuato. Puoi pagare ora cliccando qui sotto. In alternativa verrà saldato
        automaticamente tra il 03/04/2023 e il 05/04/2023. Per qualsiasi informazione scrivici a
        <a href="mailto:supporto@pdobassogardabio.it">supporto@pdobassogardabio.it</a>
        <div class="text-center">
          <v-btn class="mt-3" color="primary" @click="payNow" :disabled="payNowDisabled" :loading="payNowDisabled">
            Paga ora {{ formatMoney(payment.total) }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="payment.status === 'WAITING_RESULT'" class="mx-auto my-4"
            style="border-radius: 25px; max-width: 70rem" outlined>
      <v-card-title>
        Pagamento in attesa di conferma
      </v-card-title>
      <v-card-text>
        Il pagamento è in attesa di conferma. Ti ricordiamo che gli addebiti SEPA non sono un pagamento immediato e siamo in attesa del suo completamento.
      </v-card-text>
    </v-card>

    <v-card v-if="payment.status === 'NOT_PAID_WAIT_SUPPORT'" class="mx-auto my-4 lighten-3" color="grey"
            style="border-radius: 25px; max-width: 70rem" outlined>
      <v-card-title>
        Pagamento non ancora effettuato
      </v-card-title>
      <v-card-text>
        Il pagamento non è ancora stato effettuato. Per procedere con il pagamento scrivici a
        <a href="mailto:supporto@pdobassogardabio.it">supporto@pdobassogardabio.it</a>
        o attendi una nostra comunicazione.
      </v-card-text>
    </v-card>

    <v-card v-if="['PAID', 'READY_TO_CAPTURE'].includes(payment.status)"
            class="mx-auto my-4" style="border-radius: 25px; max-width: 70rem" outlined>
      <v-card-text>
        A breve sarà visibile qui il metodo di pagamento utilizzato.
      </v-card-text>
    </v-card>

    <v-card v-if="payment.status === 'READY_TO_CAPTURE'" class="mx-auto my-4"
            style="border-radius: 25px; max-width: 70rem" outlined>
      <v-card-title>
        Pagamento bloccato sulla carta ma non ancora acquisito
      </v-card-title>
      <v-card-text>
        <p class="text-subtitle-1">
          Importo bloccato: <span class="font-weight-bold">{{ formatMoney(payment.preAuthorizedAmount) }}</span>
        </p>
        <p>
          L'importo è stato bloccato sulla carta per avere la conferma di addebito sufficiente. Questo è il prezzo
          massimo che potrà esserti addebitato, ma di solito è inferiore. Stiamo pesando i prodotti che hai acquistato
          per farti pagare il prezzo giusto!
        </p>
        <p>
          Attendi che venga completato il tuo ordine per vedere il prezzo definitivo e i dettagli del pagamento.
        </p>
        <p>
          Per qualsiasi informazione scrivici a
          <a href="mailto:supporto@pdobassogardabio.it">supporto@pdobassogardabio.it</a>
        </p>
      </v-card-text>
    </v-card>

    <div v-if="!['READY_TO_CAPTURE'].includes(payment.status)">

      <v-card class="mx-auto my-4" style="border-radius: 25px; max-width: 70rem" outlined>
        <v-card-title>
          Totale consegne
        </v-card-title>
        <v-card-text class="mb-2">
          <v-simple-table>
            <thead>
            <tr>
              <th class="text-left">
                Descrizione
              </th>
              <th class="text-right mr-5">
                <div class="mr-1 mr-md-4">
                  Totale
                </div>
              </th>
            </tr>
            </thead>
            <tbody>

            <!-- Deliveries -->

            <tr
                v-for="(item, i) in payment.deliveries"
                :key="i"
                class="cursor-pointer"
                @click="goDelivery(item.distSessionId)"
            >
              <td>
                <span class="text-caption">Consegna del </span>
                <span class="font-weight-bold">{{ formatDate(item.date) }}</span>
              </td>
              <td class="text-right">
                <div class="mr-1 mr-md-4 mr-lg-6">
                  {{ formatMoney(item.total) }}
                </div>
              </td>
            </tr>

            <!-- Total row -->

            <tr class="green lighten-5">
              <td>
                <h4>Totale consegne</h4>
              </td>
              <td class="text-right">
                <div class="mr-1 mr-md-4 mr-lg-6">
                  <h4>{{ formatMoney(payment.deliveriesTotal) }}</h4>
                </div>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto my-4" style="border-radius: 25px; max-width: 70rem" outlined>
        <v-card-title>
          Totale pagamento
        </v-card-title>
        <v-card-text class="mb-2">
          <v-simple-table>
            <thead>
            <tr>
              <th class="text-left">
                Descrizione
              </th>
              <th class="text-right mr-5">
                <div class="mr-1 mr-md-4">
                  Totale
                </div>
              </th>
            </tr>
            </thead>
            <tbody>

            <tr>
              <td>Totale consegne</td>
              <td class="text-right">
                <div class="mr-1 mr-md-4 mr-lg-6">
                  {{ formatMoney(payment.deliveriesTotal) }}
                </div>
              </td>
            </tr>

            <!-- Additional items -->

            <tr
                v-for="(item, i) in payment.items"
                :key="i + payment.deliveries.length"
            >
              <td>
                {{ item.description }}
              </td>
              <td class="text-right">
                <div class="mr-1 mr-md-4 mr-lg-6">
                  {{ formatMoney(item.amount) }}
                </div>
              </td>
            </tr>

            <!-- Total row -->

            <tr class="green lighten-5">
              <td>
                <h4>Totale pagato</h4>
              </td>
              <td class="text-right">
                <div class="mr-1 mr-md-4 mr-lg-6">
                  <h4>{{ formatMoney(payment.total) }}</h4>
                </div>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>

    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PaymentsDetails',
  data () {
    return {
      payment: null,
      payNowDisabled: false,
      headers: [
        {
          text: 'Descrizione',
          value: 'description'
        },
        {
          text: 'Totale',
          value: 'total',
          align: 'right'
        }
      ]
    }
  },
  mounted () {
    this.loadPayment()
  },
  computed: {
    manyDeliveries () {
      return this.delivery.payment.deliveriesCount > 1
    }
  },
  methods: {
    async loadPayment () {
      const response = await this.$http.get('v1/payments/' + this.$route.params.id)
      this.payment = response.data
    },
    goDelivery (id) {
      this.$router.push('/consegne/' + id)
    },
    async payNow () {
      const oldStatus = this.payment.status
      this.payNowDisabled = true
      const confirm = await this.$dialog.confirm({
        title: 'Conferma pagamento',
        text: `Sei sicuro di voler pagare <b>${this.formatMoney(this.payment.total)}</b>?`,
        cancel: true,
        actions: [
          {
            text: 'Annulla',
            key: 'false', // don't know why, but it works with quotes and without it returns 'Annulla'
            color: ''
          },
          {
            text: 'Conferma',
            key: true,
            color: 'primary',
            outlined: true
          }
        ],
        okText: 'Conferma',
        cancelText: 'Annulla'
      })

      if (!confirm) {
        this.payNowDisabled = false
        return
      }

      await this.$http.put('v1/payments/' + this.payment.id + '/pay', {
        paymentMethodId: null
      }) // todo: add in body paymentMethodId if the user wants to use a different method rather than the default one

      this.$dialog.notify.success("Pagamento effettuato. Attendi qualche istante per vedere l'aggiornamento dello stato").then()

      let tries = 0

      const callback = async () => {
        await this.loadPayment()
        if (this.payment.status !== oldStatus) {
          this.payNowDisabled = false
        } else if (tries < 2) {
          setTimeout(callback, 1000)
        } else if (tries < 5) {
          setTimeout(callback, 2000)
        } else {
          this.payNowDisabled = false
        }
        tries++
      }

      setTimeout(callback, 1000)
    }
  }
}
</script>
