<template>
  <common-details-dialog
    v-model="dialog"
    v-if="dialog"
    :title="'Informazioni fornitore: ' + value && value.businessName"
    :data-to-show="dataToShow"
  ></common-details-dialog>
</template>

<script>
import CommonDetailsDialog from '@/components/CommonDetailsDialog/CommonDetailsDialog'

export default {
  name: 'SupplierDetailsDialog',
  components: { CommonDetailsDialog },
  props: {
    value: Object
  },
  computed: {
    dialog: {
      get () {
        return !!this.value
      },
      set (newVal) {
        if (!newVal) this.$emit('input', null)
      }
    },
    dataToShow () {
      return [
        {
          title: 'Dati del fornitore',
          information: [
            {
              name: 'Ragione sociale',
              value: this.value.businessName
            },
            {
              name: 'Email per invio ordini',
              value: this.value.email || '<i class="text-caption grey--text">Non inserita</i>'
            },
            {
              name: 'Partita IVA',
              value: this.value.partitaIva
            },
            {
              name: 'Codice fiscale',
              value: this.value.fiscalCode
            },
            {
              name: 'Codice univoco',
              value: this.value.codiceUnivoco
            },
            {
              name: 'Sede legale',
              value: `${this.value.address}, ${this.value.city} (${this.value.province}) - ${this.value.cap}`
            }
          ]
        }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>

</style>
