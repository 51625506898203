<template>
  <v-card max-width="55em"
          class="mx-auto mt-6 mb-12"
          outlined
  >
    <v-card-title class="text-h4">
      {{ title }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-row>
        <slot>
          <text-field
            v-for="field in fields"
            :key="field.value"
            v-model="data[field.value]"
            :label="field.label"
            :placeholder="field.placeholder"
            :rules="field.rules"
            :type="field.type"
            :cols="field.cols ? field.cols : '12'"
            :sm="field.sm"
            :md="field.md"
            :lg="field.lg"
            :append-icon="field.appendIcon"
            @click:append="showPassword = !showPassword"
            :help-text="field.helpText"
            @input="atInput(field.atInput)"
          >
            <template v-slot:default="params">
              <slot :name="'field-' + field.value" v-bind="{ value: field.value, ...params }"></slot>
            </template>
          </text-field>
        </slot>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FormCard',
  props: {
    title: {
      type: String,
      required: true
    },
    data: Object,
    fields: Array
  },
  methods: {
    atInput (cb) {
      if (cb) cb()
    }
  }
}
</script>

<style scoped>

</style>
