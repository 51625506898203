<template>
  <div>
    <h1 class="text-center display-2 font-weight mt-10">Domande frequenti</h1>
    <p class="text-center font-weight-light grey--text mt-3">Come possiamo aiutarti?</p>

    <v-container>
      <!-- Search input -->
      <v-row>
        <v-col>
          <v-text-field
              v-model="searchQuery"
              label="Cerca una parola chiave"
              outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- FAQs with live search highlighting -->
      <v-expansion-panels>
        <v-expansion-panel v-for="(faq, index) in filteredFaqs" :key="index">
          <v-expansion-panel-header>
            <h3 v-html="highlightSearch(faq.question)"></h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="highlightSearch(faq.answer)"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  data () {
    return {
      searchQuery: '',
      faqs: [
        {
          question: 'Cosa sono i panieri?',
          answer:
              'I panieri sono le varie tipologie di prodotti che sono disponibili nella spesa della settimana. Alcuni panieri, generalmente associati ad un singolo produttore, possono rimanere aperti per più di una settimana e avere consegne più lunghe (es. paniere Rosarno).'
        },
        {
          question: 'Quando vengono aperti e chiusi i panieri?',
          answer:
              'Vengono aperti tra il giovedì e il venerdì e vengono chiusi il lunedì mattina di ogni settimana. Al momento dell’apertura vi arriverà comunicazione via mail. Ogni paniere ha una sua data di apertura e chiusura con relativa scadenza.'
        },
        {
          question: 'Ci sono panieri che rimangono aperti per più di una settimana?',
          answer:
              'Sì, per ora il Paniere Rosarno, per arance calabresi e cipolle di Tropea, rimane aperto per più di una settimana e non viene consegnato il mercoledì successivo alla chiusura, ma due mercoledì dopo. A questo verranno aggiunti altri panieri di prodotti che intendiamo inserire a breve.'
        },
        {
          question: 'Dove vedo i prodotti acquistati con paniere ancora aperto?',
          answer: 'Nella sezione privata (CIAO “IL TUO NOME”), le mie consegne, aprendo la consegna della settimana in corso.'
        },
        {
          question: 'Dove vedo i prodotti acquistati da un paniere chiuso?',
          answer: 'Nella sezione privata (CIAO “IL TUO NOME”), le mie consegne, cliccando nelle varie date.'
        },
        {
          question: 'Come so che il paniere è aperto?',
          answer: 'Al momento dell’apertura del paniere vi arriverà una mail di comunicazione. Altrimenti basta entrare nel sito e vedere se ci sono prodotti disponibili.'
        },
        {
          question: 'Quando mi addebitate l’importo della spesa?',
          answer: 'Carta di credito: Per coloro che hanno scelto la carta di credito, il mercoledì, a spesa conclusa. L\'addebito effettivo avverrà solo dopo la chiusura del tuo conto, una volta completata la preparazione della tua cassetta. L’importo potrà essere controllato nella sezione privata (in alto a destra CIAO “IL TUO NOME” – i miei pagamenti).\n\nSEPA: Per chi ha scelto il pagamento tramite SEPA, attivo solo per gli associati, vedrà addebitarsi il totale delle varie spese fatte nel mese precedente a quello di addebito. Tali importi possono essere controllati nella sezione privata (in alto a destra CIAO “IL TUO NOME” – i miei pagamenti). Generalmente provvederemo ad effettuare tale addebito il primo mercoledì del mese successivo (es.: il totale delle spese effettuate a febbraio lo vedrete addebitato il primo mercoledì di marzo) o comunque entro i primi 10 giorni del mese successivo. E’ possibile gestire il proprio addebito in autonomia nella stessa sezione “i miei pagamenti”, cliccando su paga ora.'
        },
        {
          question: 'Perché vedo un importo addebitato con carta di credito diverso da quanto addebitato?',
          answer: 'Al momento dell’inizio della distribuzione viene bloccato (preautorizzato) sulla carta di credito un importo superiore a quello della spesa. Questo comprende una piccola percentuale aggiuntiva sul totale che serve a garantire, una volta chiuso l\'ordine, che l\'importo effettivo non superi quello preautorizzato, evitando così la necessità di un doppio addebito per la differenza.'
        },
        // {
        //   question: 'Perché l\'importo che vedo sul gestionale prima della consegna della cassetta è diverso dall\'importo addebitatomi?',
        //   answer: 'Ricordiamo che l’importo indicato sul gestionale prima del mercoledì è solo un importo stimato, perchè la maggior parte dei nostri prodotti viene venduta a peso. Conseguentemente, solo a spesa chiusa verrà determinato l’importo corretto, che verrà poi addebitato.'
        // },
        {
          question: 'Dove e quando posso restituire la cassetta?',
          answer: 'La cassetta viene restituita durante la distribuzione del paniere successivo, al punto di ritiro prestabilito per quel paniere.'
        }
      ]
    }
  },
  computed: {
    filteredFaqs () {
      if (!this.searchQuery) {
        return this.faqs
      }

      const regex = new RegExp(this.searchQuery, 'ig')

      return this.faqs.filter((faq) => {
        return regex.test(faq.question) || regex.test(faq.answer)
      })
    }
  },
  methods: {
    highlightSearch (text) {
      if (!this.searchQuery) {
        return text
      }

      const regex = new RegExp(this.searchQuery, 'ig')
      return text.replace(regex, (match) => `<span style="background-color: yellow; font-weight: bold;">${match}</span>`)
    }
  }
}
</script>

<style scoped>
</style>
