<template>
  <validation-provider
      rules="required|min_value:0"
      :name="label"
      v-slot="{ errors }"
  >
    <slot v-bind="{ errors, label }">
      <v-text-field
          v-model.number="price"
          :disabled="disabled"
          :label="hideLabel ? '' : label"
          :error-messages="errors"
          :class="classes"
          type="number"
          prefix="€"
          :outlined="outlined"
      >
      </v-text-field>
    </slot>
  </validation-provider>
</template>

<script>
export default {
  name: 'PriceInput',
  props: {
    value: {},
    label: {
      type: String,
      required: true
    },
    disabled: Boolean,
    outlined: Boolean,
    hideLabel: Boolean,
    classes: String
  },
  computed: {
    price: {
      get () {
        return this.value / 100
      },
      set (newValue) {
        this.$emit('input', Math.round(newValue * 100))
      }
    }
  }
}
</script>

<style scoped>

</style>
