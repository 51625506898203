<template>
  <v-card outlined class="mx-5 mb-6 cursor-pointer" @click="openPayment">
    <div class="ma-5">
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" sm="">
              <span class="font-weight-bold">{{date}}</span>
            </v-col>
            <v-col cols="12" sm="" v-if="payment.deliveriesCount > 1">
              {{payment.deliveriesCount}}
              <span class="text-caption">consegne</span>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="d-flex align-center justify-end">
          <div class="mx-4">
            <v-chip color="warning" v-if="payment.status === 'NOT_PAID' || payment.status === 'NOT_PAID_WAIT_SUPPORT'">
              Non pagato
            </v-chip>
            <v-chip color="light" v-if="payment.status === 'WAITING_RESULT'">
              In attesa di conferma
            </v-chip>
            <v-chip color="light" v-if="payment.status === 'READY_TO_CAPTURE'">
              Non ancora acquisito
            </v-chip>
          </div>

          <div>
            <span v-if="showPreauthorizedAmount" class="mr-3 text-h5">
              {{formatMoney(payment.preAuthorizedAmount)}}
            </span>
            <span v-else class="mr-3 text-h5">{{total}}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'PaymentListItem',
  props: {
    payment: {
      type: Object,
      required: true
    }
  },
  computed: {
    date () {
      return this.formatDate(this.payment.date)
    },
    total () {
      return this.formatMoney(this.payment.total)
    },
    deliveriesTotal () {
      return this.formatMoney(this.payment.deliveriesTotal)
    },
    showPreauthorizedAmount () {
      return this.payment.status === 'READY_TO_CAPTURE' ||
          (['NOT_PAID', 'NOT_PAID_WAIT_SUPPORT'].includes(this.payment.status) &&
              (this.payment.preAuthorized || this.payment.total === 0))
    }
  },
  methods: {
    openPayment () {
      this.$router.push('/pagamenti/' + this.payment.id)
    }
  }
}
</script>
