<template>
  <div>
    <img v-if="['visa', 'mastercard', 'sepa_direct'].includes(method.cardType)"
         :src="`../../assets/payments/${method.cardType}.svg`" alt="Logo metodo di pagamento">
    <span class="mb-0">•••• {{method.last4}}</span>
  </div>
</template>

<script>
export default {
  name: 'PaymentMethod',
  props: {
    method: {
      type: Object
      // required: true
    }
  }
}
</script>

<style scoped>

</style>
