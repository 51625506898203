<template>
  <div id="categoryPage">
    <h1 class="text-center display-2 font-weight mt-10">Le nostre categorie</h1>
    <p class="text-center font-weight-light grey--text mt-3">Trova il prodotto più adatto alle tue esigenze</p>
    <v-col v-if="categories && categories.length === 0" class="mt-16">
      <v-row justify="center">
      <v-img
          max-height="250"
          max-width="250"
          src='@/assets/empty_cart.svg'
      ></v-img>
      </v-row>
      <p class="text-center font-weight-light mt-9">Nessun prodotto disponibile al momento.</p>
      <p class="text-center font-weight-light grey--text">Torna nei prossimi giorni.</p>
    </v-col>
    <v-container v-else class="mt-5" style="min-height: 50vh">
      <SearchBar @search="search" @clear="clear"/>
      <v-row v-if="!query">
        <v-col v-for="category in categories" :key="category.id" cols="12" sm="6" md="4" xl="3">
          <v-hover v-slot:default="{ hover }">
            <v-card class="mb-3 mx-auto grey lighten-5" v-bind:class="{'grey lighten-4' : hover, 'on-hover': hover}" :elevation="hover ? 0 : 0" max-width="20em" @click="goToUnderCategory(category.id)">
                <div class="card-container">
                  <div class="card-image-container ma-0">
                    <div class="card-image primary ma-0">
                      <i class="fas fa-box-open white--text"></i>
                    </div>
                  </div>
                  <div class="card-text pa-1">
                    <v-card-title class="justify-start world-divided">{{ category.name }}</v-card-title>
                    <v-card-subtitle class="justify-start grey--text world-divided">{{ category.paniereProductsCount }} prodott{{((category.paniereProductsCount > 1) ? "i" : "o") }}</v-card-subtitle>
                  </div>
                </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row v-else>
        <div class="container" v-if="products">
          <div class="row col-12 justify-center text-center">
            <div class="col-12 col-md-4"
                 v-for="product in products"
                 :key="product.id"
            >
              <product-purchase-card :product="product"/>
            </div>
          </div>
        </div>
        <div class="container" v-else>
          <p class="text-center font-weight-light mt-9">Nessun prodotto corrisponde ai criteri di ricerca.</p>
        </div>
      </v-row>
    </v-container>

    <CategoryDialog
      v-model="showCategoryDialog"
      :edit-mode="categoryDialogEditMode"
      @close="closeCategoryDialog"
      @categoryCreated="loadCategories"/>
  </div>
</template>

<script>
import CategoryDialog from '@/components/ActionDialog/implementations/CategoryDialog'
import { mapGetters } from 'vuex'
import SearchBar from '@/components/SearchBar/SearchBar'
import ProductPurchaseCard from '@/components/ProductCard/ProductPurchaseCard.vue'

export default {
  name: 'RootCategories',
  components: {
    ProductPurchaseCard,
    SearchBar,
    CategoryDialog
  },
  data () {
    return {
      categories: null,
      showCategoryDialog: false,
      categoryDialogEditMode: false,
      query: null,
      products: null
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  mounted () {
    this.loadCategories()
  },
  methods: {
    goToUnderCategory (category) {
      this.$router.push('/categorie/' + category)
    },
    closeCategoryDialog () {
      this.showCategoryDialog = false
    },
    async loadCategories () {
      const res = await this.$http.get('/v1/categories')
      this.categories = res.data
    },
    search (args) {
      this.query = args.query
      this.products = args.data
    },
    clear () {
      this.query = null
      this.products = null
    }
  }
}
</script>

<style scoped>
  .card-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 70px auto;
  }
  .card-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
  .card-image{
    border-radius: 5px;
  }
  .card-image i{
    padding: 15px;
  }
  .card-text{
    text-align: start;
  }
  .world-divided {
    word-break: break-word;
  }
</style>
