<template>
  <div>
    {{ formatMoney(price) }}
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
              <span
                  v-bind="attrs"
                  v-on="on"
              ><small class="black--text"> / {{ showKilos ? 'kg' : measureUnit.code }}</small>
              </span>
      </template>
      <span>{{ showKilos ? 'Chilogrammi' : measureUnit.value }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'PricePerUnit',
  props: {
    price: {
      type: Number,
      required: true
    },
    measureUnit: {
      type: Object,
      required: true
    },
    showKilos: Boolean
  }
}
</script>
