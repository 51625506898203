<template>
  <v-dialog v-model="show" width="500" @click:outside="close">
    <v-card>
      <v-card-title class="primary white--text">Ricerca</v-card-title>
      <v-card-text class="mt-4" style="font-size: 1.3rem">
        Puoi utilizzare la barra di ricerca per trovare prodotti in base al nome del prodotto o della categoria. <br><br>
        Inserendo il <b>nome di un prodotto</b> vengono mostrati tutti i prodotti con quel nome, mentre
        inserendo il <b>nome di una categoria</b> vengono mostrati tutti i prodotti che fanno parte di quella categoria.
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">
          Chiudi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SearchInfoDialog',
  props: {
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      show: false
    }
  },
  watch: {
    value (val) {
      this.show = val
    }
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', this.show)
    }
  }
}
</script>

<style scoped>

</style>
