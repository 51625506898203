<template>
  <action-dialog
      v-model="fieldValue"
      :data="supplierProduct"
      :edit-mode="editMode"

      dialog-max-width="500"

      submit-title="Aggiungi un fornitore del prodotto"
      edit-title="Modifica il fornitore del prodotto"
      submit-success-message="Fornitore del prodotto aggiunto con successo!"
      edit-success-message="Fornitore del prodotto modificato con successo!"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createCallback"
      :edit-promise-callback="editCallback"
      @close="close"
  >
    <template #field.supplierId="{ errors, label, classes }">
      <v-autocomplete
          v-model="supplierProduct.supplierId"
          :label="label"
          :class="classes"
          :error-messages="errors"
          outlined
          :items="suppliers"
          item-value="id"
          item-text="businessName"
      >
      </v-autocomplete>
    </template>

    <template #field.price="{ label, classes }">
      <price-input
          v-model="supplierProduct.price"
          :classes="classes"
          :label="label"
          outlined
      />
    </template>

    <template #field.businessPrice="{ label, classes }">
      <price-input
          v-model="supplierProduct.businessPrice"
          :classes="classes"
          :label="label"
          outlined
      />
    </template>

    <template #field.supplierPrice="{ label, classes }">
      <price-input
          v-model="supplierProduct.supplierPrice"
          :classes="classes"
          :label="label"
          outlined
      />
    </template>

    <template #field.weight="{ errors, label, classes }">
      <v-text-field
          v-model="supplierProduct.weight"
          :classes="classes"
          :error-messages="errors"
          :label="label"
          type="number"
          outlined
      />
    </template>
  </action-dialog>
</template>

<script>
import ActionDialog from '@/components/ActionDialog/ActionDialog'
import { moneyUtils } from '@/mixins/moneyUtils'
import PriceInput from '@/components/Field/PriceInput'

export default {
  name: 'SupplierProductDialog',
  components: { PriceInput, ActionDialog },
  mixins: [moneyUtils],
  props: {
    value: Boolean,
    productId: {
      type: String,
      required: true
    },
    supplierProduct: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    },
    showWeight: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true
      },
      fields: [
        { value: 'supplierId', label: 'Fornitore', rules: 'required', cols: '12' },
        { value: 'price', label: 'Prezzo', rules: 'required|min_value:0', cols: '12' },
        { value: 'businessPrice', label: 'Prezzo business', rules: 'required|min_value:0', cols: '12' },
        { value: 'supplierPrice', label: 'Prezzo fornitore', rules: 'required|min_value:0', cols: '12' },
        { value: 'weight', label: 'Peso stimato', rules: 'required', cols: '12', hide: !this.showWeight }
      ].filter(f => !f.hide),
      suppliers: []
    }
  },
  computed: {
    fieldValue: {
      get () {
        return !!this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    createCallback () {
      return this.$http.post(
        `/v1/products/${this.productId}/suppliers`,
        this.supplierProduct
      )
    },
    editCallback () {
      return this.$http.put(
        'v1/products/suppliers/' + this.supplierProduct.id,
        this.supplierProduct
      )
    },
    close () {
      this.fieldValue = false
      this.$emit('close')
    },
    async getSuppliers () {
      const res = await this.$http.get('/v1/suppliers')
      this.suppliers = res.data
    }
  },
  mounted () {
    this.getSuppliers()
  }
}
</script>
