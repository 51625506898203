<template>
  <v-card outlined class="mt-16" v-if="users && users.length">
    <v-card-title>
      Preautorizzazioni non andate a buon fine
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="users"
          sort-by="name"
          :items-per-page="-1"
          hide-default-footer
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import { stringUtils } from '@/mixins/stringUtils'

export default {
  name: 'UsersWithFailedPayment',
  mixins: [usersDataCardFields, stringUtils],
  data () {
    return {
      users: [],
      headers: [
        {
          text: 'Nome',
          value: 'name'
        },
        {
          text: 'Azienda',
          value: 'business'
        },
        {
          text: 'Errore gestionale',
          value: 'errorMessage'
        },
        {
          text: 'Errore dal gestore pagamenti',
          value: 'stripeErrorMessage'
        }
      ]
    }
  },
  mounted () {
    this.loadUsers()
  },
  methods: {
    async loadUsers () {
      const res = await this.$http.get('v1/distribution/users/failed-payments')
      this.users = res.data.map(user => {
        user.name = this.nameField(user.user)
        user.business = this.businessField(user.user)
        return user
      })
    }
  }
}
</script>

<style scoped>

</style>
