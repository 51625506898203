<template>
  <div>
    <DataCard
        title-icon="fas fa-truck"
        title="Fornitori"
        :items="suppliers"
        :headers="headers"
        search-label="Cerca un fornitore"
        no-data-text="Non ci sono fornitori presenti, creane uno!"
        :operations="['create', 'update']"
        :delete-callback="deleteItem"
        :action-modal.sync="actionModal.show"
        :action-modal-edit-mode.sync="actionModal.editMode"
        :action-modal-object.sync="actionModal.obj"
        row-pointer
        @click:row="rowClick"
    />

    <supplier-dialog
      v-model="actionModal.show"
      :edit-mode="actionModal.editMode"
      :supplier="actionModal.obj"
      @close="init"
    />

    <supplier-details-dialog v-model="supplierToShow"/>
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'
import SupplierDialog from '@/components/ActionDialog/implementations/SupplierDialog'
import SupplierDetailsDialog from '@/components/DetailsDialog/SupplierDetailsDialog'

export default {
  name: 'Suppliers',
  components: { SupplierDetailsDialog, SupplierDialog, DataCard },
  data () {
    return {
      suppliers: [],
      headers: [
        { text: 'Nome', value: 'businessName' },
        { text: 'Azioni', value: 'crud' }
      ],
      actionModal: {
        show: false,
        obj: {},
        editMode: false
      },
      supplierToShow: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      try {
        const response = await this.$http.get('v1/suppliers')
        this.suppliers = response.data
      } catch (e) {
      }
    },
    edit (supplier) {
      this.actionModal.editMode = true
      this.actionModal.obj = supplier
      this.actionModal.show = true
    },
    async deleteItem (item) {
      try {
        await this.$dialog.confirmation.deletion(`Sei sicuro di voler cancellare il fornitore <b>${item.businessName}</b>?`)
        await this.$http.delete('v1/suppliers/' + item.id)
        this.$dialog.notify.success('Fornitore cancellato con successo!').then()
        await this.init()
      } catch (e) {
      }
    },
    async rowClick (item) {
      this.supplierToShow = item
      try {
        const response = await this.$http.get('v1/suppliers/' + item.id)
        this.supplierToShow = response.data
      } catch (e) {}
    }
  }
}
</script>

<style scoped>

</style>
