<template>
  <div id="categoryPage">
    <h1 class="text-center display-2 font-weight mt-10">Elenco prodotti per categoria</h1>

    <div class="text-center mt-1">
      <v-btn
          color="success"
          class="mt-5"
          depressed outlined small
          @click="createCategory"
      >
        Crea categoria
      </v-btn>
    </div>

    <v-container class="mt-5" style="min-height: 50vh">
      <v-row>
        <v-col v-for="category in categories" :key="category.id" cols="12" sm="6" md="4" xl="3">
          <v-hover v-slot:default="{ hover }">
            <v-card class="mb-3 mx-auto grey lighten-5"
                    v-bind:class="{'grey lighten-4' : hover, 'on-hover': hover}" :elevation="hover ? 0 : 0"
                    max-width="20em" >
              <div class="card-container" @click="goToUnderCategory(category.id)">
                <div class="card-image-container ">
                  <div class="card-image primary ">
                    <i class="fas fa-box-open white--text"></i>
                  </div>
                </div>
                <v-card-text class="pa-1 world-divided">
                  <v-card-title class="world-divided">{{ category.name }}</v-card-title>
                </v-card-text>
              </div>
                <v-card-actions class="ml-3">
                  <v-btn :disabled="!isDeleteActive"
                         color="red"
                         outlined
                         small
                         class="px-5"
                         elevation="0"
                         @click="deleteCategory(category)"
                  >
                    <v-row>
                      <v-icon class="mr-2" small>fa-trash</v-icon>
                      Cancella
                    </v-row>
                  </v-btn>
                  <v-btn :disabled="!isDeleteActive"
                         color="orange"
                         outlined
                         small
                         class="px-5"
                         elevation="0"
                         @click="editCategory(category)"
                  >
                    <v-row>
                      <v-icon class="mr-2" small>fa-edit</v-icon>
                      Modifica
                    </v-row>
                  </v-btn>
                </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

    <CategoryDialog
        v-model="showCategoryDialog"
        :edit-mode="categoryDialogEditMode"
        :category="currentCategory"
        @close="loadCategories"/>
  </div>
</template>

<script>
import CategoryDialog from '@/components/ActionDialog/implementations/CategoryDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'AllRootCategories',
  components: { CategoryDialog },
  data () {
    return {
      categories: null,
      showCategoryDialog: false,
      categoryDialogEditMode: false,
      isDeleteActive: true,
      currentCategory: {}
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  mounted () {
    this.loadCategories()
  },
  methods: {
    goToUnderCategory (category) {
      this.$router.push('/amministrazione/tuttesottocategorieprodotti/' + category)
    },
    closeCategoryDialog () {
      this.showCategoryDialog = false
      this.categoryDialogEditMode = false
    },
    async loadCategories () {
      const res = await this.$http.get('/v1/categories/all')
      this.categories = res.data
    },
    async deleteCategory (item) {
      this.isDeleteActive = false
      try {
        await this.$dialog.confirmation.deletion(`Sei sicuro di voler cancellare la categoria di <b>${item.name}</b>?`)
        await this.$http.delete('/v1/categories/' + item.id)
          .then(() => {
            this.loadCategories()
            this.isDeleteActive = true
          })
      } catch (e) {
        this.isDeleteActive = true
      }
    },
    createCategory () {
      this.categoryDialogEditMode = false
      this.currentCategory = {}
      this.showCategoryDialog = true
    },
    editCategory (item) {
      this.categoryDialogEditMode = true
      this.currentCategory = item
      this.showCategoryDialog = true
    }
  }
}
</script>

<style scoped>
.card-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 70px auto;
  cursor: pointer;
}

.card-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.card-image {
  border-radius: 5px;
}

.card-image i {
  padding: 15px;
}
.world-divided {
  word-break: break-word;
}
</style>
