<template>
  <div>
    <DataCard
        title-icon="fas fa-globe-europe"
        title="Gestione zone"
        :items="zones"
        :headers="headers"
        :action-modal.sync="actionModal.show"
        :action-modal-edit-mode.sync="actionModal.editMode"
        :action-modal-object.sync="actionModal.obj"
        no-data-text="Non c'è ancora nessuna zona! Creane subito una!"
        :delete-callback="deleteItem"
        :operations="['create', 'update']"
    >

      <template v-slot:item.pickUpPoint="{ value }">
        {{ formatAddress(value) /* nameField(value) */ }}
      </template>

      <template v-slot:operations="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                color="blue"
                class="pa-5"
                small icon
                v-on="on"
                @click="() => {pickUpPointDialog.show = true; pickUpPointDialog.zone = item}"
            >
              <v-icon small>fa-map-marker-alt</v-icon>
            </v-btn>
          </template>
          <span>Punto di ritiro</span>
        </v-tooltip>
      </template>

    </DataCard>

    <zone-dialog
        v-model="actionModal.show"
        :edit-mode="actionModal.editMode"
        :zone="actionModal.obj"
        @close="closeActionModal"
    />

    <pick-up-point-dialog
        v-if="pickUpPointDialog.zone"
        v-model="pickUpPointDialog.show"
        :zone-id="pickUpPointDialog.zone && pickUpPointDialog.zone.id"
        :zone-name="pickUpPointDialog.zone && pickUpPointDialog.zone.name"
        @close="init"
    />
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'
import ZoneDialog from '@/components/ActionDialog/implementations/ZoneDialog'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import PickUpPointDialog from '@/components/ActionDialog/implementations/PickUpPointDialog'
import { stringUtils } from '@/mixins/stringUtils'

export default {
  name: 'Zone',
  components: {
    PickUpPointDialog,
    ZoneDialog,
    DataCard
  },
  data () {
    return {
      zones: [],
      headers: [
        {
          text: 'Nome',
          value: 'name'
        },
        {
          text: 'Punto di ritiro',
          value: 'pickUpPoint'
        },
        {
          text: 'Azioni',
          value: 'crud'
        }
      ],
      actionModal: {
        show: false,
        obj: {},
        editMode: false
      },
      pickUpPointDialog: {
        show: false,
        zone: {}
      }
    }
  },
  mixins: [usersDataCardFields, stringUtils],
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const response = await this.$http.get('v1/zones')
      this.zones = response.data
    },
    async deleteItem (item) {
      await this.$dialog.confirmation.deletion(`Sei sicuro di voler cancellare la zona <b>${item.name}</b>?`)
      await this.$http.delete('v1/zones/' + item.id)
      await this.init()
    },
    closeActionModal () {
      this.actionModal.show = false
      this.init()
    }
  }
}
</script>
