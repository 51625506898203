function isOrderMode (item, code) {
  return item?.orderMode?.code === code || item?.orderModeCode === code
}

export const productUtils = {
  methods: {
    isPieceWeight (product) {
      return isOrderMode(product, 'piece-weight')
    },
    isApproximate (product) {
      return this.isPieceWeight(product) || isOrderMode(product, 'weight-weight')
    }
  }
}
