<template>
  <div id="SubCategoriesPage" class="mt-2">
    <v-breadcrumbs :items="path">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item exact :to="item.href">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <h1 class="display-2 text-center" v-if="category">{{ category.name }}</h1>
    <v-container>
      <div class="text-center mt-2">
        <v-btn
          v-if="isAdmin"
          color="success"
          depressed
          outlined
          small
          @click="createCategory"
        >
          Crea categoria
        </v-btn>
      </div>

      <v-row v-if="category && category.childCategories && category.childCategories.length" class="mt-3">
        <v-col
          v-for="subCategory in category.childCategories"
          :key="subCategory.id"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card class="mb-3 mx-auto text-center grey lighten-5 clickable"
                    v-bind:class="{'grey lighten-4' : hover, 'on-hover': hover}" :elevation="hover ? 0 : 0"
                    max-width="20em" >
              <div class="card-container" @click="goToUnderCategory(subCategory.id)">
                <div class="card-image-container">
                  <div class="card-image primary">
                    <i class="fas fa-box-open white--text"></i>
                  </div>
                </div>
                <v-card-text class="pa-1">
                  <v-card-title class="justify-start world-divided">{{ subCategory.name }}</v-card-title>
                </v-card-text>
              </div>
              <v-card-actions class="ml-3">
                <v-btn :disabled="!isDeleteActive"
                       color="red"
                       outlined
                       small
                       class="px-5"
                       elevation="0"
                       @click="deleteCategory(subCategory)"
                >
                  <v-row>
                    <v-icon class="mr-2" small>fa-trash</v-icon>
                    Cancella
                  </v-row>
                </v-btn>
                <v-btn :disabled="!isDeleteActive"
                       color="orange"
                       outlined
                       small
                       class="px-5"
                       elevation="0"
                       @click="editCategory(subCategory)"
                >
                  <v-row>
                    <v-icon class="mr-2" small>fa-edit</v-icon>
                    Modifica
                  </v-row>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

    <v-divider class="my-3 mx-6"
    ></v-divider>

    <div class="text-center mt-6">
      <v-btn
          v-if="isAdmin"
          color="success"
          depressed outlined small
          @click="$router.push('/crea-prodotto/' + categoryId)"
      >
        Crea prodotto
      </v-btn>
    </div>

    <div class="container" v-if="category ? category.products : false">
      <div class="row col-12 justify-center text-center">
        <div class="col-12 col-md-4"
             v-for="product in category.products"
             :key="product.id"
             @click="$router.push('/amministrazione/prodotti/' + product.id)"
        >
          <product-card :product="product"/>
        </div>
      </div>
    </div>

    <CategoryDialog
        v-model="showCategoryDialog"
        :edit-mode="categoryDialogEditMode"
        :parent-category="category.id"
        :category="currentCategory"
        @close="loadData"/>
  </div>
</template>

<script>
import CategoryDialog from '@/components/ActionDialog/implementations/CategoryDialog'
import { mapGetters } from 'vuex'
import ProductCard from '@/components/ProductCard/ProductCard'

export default {
  name: 'AllSubCategories',
  components: {
    ProductCard,
    CategoryDialog
  },
  data () {
    return {
      category: {},
      categoryPath: null,
      path: [],
      isDeleteActive: true,
      categoryDialogEditMode: false,
      showCategoryDialog: false,
      currentCategory: {}
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    categoryId () {
      return this.$route.params.categoryId
    }
  },
  watch: {
    '$route' (to, from) {
      if (from.params.categoryId !== to.params.categoryId) {
        this.reloadComponent()
      }
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    reloadComponent () {
      this.loadData()
      this.path = []
    },
    goToUnderCategory (category) {
      this.$router.push('/amministrazione/tuttesottocategorieprodotti/' + category)
    },
    closeCategoryDialog () {
      this.showCategoryDialog = false
      this.categoryDialogEditMode = false
    },
    loadPath () {
      this.path = []
      this.pushBreadcrumbsRoute(this.categoryParent)
    },
    async deleteCategory (item) {
      this.isDeleteActive = false
      try {
        await this.$dialog.confirmation.deletion(`Sei sicuro di voler cancellare la categoria di <b>${item.name}</b>?`)
        await this.$http.delete('/v1/categories/' + item.id)
          .then(() => {
            this.loadData()
            this.isDeleteActive = true
          })
      } catch (e) {
        this.isDeleteActive = true
      }
    },
    pushBreadcrumbsRoute (category) {
      if (!category.parentCategory) {
        this.path = [{
          text: 'Home',
          href: '/amministrazione/tuttecategorieprodotti/'
        }].concat(this.path)
        this.path.push({
          text: this.category.name,
          href: '/amministrazione/tuttesottocategorieprodotti/' + this.category.id
        })
        return 0
      } else {
        this.path = [{
          text: category.parentCategory.name,
          href: '/amministrazione/tuttesottocategorieprodotti/' + category.parentCategory.id
        }].concat(this.path)
        this.pushBreadcrumbsRoute(category.parentCategory)
      }
    },
    async loadData () {
      try {
        const res1 = await this.$http.get('/v1/categories/' + this.categoryId + '/all')
        this.category = res1.data
        const res2 = await this.$http.get('/v1/categories/' + this.categoryId + '/path')
        this.categoryParent = res2.data
        this.loadPath()
      } catch (e) {
      }
    },
    editCategory (item) {
      this.categoryDialogEditMode = true
      this.showCategoryDialog = true
      this.currentCategory = item
    },
    createCategory () {
      this.categoryDialogEditMode = false
      this.currentCategory = {}
      this.showCategoryDialog = true
    }
  }
}
</script>

<style scoped>
  .card-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 70px auto;
  }
  .card-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
  .card-image{
    border-radius: 5px;
  }
  .card-image i{
    padding: 15px;
  }
  .card-text{
    text-align: start;
  }
  .world-divided {
    word-break: keep-all;
  }
  .clickable{
    cursor: pointer;
  }
</style>
