<template>
  <div>
    <DataCard
        v-model="selectedMembers"
        title="Nuovi soci"
        :headers="headers"
        :items="users"
        no-data-text="Non ci sono nuovi soci registrati"
        show-select
        row-pointer
        @click:row="rowClick"
    >

      <template v-slot:actionButton>
        <v-btn
            class="ml-auto mr-8"
            color="green"
            dark
            :disabled="selectedMembers.length === 0"
            @click="showConfirmDialog = true"
        >
          Conferma
        </v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        {{ nameField(item) }}
      </template>

      <template v-slot:item.business="{ item }">
        {{ businessField(item) }}
      </template>

    </DataCard>

    <ConfirmMembersDialog
      v-model="showConfirmDialog"
      :selected-members="selectedMembers"
      @submit="init()"
    />

    <user-details-dialog v-model="userToShow" />
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import ConfirmMembersDialog from '@/components/ActionDialog/implementations/ConfirmMembersDialog'
import UserDetailsDialog from '@/components/DetailsDialog/UserDetailsDialog'

export default {
  name: 'NewMembers',
  components: {
    UserDetailsDialog,
    ConfirmMembersDialog,
    DataCard
  },
  data () {
    return {
      users: [],
      headers: [
        { text: 'Cognome e nome o ragione sociale', value: 'name' },
        { text: 'Intestazione ditta individuale o amministratore', value: 'business' },
        { text: 'Email', value: 'email' },
        { text: 'Cellulare', value: 'phoneNumber' }
      ],
      selectedMembers: [],
      showConfirmDialog: false,
      userToShow: null
    }
  },
  mixins: [usersDataCardFields],
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      try {
        const response = await this.$http.get('v1/users/members/new')
        this.users = response.data
      } catch (e) {}
    },
    async rowClick (item) {
      this.userToShow = item
      try {
        const response = await this.$http.get('v1/users/' + item.id)
        this.userToShow = response.data
      } catch (e) {}
    }
  }
}
</script>

<style scoped>

</style>
