import Vue from 'vue'
import vuetify from '@/plugins/vuetify'

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})

Vue.prototype.$dialog.confirmation = {
  deletion (message, buttonText) {
    let resolved = false

    return new Promise((resolve, reject) => {
      Vue.prototype.$dialog.confirm({
        title: 'Attenzione!',
        icon: false,
        type: 'error',
        actions: [
          'Annulla',
          {
            color: 'error',
            text: buttonText ?? 'Cancella',
            handler (action) {
              resolved = true
              resolve()
            }
          }
        ],
        text: message
      })
        .then(() => {
          if (!resolved) reject(new Error('Deletion canceled'))
        })
        .catch()
    })
  }
}
