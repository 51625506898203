<template>
  <div class="mx-10 mt-5">
    <div v-if="userInfo" class="text-center">
      <h1>Ciao, {{ userInfo.name }} {{ userInfo.surname }}</h1>
      <p>{{ userInfo.email }}</p>
    </div>
    <v-card v-if="userInfo" outlined tile max-width="60rem" class="mx-auto my-8">
      <v-card-title class="d-block">
        <h2>Informazioni</h2>
        <v-btn class="mx-8 float-right" rounded @click="saveUserInfo">Salva </v-btn>
      </v-card-title>
      <v-card-text v-if="$store.getters.memberRegistrationComplete">
        <div v-for="(card, index) in information" v-bind:key="index">
          <v-card max-width="60rem" class="mx-auto my-8" outlined v-if="card.show">
            <v-card-title>
              {{ card.title }}
            </v-card-title>
            <v-card-text>
              <v-row v-for="(row, index) in card.information" v-bind:key="index">
                <v-col v-if="row.show" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">{{ row.name }}</v-col>
                <v-col v-if="row.show && row.edit" cols="7" md="8" class="align-self-center">
                  <text-field v-if="!row.field2" outlined v-model="userInfo[row.field1]" :label="row.name"
                              :placeholder="row.name"></text-field>
                  <text-field v-else outlined v-model="userInfo[row.field1][row.field2]" :label="row.name"
                              :placeholder="row.name"></text-field>
                </v-col>
                <v-col v-if="row.show && !row.edit" cols="7" md="8" class="align-self-center">{{ row.value }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <p>Completa la registrazione per visualizzare i tuoi dati!</p>
      </v-card-text>

      <v-col cols="12" md="8" lg="8">
        <h3 class="mb-2">Cambia password</h3>
        <v-row>
          <text-field
              v-model="oldPsw"
              label="Password attuale"
              rules="required"
              :append-icon="showOldPw ? 'fas fa-eye-slash' : 'fas fa-eye'"
              :type="showOldPw ? 'text' : 'password'"
              @click:append="showOldPw = !showOldPw"
              less-margin solo
          />
          <text-field
              v-model="newPsw"
              label="Nuova password"
              rules="required"
              :append-icon="showNewPw ? 'fas fa-eye-slash' : 'fas fa-eye'"
              :type="showNewPw ? 'text' : 'password'"
              @click:append="showNewPw = !showNewPw"
              less-margin solo
          />
          <v-col class="justify-center align-center">
            <v-btn rounded @click="changePsw">Cambia password</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card>

    <v-card outlined tile max-width="60rem" class="mx-auto my-8">
      <v-card-title>
        Metodi di pagamento
      </v-card-title>
      <v-card-text v-if="!pyMethods">
        <v-skeleton-loader type="table-thead,table-row"></v-skeleton-loader>
      </v-card-text>
      <v-card-text v-else>
        <div class="mb-4">
          <v-btn @click="addPyMethod"
                 rounded
                 class="mb-5"
                 :disabled="addPmBtnDisabled"
                 :loading="addPmBtnDisabled"
          >
            Aggiungi metodo di pagamento
          </v-btn>
        </div>
        <v-card outlined tile>
          <v-card-title>
            Metodo di pagamento predefinito
          </v-card-title>
          <v-card-text>
            <v-row v-if="pyMethods.hasDefaultPm">
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Tipologia
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                Carta
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Titolare
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultCard.billingName }}
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Brand
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultCard.brand }}
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Ultime 4
                cifre
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultCard.last4 }}
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Scadenza
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultCard.expMonth }}/{{ pyMethods.defaultCard.expYear }}
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
                Tipologia
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                SEPA
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
                Titolare
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultSepaDebit.billingName }}
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Ultime
                4 cifre
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultSepaDebit.last4 }}
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
                Codice banca
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultSepaDebit.bankCode }}
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
                Codice brach
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultSepaDebit.branchCode }}
              </v-col>

              <v-col cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
              </v-col>
              <v-col cols="7" md="8" class="align-self-center">
                <v-btn :disabled="btnsDisabled" :loading="(pyMethods.defaultCard || pyMethods.defaultSepaDebit).deleteBtnLoading" rounded
                       @click="deletePm(pyMethods.defaultCard || pyMethods.defaultSepaDebit)">Cancella
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                Nessun metodo di pagamento predefinito, selezionane uno!
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined tile class="mt-5">
          <v-card-title>
            Altri metodi di pagamento
          </v-card-title>
          <v-card-text v-if="pyMethods.cards.length || pyMethods.sepaDebits.length">
            <v-data-table
                v-if="pyMethods.cards.length"
                :headers="headersCard"
                :items="pyMethods.cards"
                hide-default-footer
                class="elevation-0"
            >
              <template v-slot:item.status="{ item }">
                <v-btn :disabled="btnsDisabled" :loading="item.defaultBtnLoading" rounded
                       @click="setDefaultPm(item)">Default
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn :disabled="btnsDisabled" :loading="item.deleteBtnLoading" rounded
                       @click="deletePm(item)">Cancella
                </v-btn>
              </template>
            </v-data-table>
            <v-spacer></v-spacer>
            <v-data-table
                v-if="pyMethods.sepaDebits.length"
                :headers="headersSepa"
                :items="pyMethods.sepaDebits"
                hide-default-footer
                class="elevation-0"
            >
              <template v-slot:item.status="{ item }">
                <v-btn :disabled="btnsDisabled" :loading="item.defaultBtnLoading" rounded
                       @click="setDefaultPm(item)">Default
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn :disabled="btnsDisabled" :loading="item.deleteBtnLoading" rounded
                       @click="deletePm(item)">Cancella
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text v-else>
            Nessun metodo di pagamento registrato
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { stringUtils } from '@/mixins/stringUtils'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'

export default {
  name: 'UserInfo',
  mixins: [stringUtils, usersDataCardFields],
  data () {
    return {
      information: null,
      pyMethods: null,
      userInfo: null,
      addPmBtnDisabled: false,
      btnsDisabled: false,
      editStatusBtnLoading: [],
      headersCard: [
        {
          text: 'Nome',
          value: 'billingName'
        },
        {
          text: 'Brand',
          value: 'brand'
        },
        {
          text: 'Ultime 4 cifre',
          value: 'last4'
        },
        {
          text: 'Mese di scadenza',
          value: 'expMonth'
        },
        {
          text: 'Anno di scadenza',
          value: 'expYear'
        },
        {
          text: 'Stato',
          value: 'status'
        },
        {
          text: 'Cancella',
          value: 'delete'
        }
      ],
      headersSepa: [
        {
          text: 'Nome',
          value: 'billingName'
        },
        {
          text: 'Ultime 4 cifre',
          value: 'last4'
        },
        {
          text: 'Codice banca',
          value: 'bankCode'
        },
        {
          text: 'Codice branch',
          value: 'branchCode'
        },
        {
          text: 'Stato',
          value: 'status'
        },
        {
          text: 'Cancella',
          value: 'delete'
        }
      ],
      oldPsw: null,
      newPsw: null,
      showOldPw: false,
      showNewPw: false
    }
  },
  computed: {},
  watch: {},
  mounted () {
    this.getPyMethods()
    this.getUserInfo()
  },
  methods: {
    async getUserInfo () {
      const res = await this.$http.get('/v1/users/information')
      this.userInfo = res.data
      await this.loadUserData()
    },
    async getPyMethods () {
      const res = await this.$http.get('/v1/payment-methods')
      this.pyMethods = res.data
    },
    async loadUserData () {
      this.information = [
        {
          title: 'Informazioni di base',
          show: true,
          information: [
            {
              show: true,
              name: 'Tipo di account',
              value: (this.userInfo.isMember ? 'Socio' : 'Non socio') + ' - ' +
                  (!this.userInfo.isBusiness ? 'Privato' : this.userInfo.isIndividualCompany ? 'Ditta individuale' : 'Società')
            },
            {
              show: true,
              edit: true,
              name: 'Nome',
              value: this.userInfo.name,
              field1: 'name'
            },
            {
              show: true,
              edit: true,
              name: 'Cognome',
              value: this.userInfo.surname,
              field1: 'surname'
            },
            {
              show: this.userInfo.isBusiness,
              edit: false,
              name: !this.userInfo.isIndividualCompany ? 'Amministratore' : 'Intestazione ditta individuale',
              value: this.businessField(this.userInfo)
            },
            {
              show: true,
              edit: true,
              name: 'Email',
              value: this.userInfo.email,
              field1: 'email'
            },
            {
              show: true,
              edit: true,
              name: 'Cellulare',
              value: this.userInfo.phoneNumber,
              field1: 'phoneNumber'
            },
            {
              show: true,
              edit: false,
              name: 'Data di registrazione',
              value: this.formatDate(this.userInfo.startRegistrationDate)
            },
            {
              show: this.userInfo.memberConfirmationDate,
              edit: false,
              name: 'Data di accettazione socio',
              value: this.formatDate(this.userInfo.memberConfirmationDate)
            },
            {
              show: true,
              edit: false,
              name: 'Data di scadenza tessera',
              value: this.userInfo.memberExpirationDate ? this.formatDate(this.userInfo.memberExpirationDate) : 'Quota di iscrizione non ancora pagata'
            }]
        },
        {
          title: 'Zone',
          information: [
            {
              show: true,
              name: 'Zona di referenza',
              value: this.userInfo.zonaReferenza?.name ?? '-'
            },
            {
              show: this.userInfo.deliveryZone,
              name: 'Zona di consegna',
              value: this.userInfo.deliveryZone.name
            }
          ]
        },
        {
          title: 'Dati ' + (this.userInfo.isIndividualCompany ? 'ditta individuale' : 'azienda'),
          show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
          information: [
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: true,
              name: this.userInfo.isIndividualCompany ? 'Intestazione' : 'Ragione sociale',
              value: this.userInfo.business.businessName,
              field1: 'business',
              field2: 'businessName'
            },
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: true,
              name: 'Partita IVA',
              value: this.userInfo.business.partitaIva,
              field1: 'business',
              field2: 'partitaIva'
            },
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: true,
              name: 'Codice fiscale',
              value: this.userInfo.business.fiscalCode,
              field1: 'business',
              field2: 'fiscalCode'
            },
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: this.userInfo.business.codiceUnivoco,
              name: 'Codice univoco',
              value: this.userInfo.business.codiceUnivoco,
              field1: 'business',
              field2: 'codiceUnivoco'
            },
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: true,
              name: 'PEC',
              value: this.userInfo.business.pec,
              field1: 'business',
              field2: 'pec'
            },
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: true,
              name: 'Indirizzo sede legale',
              value: this.userInfo.business.address,
              field1: 'business',
              field2: 'address'
            },
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: true,
              name: 'Città sede legale',
              value: this.userInfo.business.city,
              field1: 'business',
              field2: 'city'
            },
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: true,
              name: 'Provincia sede legale',
              value: this.userInfo.business.province,
              field1: 'business',
              field2: 'province'
            },
            {
              show: this.userInfo.isBusiness || this.userInfo.isIndividualCompany,
              edit: true,
              name: 'Cap sede legale',
              value: this.userInfo.business.cap,
              field1: 'business',
              field2: 'cap'
            }]
        },
        {
          title: 'Dati ' + (!this.userInfo.isBusiness ? 'personali' : this.userInfo.isIndividualCompany ? 'titolare ditta individuale' : 'legale rappresentante'),
          show: this.userInfo.isMember,
          information: [
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Codice fiscale',
              value: this.userInfo.member.fiscalCode,
              field1: 'member',
              field2: 'fiscalCode'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Tipo documento',
              value: this.userInfo.member.documentType,
              field1: 'member',
              field2: 'documentType'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Documento',
              value: this.userInfo.member.documentNumber,
              field1: 'member',
              field2: 'documentNumber'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Data di scadenza documento',
              value: this.userInfo.member.documentExpirationDate,
              field1: 'member',
              field2: 'documentExpirationDate'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Data di nascita',
              value: this.userInfo.member.birthDate,
              field1: 'member',
              field2: 'birthDate'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Città di nascita',
              value: this.userInfo.member.birthCity,
              field1: 'member',
              field2: 'birthCity'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Provincia di nascita',
              value: this.userInfo.member.birthProvince,
              field1: 'member',
              field2: 'birthProvince'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Indirizzo socio',
              value: this.userInfo.member.address,
              field1: 'member',
              field2: 'address'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Città socio',
              value: this.userInfo.member.city,
              field1: 'member',
              field2: 'city'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Provinci socio',
              value: this.userInfo.member.province,
              field1: 'member',
              field2: 'province'
            },
            {
              show: this.userInfo.isMember,
              edit: true,
              name: 'Cap socio',
              value: this.userInfo.member.cap,
              field1: 'member',
              field2: 'cap'
            }
          ]
        }
      ]
    },
    async saveUserInfo () {
      const data = {
        name: this.userInfo.name,
        surname: this.userInfo.surname,
        email: this.userInfo.email,
        phoneNumber: this.userInfo.phoneNumber
      }
      await this.$http.patch('/v1/users/update', data)
      if (this.userInfo.isMember) {
        const data = {
          fiscalCode: this.userInfo.member.fiscalCode,
          birthDate: this.userInfo.member.birthDate,
          birthCity: this.userInfo.member.birthCity,
          birthProvince: this.userInfo.member.birthProvince,
          address: this.userInfo.member.address,
          city: this.userInfo.member.city,
          province: this.userInfo.member.province,
          cap: this.userInfo.member.cap
        }
        await this.$http.patch('/v1/users/update/member', data)
      }
      if (this.userInfo.isBusiness || this.userInfo.isIndividualCompany) {
        const data = {
          businessName: this.userInfo.business.businessName,
          fiscalCode: this.userInfo.business.fiscalCode,
          partitaIva: this.userInfo.business.partitaIva,
          codiceUnivoco: this.userInfo.business.codiceUnivoco,
          pec: this.userInfo.business.pec,
          address: this.userInfo.business.address,
          city: this.userInfo.business.city,
          province: this.userInfo.business.province,
          cap: this.userInfo.business.cap
        }
        await this.$http.patch('/v1/users/update/business', data)
      }
      await this.$dialog.notify.success('Modifiche apportate correttamente')
      await this.loadUserData()
    },
    async changePsw () {
      await this.$http.patch('/v1/users/updatePassword', {
        oldPassword: this.oldPsw,
        newPassword: this.newPsw
      })
    },
    async addPyMethod () {
      this.addPmBtnDisabled = true
      try {
        const res = await this.$http.post('/v1/payment-methods', {
          successUrl: window.location.href,
          cancelUrl: window.location.href
        })
        this.addPmBtnDisabled = false
        window.location.replace(res.data)
      } catch (e) {
        this.addPmBtnDisabled = false
      }
    },
    async setDefaultPm (item) {
      item.defaultBtnLoading = true
      this.btnsDisabled = true
      await this.$http.patch('/v1/payment-methods/default/stripe/' + item.id)
      setTimeout(async () => {
        await this.getPyMethods()
        this.btnsDisabled = false
        item.defaultBtnLoading = false
      }, 5000)
    },
    async deletePm (item) {
      item.deleteBtnLoading = true
      this.btnsDisabled = true
      await this.$http.delete('/v1/payment-methods/' + item.id)
      await this.getPyMethods()
      this.btnsDisabled = false
      item.deleteBtnLoading = false
    }
  }

}

</script>

<style>
</style>
