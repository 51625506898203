import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vuetify-dialog'
import './plugins/vee-validate'
import TextField from '@/components/Field/TextField'
import axiosInstance from '@/plugins/axios'
import { environment } from '@/mixins/enviorment'
import { stringUtils } from '@/mixins/stringUtils'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import { productUtils } from '@/mixins/productUtils'

Vue.component('text-field', TextField)
Vue.mixin(environment)
Vue.mixin(stringUtils)
Vue.mixin(usersDataCardFields)
Vue.mixin(productUtils)
Vue.config.productionTip = false
Vue.prototype.$http = axiosInstance

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
