<template>
  <div>
    <h1 class="text-center display-2 font-weight mt-10">Pagina di test</h1>
    <v-row class="my-8 text-center" v-if="!isProduction">
      <v-col cols="6">
        <v-btn color="error" @click="deleteDistribuzione">Cancella distribuzioni</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="error" @click="deleteAllPanieriAndDistribuzione">Cancella panieri e distrubuzioni</v-btn>
      </v-col>
    </v-row>

    <div style="max-width: 30rem" class="mx-5">
      <h1>Test tastiera tablet 1 aaa</h1>

      <v-text-field
          v-model="test1Computed"
          label="Bilancia"
          placeholder="Bilancia"
          outlined
      />

      Valore inserito riconosciuto: {{ test1Computed }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminTest',
  data () {
    return {
      test1: 125
    }
  },
  computed: {
    test1Computed: {
      get () {
        return this.test1 / 100 || ''
      },
      set (newValue) {
        newValue = newValue.replace(',', '.')
        this.test1 = Math.round(newValue * 100)
      }
    }
  },
  methods: {
    async deleteDistribuzione () {
      try {
        await this.$dialog.confirmation.deletion('Sei sicuro di voler cancellare tutte le distribuzioni?')
        await this.$http.delete('v1/reset-all/distribution-sessions')
        await this.$dialog.notify.success('Distribuzioni cancellate')
      } catch (e) {
      }
    },
    async deleteAllPanieriAndDistribuzione () {
      try {
        await this.$dialog.confirmation.deletion('Sei sicuro di voler cancellare tutti i panieri e le distribuzioni?')
        await this.$http.delete('v1/reset-all')
        await this.$dialog.notify.success('Panieri e distribuzioni cancellati')
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>
