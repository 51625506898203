<template>
  <v-container fluid class="fill-height">
    <LoadingDialog :show="showLoading"/>

    <v-row class="d-flex align-center justify-center align-content-center">
      <v-col cols="11" sm="8" md="6" lg="4">
        <validation-observer ref="inputObserver" v-slot="{ invalid }">
          <v-card outlined class="mt-n12" @keyup.enter="!invalid ? login() : null">
            <v-card-title class="mt-1"><h2 class="text-center">Effettua l'accesso</h2></v-card-title>
            <v-card-text>
              <text-field
                v-for="field in fields"
                :key="field.value"
                v-model="loginData[field.value]"
                :label="field.label"
                :placeholder="field.placeholder"
                :rules="field.rules"
                :type="field.type"
                :append-icon="field.appendIcon"
                @click:append="showPassword = !showPassword"
                less-margin solo
              ></text-field>
              <p class="mb-2">
                Hai dimenticato la tua password?
                <router-link to="/reimposta-password">Reimpostala ora!</router-link>
              </p>
              <p class="mb-0">
                Non sei ancora registrato?
                <router-link to="/iscriviti">Crea il tuo account!</router-link>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" class="ml-auto" depressed @click="login">Accedi</v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadingDialog from '@/components/ActionDialog/implementations/LoadingDialog'

export default {
  name: 'Login',
  components: { LoadingDialog },
  data () {
    return {
      loginData: {
        email: null,
        password: null
      },
      fields: [
        {
          value: 'email',
          placeholder: 'Email',
          label: 'Email',
          rules: 'required|email',
          type: 'email'
        },
        {
          value: 'password',
          placeholder: 'Password',
          label: 'Password',
          rules: 'required',
          appendIcon: () => this.showPassword ? 'fas fa-eye-slash' : 'fas fa-eye',
          type: () => this.showPassword ? 'text' : 'password'
        }
      ],
      showPassword: false,
      showLoading: false
    }
  },
  methods: {
    async login () {
      this.showLoading = true
      const valid = await this.$refs.inputObserver.validate()
      if (!valid) {
        this.showLoading = false
        return
      }
      try {
        const { loginData } = this
        const redirect = this.$route.query.redirect
        await this.$store.dispatch('login', { loginData, redirect })
        this.showLoading = false
      } catch (e) {
        this.showLoading = false
      }
    }
  }
}
</script>

<style scoped>
.login-card {
  margin-top: 13em;
}
</style>
