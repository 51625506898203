<template>
  <div>
    <DataCard
        title-icon="far fa-credit-card"
        title="Gestione pagamenti"
        :items="items"
        :headers="headers"
        no-data-text="Non ci sono importi non pagati!"
    >
      <template v-slot:actionButton>
        <v-btn
            color="success"
            class="white--text mr-4 darken-1"
            @click="openCreatePaymentsDialog"
            :loading="btnLoading"
            :disabled="btnLoading"
        >
          Crea pagamenti
        </v-btn>
        <v-btn
            color="warning"
            class="white--text darken-1"
            @click="payAll"
            :loading="btnLoading"
            :disabled="btnLoading"
        >
          Paga tutti
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            v-if="item.statusCode === 'NOT_PAID_WAIT_SUPPORT'"
            small
            class="white--text"
            color="rgb(119, 168, 248)"
            @click="markAsPayable(item)"
            :loading="btnLoading"
            :disabled="btnLoading"
        >
          Reimposta
        </v-btn>

        <v-btn
            v-else-if="item.statusCode === 'NOT_PAID'"
            small
            color="primary"
            @click="paySinglePayment(item)"
            :loading="btnLoading"
            :disabled="btnLoading"
        >
          Paga
        </v-btn>
      </template>
    </DataCard>

    <v-dialog v-model="paymentsDialog.visible" max-width="650">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Crea pagamenti
        </v-card-title>

        <v-card-text>
          <div class="my-3 body-1">
            Seleziona la data massima delle consegne da includere nel pagamento SEPA.
          </div>

          <date-picker v-model="paymentsDialog.date"
                       label="Includi consegne fino al"
                       :max="formatDateYearFirst(new Date())" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              outlined
              :disabled="!paymentsDialog.date"
              @click="createPayments"
          >
            Crea pagamenti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'
import DatePicker from '@/components/Field/DatePicker'

export default {
  name: 'UnpaidPayments',
  components: { DatePicker, DataCard },
  data () {
    return {
      items: [],
      btnLoading: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Id utente', value: 'user.id' },
        { text: 'Id Stripe', value: 'stripePaymentIntentId' },
        { text: 'Data', value: 'date' },
        { text: 'Nome', value: 'name' },
        { text: 'Business', value: 'business' },
        { text: 'Stato', value: 'status' },
        { text: 'Preautorizzato', value: 'preAuthorized' },
        { text: 'Consegne totali', value: 'deliveriesCount' },
        { text: 'Totale', value: 'total' },
        { text: '', value: 'actions', sortable: false }
      ],
      paymentsDialog: {
        visible: false,
        date: null
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      try {
        const res = await this.$http.get('v1/payments/unpaid')
        this.items = res.data.map(this.mapPayment)
      } catch (e) {
      }
    },
    mapPayment (p) {
      p.originalDate = p.date
      p.date = this.formatDateYearFirst(p.date)
      p.name = this.nameField(p.user)
      p.business = this.businessField(p.user)
      p.total = this.formatMoney(p.total)

      p.preAuthorized = p.preAuthorizedAmount ? this.formatMoney(p.preAuthorizedAmount) : 'No'

      p.statusCode = p.status
      switch (p.status) {
        case 'NOT_PAID': p.status = 'Non pagato'; break
        case 'NOT_PAID_WAIT_SUPPORT': p.status = 'Non pagato e sospeso'; break
        case 'READY_TO_CAPTURE': p.status = 'Preautorizzato ma non completato'; break
        case 'WAITING_RESULT': p.status = 'In attesa di conferma del SEPA'; break
      }

      if (p.stripeError) {
        p.status += ` (${p.stripeErrorMessage})`
      }

      return p
    },
    openCreatePaymentsDialog () {
      this.paymentsDialog.visible = true
    },
    createPayments () {
      const date = this.paymentsDialog.date
      const that = this
      this.$dialog.confirm({
        title: 'Conferma creazione pagamenti',
        text: `Confermi di voler creare i pagamenti fino alla data <b>${date}</b>?`,
        actions: [
          { color: '', text: 'Annulla' },
          {
            color: 'success',
            text: 'Conferma',
            outlined: true,
            async handler (action) {
              that.btnLoading = true
              try {
                await that.$http.post('v1/payments/unpaid', {
                  date
                })
              } catch (e) {
              }
              that.btnLoading = false
              that.init().then()
            }
          }
        ]
      })
    },
    payAll () {
      const that = this
      this.$dialog.confirm({
        title: 'Conferma pagamenti',
        text: 'Confermi di voler pagare tutti i pagamenti? (I pagamenti in sospeso non vengono effettuati)',
        actions: [
          { color: '', text: 'Annulla' },
          {
            color: 'success',
            text: 'Conferma',
            outlined: true,
            async handler (action) {
              that.btnLoading = true
              try {
                await that.$http.put('v1/payments/unpaid/pay')
              } catch (e) {
              }
              that.btnLoading = false
              that.init().then()
            }
          }
        ]
      })
    },
    markAsPayable (item) {
      const that = this
      this.$dialog.confirm({
        title: 'Conferma pagamento risolto',
        text: `Sono stati risolti i problemi al pagamento di <b>${item.total}</b> del <b>${this.formatDate(item.originalDate)}</b> ` +
            `dell'utente <b>${item.name}</b> per renderlo quindi pronto per essere pagato?`,
        actions: [
          { color: '', text: 'Annulla' },
          {
            color: 'success',
            text: 'Conferma',
            outlined: true,
            async handler (action) {
              that.btnLoading = true
              try {
                await that.$http.patch(`v1/payments/${item.id}/mark-payable`)
              } catch (e) {
              }
              that.btnLoading = false
              that.init().then()
            }
          }
        ]
      })
    },
    paySinglePayment (item) {
      const that = this
      this.$dialog.confirm({
        title: 'Conferma pagamento',
        text: `Confermi di voler pagare il pagamento di <b>${item.total}</b> del <b>${this.formatDate(item.originalDate)}</b> per conto di <b>${item.name}</b>?`,
        actions: [
          { color: '', text: 'Annulla' },
          {
            color: 'success',
            text: 'Conferma',
            outlined: true,
            async handler (action) {
              that.btnLoading = true
              try {
                await that.$http.put(`v1/payments/${item.id}/pay`)
              } catch (e) {
              }
              that.btnLoading = false
              that.init().then()
            }
          }
        ]
      })
    }
  }
}
</script>

<style scoped>

</style>
