<template>
  <div id="create-product" class="mx-10">
    <h1 class="text-center display-3 mt-6 mb-10">Crea un prodotto</h1>

    <v-card max-width="90em" class="mx-auto" outlined>
      <v-card-text>
        <validation-observer ref="dataObserver">
          <product-form v-model="productData"/>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isActive" color="primary" class="mx-auto mb-4" @click="saveProduct">
          Salva prodotto
        </v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import ProductForm from '@/components/ProductForm/ProductForm'

export default {
  name: 'CreateProduct',
  components: {
    ProductForm
  },
  data () {
    return {
      productData: {
        product: {
          categoryId: this.$route.params.categoryId,
          description: null,
          ivaCode: null,
          measureUnitCode: null,
          minQuantity: 1,
          multiplier: 1,
          name: null,
          orderModeCode: null,
          productTypeCode: null,
          weightLevelCode: null,
          post: null,
          imageUrl: null
        },
        image: null,
        imageUrl: null,
        categoryPath: null
      },
      isActive: true
    }
  },
  mounted () {
    this.productData.imageUrl = 'https://via.placeholder.com/4032x3024?text=Clicca+sotto+per+caricare+l\'immagine.'
  },
  methods: {
    showGetDataErrorDialog (err) {
      this.$dialog.error({
        title: 'Attenzione',
        text: err.response.data.message || 'C\'è stato un errore inaspettato, prova a ricaricare la pagina',
        actions: { text: 'Chiudi' }
      })
    },
    async saveProduct () {
      const valid = await this.$refs.dataObserver.validate()
      if (!valid) {
        await this.$dialog.notify.warning('Ci sono errori nei dati inseriti, controlla i campi.')
        return
      }
      this.isActive = false
      const data = new FormData()
      data.append('product', new Blob([JSON.stringify(this.productData.product)], {
        type: 'application/json'
      }))
      data.append('image', this.productData.image)

      try {
        const response = await this.$http.post('/v1/products', data)
        await this.$router.push('/amministrazione/prodotti/' + response.data)
      } catch (e) {
        this.isActive = true
      }
    }
  }
}
</script>

<style>
</style>
