import Vue from 'vue'

const state = {
  users: [],
  selectedUser: null
}

const getters = {
  users: (state) => {
    return state.users
  }
}

const mutations = {
  setUsers (state, users) {
    state.users = users
  }
}

const actions = {
  async loadDistUsers ({ state, commit }, { zoneId, postCode }) {
    const res = await Vue.prototype.$http.get('v1/distribution/zones/' + zoneId + '/users/' + postCode)
    commit('setUsers', res.data)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
