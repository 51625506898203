<template>
  <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      @close="close"
  >
    <v-card tile v-if="showDialog">
      <v-toolbar flat color="primary">
        <v-btn icon class="white--text" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text" v-if="user">Cassetta per {{ user.user.name }} {{ user.user.surname }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-4">
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="products || []"
            item-key="id"
            show-select
            class="elevation-1">

          <template v-slot:item.price="{ item }">
            € {{ item.price / 100 }}
          </template>

          <template v-slot:item.totalPrice="{ item }">
            € {{ item.totalPrice / 100 }}
          </template>

          <template v-slot:item.notAvailableForUser="{ item, value }">
            <v-btn outlined :color="value ? 'warning' : 'success'" @click="changeAvailability(item)">
              <v-icon left>
                mdi-pencil
              </v-icon>
              {{ item.notAvailableForUser ? 'Non disponibile' : 'Disponibile' }}
            </v-btn>
          </template>

        </v-data-table>

        <v-row class="mt-2" v-if="user">
          <v-col v-if="isBilanciaPost" cols="3" xl="2">
            <v-text-field
                v-model="bilanciaTotalComputed"
                :label="'Bilancia' + (!user.maxBilanciaAmount ? '' : ` (max ${user.maxBilanciaAmount/100})`)"
                placeholder="Bilancia"
                :messages="bilanciaHint"
                outlined
            />
<!--            On Cristina's tablet, the numeric keyboard does not allow to insert the dot or comma, so a normal text-field has to be used-->
<!--            <v-text-field-->
<!--                v-model.number="bilanciaTotalComputed"-->
<!--                :label="'Bilancia' + (!user.maxBilanciaAmount ? '' : ` (max ${user.maxBilanciaAmount/100})`)"-->
<!--                placeholder="Bilancia"-->
<!--                type="number"-->
<!--                step="0.01"-->
<!--                :rules="[val => val >= 0 || 'Il valore deve essere positivo']"-->
<!--                outlined-->
<!--            />-->
          </v-col>
          <v-col v-if="isBilanciaPost"
                 cols="3"
                 xl="2"
          >
            <span class="ml-1 text-left font-weight-bold">Bilancia (stimato)</span>
            <div class="outlined w-100 h-100 ma-2">
              <p class="body-1">{{ user.bilanciaTotal / 100 }} €</p>
            </div>
          </v-col>
          <v-col v-else
                 cols="3"
                 xl="2"
          >
            <span class="ml-1 text-left font-weight-bold">Bilancia {{user.bilanciaCompleted ? '' : '(stimato)'}}</span>
            <div class="outlined w-100 h-100 ma-2">
              <p class="body-1">{{ (user.bilanciaCompleted ? user.bilanciaTotalManual : user.bilanciaTotal) / 100 }} €</p>
            </div>
          </v-col>
          <v-col
              cols="3"
              xl="2"
          >
            <span class="ml-1 text-left font-weight-bold">Bancone</span>
            <div class="outlined w-100 h-100 ma-2">
              <p class="body-1">{{ user.banconeTotal / 100 }} €</p>
            </div>
          </v-col>
          <v-col
              cols="3"
              xl="2"
          >
            <span class="ml-1 text-left font-weight-bold">Totale</span>
            <div class="outlined w-100 h-100 ma-2">
              <p class="body-1">{{ total }}</p>
            </div>
          </v-col>
        </v-row>

        <div v-if="stripeError">
          <v-alert class="text-center lighten-3" color="warning">
            La preautorizzazione del pagamento di {{user.user.name}} {{user.user.surname}} non è andata a buon fine.
            <br>
            Messaggio di errore del gestore dei pagamenti: <span class="font-weight-bold">{{user.payment.stripeErrorMessage}}</span>
          </v-alert>
        </div>

        <div class="d-flex my-5 mx-3">
          <v-btn :disabled="loading" :loading="loading" @click="previousUser">Utente precedente</v-btn>

          <v-spacer/>

          <v-btn :disabled="loading" :loading="loading" @click="skipUser" class="mx-4">Salta utente</v-btn>
          <v-btn :disabled="loading" :loading="loading" @click="confirmUser">Conferma e continua</v-btn>
        </div>

      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>

export default {
  name: 'DistUserDetailsDialog',
  props: {
    value: Number,
    post: Object
  },
  data () {
    return {
      loading: false,
      supplierToShow: null,
      products: null,
      user: null,
      headers: [
        {
          text: 'Prodotto',
          value: 'name'
        },
        {
          text: 'Fornitore',
          value: 'supplierName'
        },
        {
          text: 'Quantità ordinata',
          value: 'purchased'
        },
        {
          text: 'Prezzo',
          value: 'price'
        },
        {
          text: 'Peso prodotto',
          value: 'weight'
        },
        {
          text: 'Totale',
          value: 'totalPrice'
        },
        {
          text: 'Disponibilità',
          value: 'notAvailableForUser'
        }
      ]
    }
  },
  computed: {
    isBilanciaPost () {
      return this.post.code === 'BILANCIA'
    },
    bilanciaTotal () {
      return this.user.bilanciaTotalManual ?? this.user.bilanciaTotal
    },
    bilanciaHint () {
      const total = this.user.bilanciaTotalManual
      const max = this.user.maxBilanciaAmount
      return max < total
        ? ((total - max) / 100) + '€ verranno addebitati al prossimo pagamento'
        : ''
    },
    stripeError () {
      return this.user?.payment?.stripeError
    },
    showDialog: {
      get () {
        return !!this.value
      },
      set (newVal) {
        if (!newVal) this.$emit('input', null)
      }
    },
    bilanciaTotalComputed: {
      get () {
        return this.user.bilanciaTotalManual / 100 || ''
      },
      set (newValue) {
        newValue = newValue.replace(',', '.')
        this.user.bilanciaTotalManual = Math.round(newValue * 100)
      }
    },
    total () {
      let total
      if (this.user.total) total = this.user.total
      else if (this.post.code === 'BILANCIA' || this.user.bilanciaCompleted) {
        total = this.user.bilanciaTotalManual + this.user.banconeTotal
      } else {
        total = this.user.bilanciaTotal + this.user.banconeTotal
      }

      return (total / 100) + ' €'
    }
  },
  watch: {
    value (newVal) {
      if (newVal) this.init()
    }
  },
  methods: {
    async init () {
      this.loading = true

      try {
        const userId = this.value
        const userPromise = this.$http.get('v1/distribution/users/' + userId + '/')
        const productsPromise = this.$http.get('v1/distribution/users/' + userId + '/products/' + this.post.code)
        this.user = (await userPromise).data
        if (this.post.code === 'BILANCIA' && !this.user.bilanciaTotalManual) this.user.bilanciaTotalManual = this.user.bilanciaTotal
        this.products = (await productsPromise).data
      } catch (e) {
        this.$emit('input', null)
      }

      this.loading = false
    },
    close () {
      this.$emit('input', null)
    },
    previousUser () {
      this.$emit('previousUser')
    },
    skipUser () {
      this.$emit('nextUser')
    },
    async confirmUser () {
      try {
        if (this.post.code === 'BILANCIA') {
          await this.$http.patch('v1/distribution/users/' + this.user.user.id + '/complete/bilancia/' + this.user.bilanciaTotalManual)
        } else {
          await this.$http.patch('v1/distribution/users/' + this.user.user.id + '/complete/bancone')
        }
        this.$emit('nextUser')
      } catch (e) {
      }
    },
    async changeAvailability (item) {
      try {
        const notAvail = !item.notAvailableForUser
        await this.$dialog.confirmation.deletion(
            `Vuoi rendere <b>${notAvail ? 'NON ' : ''}disponibile</b> il prodotto ${item.name} per questo utente?`,
            'Conferma'
        )
        await this.$http.patch(
            `v1/distribution/users/${this.user.user.id}/paniere-products/${item.id}/not-available/${notAvail}`
        )
        await this.init()
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>
