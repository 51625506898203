<template>
  <v-text-field
      v-if="!$store.getters.isLogged"
      value="Effettua l'accesso per poter acquistare!"
      disabled
  >
    <template v-slot:prepend-inner>
      <v-btn icon disabled>
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </template>
    <template v-slot:append>
      <v-btn icon disabled>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
  </v-text-field>
  <validation-provider
      v-else
      :rules="rules"
      :name="label"
      v-slot="{ errors }"
  >
    <v-text-field
        v-model.number="quantity"
        :error-messages="errorMessage ? [errorMessage] : errors"
        :disabled="disabled"
        loading
        @input="onChange"
        @click.stop
    >
      <template v-slot:progress>
        <v-progress-linear
            v-if="finishedLoading"
            absolute
            height="7"
        ></v-progress-linear>
      </template>

      <template v-slot:prepend-inner>
        <v-btn icon @click.stop="decrement">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </template>
      <template v-slot:append>
        <v-btn icon @click.stop="increment">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
import { preventNextErrDialog } from '@/plugins/axios'

export default {
  name: 'PurchaseField',
  props: {
    value: {},
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      required: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minQuantity: {
      type: Number,
      required: true
    },
    multiplier: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      classes: {
        'mb-n3': !this.lessMargin,
        'mb-n5': this.lessMargin,
        'mt-1': !this.outlined
      },
      finishedLoading: true,
      timeout: null,
      originalValue: null,
      errorMessage: null
    }
  },
  mounted () {
    this.originalValue = this.quantity
  },
  computed: {
    quantity: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    onChange (newValue) {
      clearTimeout(this.timeout)
      if (newValue === '') {
        this.finishedLoading = true
        return
      }
      this.finishedLoading = false
      this.timeout = setTimeout(() => {
        this.updateQuantity(this.id, newValue)
        this.finishedLoading = true
      }, 1000)
    },
    showHelpMessage () {
      this.$dialog.info({
        title: 'Info ' + this.label || this.placeholder,
        text: this.helpText
      })
    },
    async updateQuantity (id, newValue) {
      const ordered = typeof newValue === 'string' ? newValue?.replace(',', '.') ?? 0 : newValue
      preventNextErrDialog()
      try {
        await this.$http.put('/v1/carts/purchase/' + id + '/' + ordered)
        this.errorMessage = null
        this.originalValue = this.quantity
        this.$emit('quantityUpdated', newValue)
      } catch (e) {
        this.quantity = this.originalValue
        this.errorMessage = e.response.data.message
      }
    },
    increment () {
      let newVal = this.quantity + this.multiplier

      if (newVal < this.minQuantity) {
        newVal = this.minQuantity
      } else if (newVal % this.multiplier !== 0) {
        newVal = Math.floor(newVal / this.multiplier) * this.multiplier
      }

      if (newVal !== this.quantity) {
        this.quantity = newVal
        this.onChange(newVal)
      }
    },
    decrement () {
      let newVal = this.quantity

      if (newVal % this.multiplier !== 0) {
        newVal = Math.floor(newVal / this.multiplier) * this.multiplier
      } else {
        newVal -= this.multiplier
      }

      if (newVal < this.minQuantity) {
        newVal = 0
      }

      if (newVal !== this.quantity) {
        this.quantity = newVal
        this.onChange(newVal)
      }
    }
  },
  beforeDestroy () {
    if (this.originalValue !== this.value) {
      this.updateQuantity(this.id, this.value, 0)
    }
  }
}
</script>

<style scoped>

</style>
