<template>
    <v-card
        class="mx-auto grey lighten-5"
        outlined
        elevation="0"
    >
      <v-img
          class=""
          height="200px"
          :src="product.imageUrl || 'https://via.placeholder.com/4032x3024?text=Immagine+non+disponibile'"
      >
      </v-img>

      <div>
        <div class="card-text">
          <v-card-title class="justify-start world-divided">{{ product.name }}</v-card-title>
          <v-card-subtitle class="justify-start grey--text">
            {{ product.productType.value }}
          </v-card-subtitle>
          <v-card-text>
            <div class="mb-3">
              <div class="text-caption grey--text">Prezzo</div>
              <div class="text-body-1">
                <price-per-unit
                    :measure-unit="product.measureUnit"
                    :price="product.price"
                    :show-kilos="isPieceWeight(product)"
                />
              </div>
            </div>
            <div class="mb-3">
              <div class="text-caption grey--text">Fornitore</div>
              <div class="text-body-1">
                {{ product.supplierBusinessName }}
              </div>
            </div>

            <div class="mb-3">
              <div class="text-caption grey--text mb-n4">Quantità ({{product.orderMode.value}})</div>
              <PurchaseField
                  v-model="product.purchased"
                  rules="min_value:0"
                  label="Quantità"
                  :id="product.id"
                  :min-quantity="product.minQuantity"
                  :multiplier="product.multiplier"
              />
              <span v-if="isPieceWeight(product)">Ogni pezzo ha un peso medio di {{ product.weight }} kg</span>
            </div>

            <div v-if="product.description">
              <div class="text-caption grey--text">Descrizione</div>
              {{ product.description }}
            </div>
          </v-card-text>
        </div>
      </div>
    </v-card>
</template>

<script>
import { stringUtils } from '@/mixins/stringUtils'
import PricePerUnit from '@/components/products/PricePerUnit.vue'
import PurchaseField from '@/components/Field/PurchaseField.vue'

export default {
  name: 'ProductPurchaseCard',
  components: { PurchaseField, PricePerUnit },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  mixins: [stringUtils]
}
</script>

<style scoped>
.card-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 70px auto;
}

.card-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.card-image {
  border-radius: 5px;
}

.card-image i {
  padding: 15px;
}

.card-text {
  text-align: start;
}

.world-divided {
  word-break: keep-all;
}
.clickable {
  cursor: pointer;
}

</style>
