<template>
  <div id="create-product" class="mx-10">
    <h1 class="text-center display-3 mt-6 mb-10">Modifica un prodotto</h1>

    <v-card max-width="90em" class="mx-auto" outlined>
      <v-card-text>
        <validation-observer ref="dataObserver">
          <product-form v-model="productData"/>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isActive" color="primary" class="mx-auto mb-4" @click="saveProduct">
          Salva prodotto
        </v-btn>
      </v-card-actions>
    </v-card>

    <loading-dialog :show="showLoadingDialog" :text="loadingText"/>
  </div>
</template>

<script>
import ProductForm from '@/components/ProductForm/ProductForm'
import LoadingDialog from '@/components/ActionDialog/implementations/LoadingDialog'

export default {
  name: 'EditProduct',
  components: {
    LoadingDialog,
    ProductForm
  },
  data () {
    return {
      productData: {
        product: {
          categoryId: this.$route.params.categoryId,
          description: null,
          ivaCode: null,
          measureUnitCode: null,
          minQuantity: null,
          multiplier: null,
          name: null,
          orderModeCode: null,
          productTypeCode: null,
          weightLevelCode: null,
          post: null,
          imageUrl: null
        },
        image: null,
        imageUrl: null,
        categoryPath: null
      },
      isActive: true,
      showLoadingDialog: false,
      loadingText: 'Modifica del prodotto in corso'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const id = this.$route.params.productId
      this.$http.get('/v1/products/' + id)
        .then((res) => {
          this.productData.product.categoryId = res.data.categoryId
          this.productData.product.description = res.data.description
          this.productData.product.ivaCode = res.data.iva.code
          this.productData.product.measureUnitCode = res.data.measureUnit.code
          this.productData.product.minQuantity = res.data.minQuantity
          this.productData.product.multiplier = res.data.multiplier
          this.productData.product.name = res.data.name
          this.productData.product.orderModeCode = res.data.orderMode.code
          this.productData.product.productTypeCode = res.data.productType.code
          this.productData.product.weightLevelCode = res.data.weightLevel.code
          this.productData.product.post = res.data.post
          this.productData.imageUrl = res.data.imageUrl
          this.productData.categoryPath = res.data.categoryName
        }).catch((err) => {
          this.showGetDataErrorDialog(err)
        })
    },
    showGetDataErrorDialog (err) {
      this.$dialog.error({
        title: 'Attenzione',
        text: err.response.data.message || 'C\'è stato un errore inaspettato, prova a ricaricare la pagina',
        actions: { text: 'Chiudi' }
      })
    },

    async saveProduct () {
      const valid = await this.$refs.dataObserver.validate()
      if (!valid) {
        await this.$dialog.notify.warning('Ci sono errori nei dati inseriti, controlla i campi.')
        return
      }
      this.isActive = false
      const data = new FormData()
      data.append('product', new Blob([JSON.stringify(this.productData.product)], {
        type: 'application/json'
      }))
      data.append('image', this.productData.image)
      try {
        const productId = this.$route.params.productId
        this.showLoadingDialog = true
        await this.$http.put('/v1/products/' + productId, data)
        this.showLoadingDialog = false
        await this.$router.push('/amministrazione/prodotti/' + productId)
      } catch (e) {
        this.showLoadingDialog = false
        this.isActive = true
      }
    }
  }
}
</script>

<style>
</style>
