<template>
  <div>
    <action-dialog
        v-model="show"

        :submit-title="`Seleziona il punto di ritiro a <b>${zoneName}</b>`"
        submit-success-message="Punto di ritiro aggiornato con successo!"
        submit-btn-text="Conferma"

        :fields="fields"
        :field-default-options="fieldDefaultOptions"

        dialog-max-width="50em"

        :submit-promise-callback="setPickUpPointCallback"
        @close="close"
    >
      <template #field.selectedAddress="{ errors, label, classes }">
        <v-select
            outlined
            v-if="pickUpPoints && pickUpPoints.length === 0"
            disabled
            label="Non ci sono indirizzi di consegna per questa zona"
        ></v-select>
        <v-autocomplete
            v-else
            v-model="selectedAddress"
            return-object
            :items="pickUpPoints"
            color="white"
            :label="label"
            :class="classes"
            :error-messages="errors"
            :item-text="formatAddress"
            outlined
            no-data-text="Non ci sono indirizzi di consegna per questa zona"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex flex-row" style="width: 100%">
              <span class="flex-grow-1">
                {{ formatAddress(item) }}
              </span>
              <div class="mr-3">
                <v-chip v-if="item.isDefault" small color="primary">corrente</v-chip>
              </div>
            </div>
            <v-btn color="orange" icon @click.prevent="editZoneAddress(item)">
              <v-icon small>fa-edit</v-icon>
            </v-btn>
            <v-btn color="red" icon @click.prevent="deleteZoneAddress(item)">
              <v-icon small>fa-trash</v-icon>
            </v-btn>
          </template>

          <template v-slot:selection="{ item }">
            {{ formatAddress(item) }}
          </template>
        </v-autocomplete>
      </template>

      <template #fields>
        <v-container class="mb-10">
          <h5>Note aggiuntive:</h5>
          <span>{{ selectedAddress && selectedAddress.notes ? selectedAddress.notes : '-' }}</span>
        </v-container>
      </template>

      <template v-slot:actions>
        <v-btn
            outlined
            color="indigo"
            class="white--text"
            @click="actionModal.show = true"
        >
          Aggiungi un indirizzo
        </v-btn>
      </template>
    </action-dialog>

    <zone-address-dialog
      v-model="actionModal.show"
      :zone-id="zoneId"
      :zone-name="zoneName"
      :edit-mode="actionModal.editMode"
      :zone-address="actionModal.obj"
      @close="zoneAddressDialogClosed"
    />
  </div>

<!--  <action-dialog-->
<!--      v-model="fieldValue"-->
<!--      :data="obj"-->

<!--      :submit-title="`Seleziona il punto di ritiro a <b>${zoneName}</b>`"-->
<!--      submit-success-message="Punto di ritiro aggiornato con successo!"-->
<!--      submit-btn-text="Conferma"-->

<!--      :fields="fields"-->
<!--      :field-default-options="fieldDefaultOptions"-->

<!--      :submit-promise-callback="setPickUpPointCallback"-->
<!--      @close="close"-->
<!--  >-->
<!--    <template #field.selectedUser="{ errors, label, classes }">-->
<!--      <v-autocomplete-->
<!--          v-model="obj.selectedUser"-->
<!--          :items="pickUpPoints"-->
<!--          item-value="id"-->
<!--          color="white"-->
<!--          :label="label"-->
<!--          :class="classes"-->
<!--          :error-messages="errors"-->
<!--          :item-text="nameField"-->
<!--          outlined-->
<!--          no-data-text="Nessun utente della zona ha dato la disponibilità come punto di ritiro"-->
<!--      >-->
<!--        <template v-slot:item="{ item }">-->
<!--          <div class="d-flex flex-row" style="width: 100%">-->
<!--            <span class="flex-grow-1">{{ nameField(item) }}</span>-->
<!--          </div>-->
<!--        </template>-->

<!--        <template v-slot:selection="{ item }">-->
<!--          {{ nameField(item) }}-->
<!--        </template>-->
<!--      </v-autocomplete>-->
<!--    </template>-->
<!--  </action-dialog>-->

<!--  <div>-->
<!--    <loading-dialog text="Caricamento in corso" :show="loading"/>-->

<!--    <v-dialog v-model="show" @click:outside="close" max-width="600px">-->
<!--      <v-card>-->
<!--        <v-card-title>-->
<!--          <h5 class="text-h5">Seleziona il punto di ritiro a <b>{{ zone.name }}</b></h5>-->
<!--        </v-card-title>-->

<!--        <v-card-text class="px-6 mt-5 pb-0">-->
<!--          -->
<!--        </v-card-text>-->

<!--        <v-card-actions class="pa-3">-->
<!--          <v-spacer/>-->
<!--          <v-btn color="blue" text @click="close">-->
<!--            Chiudi-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--              @click="setPickUpPoint"-->
<!--              color="green"-->
<!--              class="white&#45;&#45;text"-->
<!--          >-->
<!--            Conferma-->
<!--          </v-btn>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-dialog>-->
<!--  </div>-->
</template>

<script>
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import ActionDialog from '@/components/ActionDialog/ActionDialog'
import { stringUtils } from '@/mixins/stringUtils'
import ZoneAddressDialog from '@/components/ActionDialog/implementations/ZoneAddressDialog'

export default {
  name: 'PickUpPointDialog',
  components: {
    ZoneAddressDialog,
    ActionDialog
  },
  props: {
    value: {
      type: Boolean
    },
    zoneName: String,
    zoneId: Number,
    distribution: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      pickUpPoints: [],
      // obj: {
      // selectedUser: null
      selectedAddress: null,
      actionModal: {
        show: false,
        obj: {},
        editMode: false
      },
      // },
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12
      },
      fields: [
        // { value: 'selectedUser', label: 'Cerca utente', rules: 'required' }
        { value: 'selectedAddress', label: 'Cerca indirizzo', rules: 'required' }
      ]
    }
  },
  mixins: [usersDataCardFields, stringUtils],
  computed: {
    show: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (!oldValue && newValue) this.init()
    }
  },
  methods: {
    async init () {
      const res = await this.$http.get(`/v1/zones/${this.zoneId}/addresses`)
      this.pickUpPoints = res.data
      // const res = await this.$http.get('/v1/zones/' + this.zoneId + '/pick-up-points')
      // this.pickUpPoints = res.data
    },
    setPickUpPointCallback () {
      if (this.distribution) {
        return this.$http.patch(`/v1/distribution/zones/${this.zoneId}/pick-up-point/${this.selectedAddress.id}`)
        // return this.$http.patch(`/v1/distribution/zones/${this.zoneId}/pick-up-point/${this.obj.selectedUser}`)
      } else {
        return this.$http.patch(`/v1/zones/${this.zoneId}/pick-up-point/${this.selectedAddress.id}`)
        // return this.$http.patch(`/v1/zones/${this.zoneId}/pick-up-points/${this.obj.selectedUser}`)
      }
    },
    close () {
      this.$emit('close')
    },
    editZoneAddress (zoneAddress) {
      this.actionModal.show = true
      this.actionModal.editMode = true

      let obj = {}
      obj = Object.assign(obj, zoneAddress)
      this.actionModal.obj = obj
    },
    async deleteZoneAddress (zoneAddress) {
      // console.log(zoneAddress.id)
      await this.$http.delete(`/v1/zones/${this.zoneId}/address/${zoneAddress.id}`)
      this.selectedAddress = null
      await this.init()
    },
    async zoneAddressDialogClosed () {
      await this.init()

      // to make sure that after an item has been edited from the selection list
      // the same item (edited) is then re-selected so the user doesn't have to do it manually
      if (this.selectedAddress)
        this.selectedAddress = this.pickUpPoints.find((el) => el.id === this.selectedAddress.id)
    }
  }
}
</script>

<style scoped>

</style>
