<template>
  <div class="ma-4">
    <h1 class="text-center text-h3">
      {{ isIndividualCompany ? 'Registrazione ditta individuale' : 'Registrazione azienda' }}
    </h1>

    <v-container>
      <div style="display: flex; flex-direction: row" class="mt-8">
        <v-icon @click="backToIndividualCompanySelection">fa-arrow-left</v-icon>
        <span class="ml-3 text-h6 font-weight-regular">Indietro</span>
      </div>
    </v-container>

    <validation-observer ref="form">
      <v-card class="x-margin mx-auto mt-9" outlined>
        <v-card-title class="text-h4">
          Informazioni sull'attività
        </v-card-title>
        <v-card-text class="mt-2">
          <v-row>
            <text-field
              v-for="field in businessFields"
              :key="field.value"
              v-model="data[field.value]"
              :label="field.label"
              :placeholder="field.placeholder"
              :rules="field.rules"
              :type="field.type"
              :cols="field.cols ? field.cols : '12'"
              :sm="field.sm"
              :md="field.md"
              :lg="field.lg"
              :append-icon="field.appendIcon"
              @click:append="showPassword = !showPassword"
              :help-text="field.helpText"
            ></text-field>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="x-margin mx-auto mt-12" outlined>
        <v-card-title class="text-h4">
          Sede legale
        </v-card-title>
        <v-card-text class="mt-2">
          <v-row>
            <text-field
              v-for="field in headquarterFields"
              :key="field.value"
              v-model="data[field.value]"
              :label="field.label"
              :placeholder="field.placeholder"
              :rules="field.rules"
              :type="field.type"
              :cols="field.cols ? field.cols : '12'"
              :sm="field.sm"
              :md="field.md"
              :lg="field.lg"
              :append-icon="field.appendIcon"
              @click:append="showPassword = !showPassword"
              :help-text="field.helpText"
              less-margin solo
            ></text-field>
          </v-row>
        </v-card-text>
      </v-card>
    </validation-observer>

    <div class="w-100 x-margin mx-auto text-right px-sm-2 mt-6 mb-12">
      <v-btn color="success" @click="submit">Continua</v-btn>
    </div>
  </div>
</template>

<style scoped>

.x-margin {
  max-width: 55em;
}

</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BusinessRegistration',
  data () {
    return {
      data: {
        codiceUnivoco: null,
        pec: null
      },
      headquarterFields: [
        {
          value: 'address',
          placeholder: 'Indirizzo',
          label: 'Indirizzo',
          rules: 'required|max:64',
          md: '6'
        },
        {
          value: 'city',
          placeholder: 'Città',
          label: 'Città',
          rules: 'required|max:64',
          md: '6'
        },
        {
          value: 'province',
          placeholder: 'Provincia',
          label: 'Provincia',
          rules: 'required|max:64',
          md: '6'
        },
        {
          value: 'cap',
          placeholder: 'CAP',
          label: 'CAP',
          rules: 'required|max:6',
          md: '6'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['isIndividualCompany']),
    businessFields () {
      return [
        {
          value: 'businessName',
          placeholder: this.isIndividualCompany ? 'Intestazione' : 'Ragione sociale',
          label: this.isIndividualCompany ? 'Intestazione' : 'Ragione sociale',
          rules: 'required|max:100'
        },
        {
          value: 'fiscalCode',
          placeholder: 'Codice fiscale',
          label: 'Codice fiscale',
          rules: 'required|max:20',
          md: '6'
        },
        {
          value: 'partitaIva',
          placeholder: 'Partita IVA',
          label: 'Partita IVA',
          rules: 'required|max:16',
          md: '6'
        },
        {
          value: 'codiceUnivoco',
          placeholder: 'Codice univoco',
          label: 'Codice univoco',
          rules: { required: !this.data.pec, max: 10 },
          md: '6'
        },
        {
          value: 'pec',
          placeholder: 'Pec',
          label: 'Pec',
          rules: { required: !this.data.codiceUnivoco, max: 80 },
          md: '6',
          helpText: 'La PEC è obbligatoria solo se non viene inserito il codice univoco.'
        }
      ]
    }
  },
  methods: {
    async submit () {
      try {
        const valid = await this.$refs.form.validate()
        if (!valid) return

        await this.$http.post('v1/users/register/business', this.data)
        this.$store.commit('businessRegistered')
        await this.$router.push('persona-fisica')
      } catch (e) {
      }
    },
    async backToIndividualCompanySelection () {
      try {
        await this.$http.get('/v1/users/register/individualCompany/rollback')
        await this.$store.commit('chooseIndividualCompany', null)
        await this.$router.push('/iscriviti/continua')
      } catch (e) {}
    }
  }
}
</script>
