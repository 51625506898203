<template>
  <v-container>
    <h1 class="text-center display-2 font-weight mt-8">Carrello</h1>
    <v-col v-if="openedOrders && openedOrders.length === 0" class="mt-16">
      <v-row justify="center">
        <v-img
            max-height="250"
            max-width="250"
            src='@/assets/empty_cart.svg'
        ></v-img>
      </v-row>
      <p class="text-center font-weight-bold mt-16">Il carrello è attualmente vuoto</p>
      <p class="text-center font-weight-light grey--text">
        <a @click="$router.push('/')"> Torna alla home </a> e aggiungi i prodotti di tuo interesse
      </p>
    </v-col>
    <div v-else>
<!--      <v-alert-->
<!--          border="top"-->
<!--          colored-border-->
<!--          type="info"-->
<!--          elevation="2"-->
<!--          transition="slide-y-transition"-->
<!--          class="mt-8"-->
<!--      >-->
<!--        Ricordiamo che al momento dell’inizio della distribuzione viene bloccato (preautorizzato) sulla carta di credito-->
<!--        un importo superiore a quello della spesa. Questo comprende una piccola percentuale aggiuntiva sul totale che-->
<!--        serve a garantire, una volta chiuso l'ordine, che l'importo effettivo non superi quello preautorizzato, evitando-->
<!--        così la necessità di un doppio addebito per la differenza.-->
<!--      </v-alert>-->
      <paniere-in-cart
          class="mt-8"
          v-for="order in openedOrders"
          :key="order.id"
          :order="order"
      />
    </div>

    <div v-if="closedOrders && closedOrders.length">
      <hr style="border: 1px solid #dddddd;">
      <h1 class="display-1 font-weight mt-8">In arrivo</h1>

      <paniere-in-cart class="mb-16 mt-5"
                       v-for="order in closedOrders"
                       :key="order.id"
                       :order="order"
                       not-editable
      />
    </div>
  </v-container>
</template>

<script>
import PaniereInCart from '@/views/Cart/PaniereInCart'

export default {
  name: 'Cart',
  components: { PaniereInCart },
  data () {
    return {
      openedOrders: null,
      closedOrders: null,
      headers: [
        {
          text: 'Nome',
          value: 'name'
        },
        // { text: 'Descrizione', value: 'description' },
        {
          text: 'Fornitore',
          value: 'supplierBusinessName'
        },
        {
          text: 'Prezzo',
          value: 'price'
        },
        {
          text: 'Quantità',
          value: 'purchased'
        },
        {
          text: 'Prezzo totale',
          value: 'totalPrice'
        }
      ]
    }
  },
  computed: {},
  mounted () {
    this.loadOpenedOrders()
    this.loadClosedOrders()
  },
  methods: {
    async loadClosedOrders () {
      const response = await this.$http.get('v1/carts/panieri/status/closed')
      this.closedOrders = response.data
    },
    async loadOpenedOrders () {
      const response = await this.$http.get('v1/carts/panieri/status/opened')
      this.openedOrders = response.data
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
