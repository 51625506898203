<template>
  <v-container>
    <h1 class="text-center display-2 font-weight mt-8 mb-6">I miei pagamenti</h1>

    <div v-if="payments">
      <payment-list-item v-for="p in payments"
                          :key="p.id"
                          :payment="p"
      />
    </div>
    <div v-else>
      <v-skeleton-loader
          v-for="n in 3"
          :key="n"
          type="table-heading"
      />
    </div>
  </v-container>
</template>

<script>
import PaymentListItem from '@/components/deliveries_payments/PaymentListItem'
export default {
  name: 'Payments',
  components: { PaymentListItem },
  data () {
    return {
      payments: null
    }
  },
  mounted () {
    this.loadPayments()
  },
  methods: {
    async loadPayments () {
      const response = await this.$http.get('v1/payments')
      this.payments = response.data
    }
  }
}
</script>
