<template>
  <v-container>
    <h1>Benvenuto nel gestionale di amministrazione della PDO</h1>
    <p>Seleziona una pagina qui in parte per accedere a tutte le funzioni.</p>
    <p>In futuro in questa pagina si potrà vedere un resoconto del funzionamento della piattaforma.</p>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>

<style scoped>

</style>
