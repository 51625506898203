<template>
  <div v-if="!memberChosen">
    <chose-card
        title="Vuoi iscriverti all'associazione come socio?"
        :hints="memberHints"
        :options="memberOptions"
    />
  </div>

  <div v-else-if="!typeChosen">
    <chose-card
        title="Chi sei?"
        :options="typeOptions"
        show-back-button
        @backButtonClick="backToMemberSelection"
    />
  </div>

  <div v-else-if="!businessTypeChosen">
    <chose-card
        title="Che tipo di azienda hai?"
        :options="businessTypeOptions"
        show-back-button
        @backButtonClick="backToBusinessSelection"
    />
  </div>
</template>

<script>
import ChoseCard from '@/components/Registration/ChoseCard'
import Vue from 'vue'

export default Vue.extend({
  name: 'ContinueRegistration',
  components: { ChoseCard },
  data () {
    return {
      memberHints: {
        title: 'Che vantaggi ho?',
        text: '<p>Il costo della quota associativa annuale è di <b>€ 15,00</b>. Contribuendo con questa modesta somma, avremo la ' +
            'possibilità di organizzare vari eventi a cui potrai prendere parte sia come partecipante che come volontario. ' +
            'L\'iscrizione non implica alcun obbligo, ma ti offre la possibilità di rimanere informato sulle attività ' +
            'dell\'associazione e di farne parte attivamente.</p>' +

            '<p>I soci hanno la possibilità di utilizzare il servizio di pagamento <b>SEPA</b> per effettuare un ' +
            'unico addebito a fine mese.</p>' +

            '<p>Stiamo anche lavorando per offrire ulteriori vantaggi esclusivi ai nostri soci come poter usufruire di ' +
            'sconti speciali su prodotti di stagione in determinati periodi dell\'anno. </p>'
      },
      memberOptions: [
        {
          text: 'Diventa socio!',
          icon: 'fas fa-hand-holding-heart',
          click: () => this.chooseMember(true)
        },
        {
          text: 'Voglio solo acquistare',
          icon: 'fas fa-utensils',
          click: () => this.confirmNotMember()
        }
      ],
      typeOptions: [
        {
          text: 'Privato',
          icon: 'fas fa-house-user',
          click: () => this.chooseBusiness(false)
        },
        {
          text: 'Azienda o associazione',
          icon: 'fas fa-industry',
          click: () => this.chooseBusiness(true)
        }
      ],
      businessTypeOptions: [
        {
          text: 'Ditta individuale',
          icon: 'fas fa-user-tie',
          click: () => this.chooseIndividualCompany(true)
        },
        {
          text: 'Società o associazione',
          icon: 'fas fa-building',
          click: () => this.chooseIndividualCompany(false)
        }
      ]
    }
  },
  computed: {
    memberChosen () {
      return this.$store.getters.isMember !== null
    },
    typeChosen () {
      return this.$store.getters.isBusiness !== null
    },
    businessTypeChosen () {
      return this.$store.getters.isIndividualCompany !== null
    }
  },
  methods: {
    confirmNotMember () {
      const that = this
      this.$dialog.warning({
        title: 'Sei sicuro?',
        text: 'Sei sicuro di voler continuare senza iscriverti come socio? ' +
            'Non potrai utilizzare il SEPA come metodo di pagamento.',
        width: 500,
        actions: [
          {
            text: 'Voglio solo acquistare',
            color: 'grey darker-1',
            handler () {
              that.chooseMember(false)
            }
          },
          {
            text: 'Diventa socio!',
            color: 'success darker-3',
            outlined: true,
            handler () {
              that.chooseMember(true)
            }
          }
        ]
      })
    },
    async chooseMember (chosen) {
      try {
        await this.$store.dispatch('chooseMember', chosen)
      } catch (e) {
      }
    },
    async chooseBusiness (chosen) {
      try {
        await this.$store.dispatch('chooseBusiness', chosen)
        if (!chosen) await this.$router.push('/iscriviti/continua/azienda')
      } catch (e) {
      }
    },
    async chooseIndividualCompany (chosen) {
      try {
        await this.$store.dispatch('chooseIndividualCompany', chosen)
        await this.$router.push('/iscriviti/continua/azienda')
      } catch (e) {
      }
    },
    async backToMemberSelection () {
      try {
        await this.$http.get('/v1/users/register/member/rollback')
        await this.$store.commit('chooseMember', null)
      } catch (e) {
      }
    },
    async backToBusinessSelection () {
      try {
        await this.$http.get('/v1/users/register/business/rollback')
        await this.$store.commit('chooseBusiness', null)
      } catch (e) {
      }
    }
  }
})
</script>
