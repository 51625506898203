<template>
  <action-dialog
      v-model="fieldValue"
      :data="category"
      :edit-mode="editMode"

      submit-title="Crea una categoria"
      edit-title="Modifica un categoria"
      submit-success-message="Categoria creata con successo!"
      edit-success-message="Categoria modificata con successo!"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createCallback"
      :edit-promise-callback="editCallback"
      @close="close"
  />
</template>

<script>
import ActionDialog from '@/components/ActionDialog/ActionDialog'

export default {
  name: 'CategoryDialog',
  components: { ActionDialog },
  props: {
    value: {
      type: Boolean
    },
    category: {
      type: Object
    },
    parentCategory: {
      type: Number
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true
      },
      fields: [
        { value: 'name', label: 'Nome', rules: 'required', cols: '12', sm: '12' }
      ]
    }
  },
  computed: {
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    createCallback () {
      const address = '/v1/categories' + (this.parentCategory ? ('/' + this.parentCategory) : '')
      return this.$http.post(
        address,
        this.category
      )
    },
    editCallback () {
      return this.$http.put(
        '/v1/categories/' + this.category.id,
        this.category
      )
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
