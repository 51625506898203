<template>
  <loading-dialog show text="Stiamo verificando la tua mail..."></loading-dialog>
</template>

<script>
import LoadingDialog from '@/components/ActionDialog/implementations/LoadingDialog'
export default {
  name: 'ConfirmEmail',
  components: { LoadingDialog },
  async mounted () {
    if (!this.$route.query.token)
      this.$router.push('/')

    try {
      await this.$http.patch('v1/users/register/confirm-email/' + this.$route.query.token)
      this.$dialog.notify.success('Email confermata con successo')
    } catch (e) {
      this.$dialog.notify.error('Errore durante la conferma dell\'email')
    }
    this.$router.push('/iscriviti')
  }
}
</script>

<style scoped>

</style>
