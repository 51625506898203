<template>
  <div>
    <DataCard
      title-icon="fas fa-carrot"
      title="Lista prodotti"
      :items="products"
      :headers="headers"
      row-pointer
      search-label="Cerca prodotto"
      :action-modal.sync="actionModal.show"
      :action-modal-edit-mode.sync="actionModal.editMode"
      :action-modal-object.sync="actionModal.obj"
      :create-callback="redirectToCreateProductPage"
      no-data-text="Non esiste ancora nessun prodotto!"
      :delete-callback="deleteItem"
      :update-callback="editItem"
      :operations="['create','update']"
      @click:row="productClick"
    />
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'

export default {
  name: 'Prodotti',
  components: {
    DataCard
  },

  data () {
    return {
      products: [],
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Categoria', value: 'categoryName' },
        { text: 'Tipologia', value: 'productType.value' },
        { text: 'Azioni', value: 'crud', sortable: false }
      ],
      actionModal: {
        show: false,
        obj: {},
        editMode: true
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      try {
        const response = await this.$http.get('v1/products')
        this.products = response.data
      } catch (e) {
      }
    },
    async deleteItem (item) {
      try {
        await this.$dialog.confirmation.deletion(`Sei sicuro di voler cancellare il prodotto <b>${item.name}</b>?`)
        await this.$http.delete('v1/products/' + item.id)
        await this.init()
      } catch (e) {
      }
    },
    editItem (product) {
      this.$router.push('/amministrazione/editproduct/' + product.id)
    },
    productClick (product) {
      this.$router.push('/amministrazione/prodotti/' + product.id)
    },
    redirectToCreateProductPage () {
      this.$router.push('/amministrazione/prodotti/crea')
    }
  }
}
</script>

<style scoped>

</style>
