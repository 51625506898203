<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scroll-y-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="fieldValue"
        :class="classes"
        :label="label"
        :error-messages="errors"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="fieldValue"
      :max="max"
      :min="min"
      @change="$refs.menu.save($event)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    label: String,
    value: String,
    classes: [String, Object],
    errors: {
      type: Array
    },
    yearFirst: {
      type: Boolean,
      default: () => false
    },
    min: {
      type: String
    },
    max: {
      type: String
    }
  },
  computed: {
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  data () {
    return {
      menu: false
    }
  },
  watch: {
    menu (val) {
      // eslint-disable-next-line no-return-assign
      val && this.yearFirst && setTimeout(() => this.$refs.picker.activePicker = 'YEAR')
    }
  }
}
</script>

<style scoped>

</style>
