<template>
  <div class="ma-4">
    <h1 class="text-center text-h3 ">
      Ultimo passo!
    </h1>

    <validation-observer ref="form">
      <form-card title="Zona di appartenenza">
        <v-col class="text-body-2" cols="12">
          La zona di appartenenza è importante per poter consegnare la merce al punto più vicino a te. Ogni zona dispone
          di almeno un punto di ritiro presso la casa di uno dei nostri associati. Nell'elenco troverai tutti i punti
          attivi con i relativi indirizzi. Tieni presente che tali punti di consegna non sono attività commerciale ma
          privati che si rendono disponibili per tale servizio, ci sono quindi orari prestabiliti per il ritiro, di
          solito dopo le 17:00 del mercoledì di consegna. Se desideri ulteriori informazioni, ti invitiamo a inviare una
          mail a <a href="mailto:info@pdobassogardabio.it">info@pdobassogardabio.it</a> e saremo lieti di fornirti tutte le informazioni necessarie.
        </v-col>

        <text-field
            label="Seleziona la tua zona"
            rules="required"
            cols="12"
        >
          <template #default="{ errors, classes, label }">
            <v-autocomplete
                v-model="data.zoneId"
                :items="zones"
                :class="classes"
                :error-messages="errors"
                :label="label"
                :placeholder="label"
                less-margin solo
            ></v-autocomplete>
          </template>
        </text-field>

        <v-col
            v-if="$store.getters.isMember"
            cols="12"
        >
          <div class="d-flex d-inline-block align-center align-content-center">
            <v-checkbox
                v-model="data.canBePickUpPoint"
                label="Voglio dare la mia disponibilità per essere un punto di ritiro"
            ></v-checkbox>
            <v-btn icon @click="showInfo">
              <v-icon color="info">
                fas fa-question-circle
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </form-card>

      <form-card title="Soggetti di appartenenza">
        <v-col class="text-body-2" cols="12">
          Seleziona uno o più soggetti a cui appartieni che si ispirano o condividono i principi dell’economia solidale
        </v-col>
        <v-col class="mt-n2" cols="12">
          <v-checkbox
              label="Non appartengo a nessun soggetto"
              class="mb-n5"
          />
        </v-col>

        <v-col v-for="(field, index) in belongsTo" :key="'belongsToCheckBox' + index" cols="12" sm="12" md="6"
               lg="4">
          <v-checkbox
              v-model="field.selected"
              :label="field.name"
              class="mb-n5"
          />
          <text-field
              v-if="field.selected"
              v-model="field.value"
              :label="'Nome ' + field.name"
              rules="required"
              class="mb-n5 mt-2"
          />
        </v-col>
      </form-card>

      <form-card title="Statuto" v-if="$store.getters.isMember">
        <v-col class="text-body-2" cols="12">
          Leggi
          <a href="https://www.pdobassogardabio.it/regolamento-pdo-basso-garda.html" target="_blank"
          >il nostro statuto</a>
          prima di continuare!
          (vedi
          <a href="https://www.pdobassogardabio.it/wp-content/uploads/2015/11/PDO_basso_Garda_Regolamento_20151.pdf"
             target="_blank">qui</a> il PDF)
        </v-col>

        <text-field
            label="L'ho letto e lo accetto!"
            :rules="{ required: { allowFalse: false } }"
            cols="12"
        >
          <template #default="{ errors, label }">
            <v-checkbox
                v-model="statuto"
                :label="label"
                :error-messages="errors"
            ></v-checkbox>
          </template>
        </text-field>
      </form-card>

      <form-card title="Termini e condizioni">
        <v-col class="text-body-2" cols="12">
          Leggi i
          <a href="https://www.iubenda.com/termini-e-condizioni/75270385"
             class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
             title="Termini e Condizioni"
             target="_blank"
          >
            Termini e Condizioni
          </a>
          prima di continuare
        </v-col>

        <text-field
            label="Ho letto e accetto i Termini e Condizioni"
            :rules="{ required: { allowFalse: false } }"
            cols="12"
        >
          <template #default="{ errors, label }">
            <v-checkbox
                v-model="termsAndConditions"
                :label="label"
                :error-messages="errors"
            ></v-checkbox>
          </template>
        </text-field>
      </form-card>

      <div class="w-100 x-margin mx-auto text-right px-sm-2 mt-6 mb-12">
        <v-btn color="success" @click="submit">Completa la registrazione</v-btn>
      </div>
    </validation-observer>
  </div>
</template>

<style scoped>
.x-margin {
  max-width: 55em;
}
</style>

<script>
import FormCard from '@/components/Registration/FormCard'

export default {
  name: 'FinishRegistration',
  components: { FormCard },
  data () {
    return {
      statuto: false,
      termsAndConditions: false,
      data: {
        zoneId: null,
        canBePickUpPoint: false,
        belongsTo: []
      },
      zones: [],
      belongsTo: [
        { key: 'gas', selected: false, value: '', name: 'GAS' },
        { key: 'associazione', selected: false, value: '', name: 'Associazione' },
        { key: 'cooperativa', selected: false, value: '', name: 'Cooperativa' },
        { key: 'aziendaAgricola', selected: false, value: '', name: 'Azienda agricola' },
        { key: 'aziendaArtigiana', selected: false, value: '', name: 'Azienda artigiana' },
        { key: 'altroGruppoInformale', selected: false, value: '', name: 'Altro gruppo informale' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      try {
        const response = await this.$http('v1/zones')

        this.zones = response.data.map(z => {
          return {
            value: z.id,
            text: z.name
          }
        })
      } catch (e) {
      }
    },
    showInfo () {
      this.$dialog.info({
        title: 'Cosa significa fare da punto di consegna?',
        text: '<p>Se hai familiari, amici, vicini di casa o conoscenti (almeno 4/5 famiglie) che sono interessati ad utilizzare questa piattaforma, puoi incoraggiarli ad iscriversi e creare un punto di consegna presso la tua abitazione. In questo modo, le cassette contenenti i prodotti della tua zona verranno consegnate direttamente a casa tua il mercoledì pomeriggio. Gli altri associati potranno poi ritirare i loro ordini in base alla tua disponibilità. È un modo pratico e conveniente per facilitare la consegna dei prodotti ai membri della tua comunità.</p>' +
            '' +
            '<p>Per poterti attivare come punto di consegna inviaci una mail con la richiesta a <a href="mailto:info@pdobassogardabio.it">info@pdobassogardabio.it</a>.</p>',
        icon: false
      })
    },
    mapBelongsTo () {
      this.data.belongsTo = this.belongsTo
        .filter(({ selected }) => selected)
        .map(({ key, value }) => {
          return { key, value }
        })
    },
    async submit () {
      try {
        const valid = await this.$refs.form.validate()
        if (!valid) return

        this.mapBelongsTo()

        await this.$http.post('v1/users/register/finish', this.data)
        await this.$store.dispatch('renewToken')
        await this.$router.push('/profilo')
      } catch (e) {
      }
    }
  }
}
</script>
