<template>
  <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      @close="close"
  >
    <v-card tile>
      <v-toolbar flat color="primary">
        <v-btn icon class="white--text" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="showDialog" class="my-4">
        <div v-for="(card, index) in dataToShow"
             v-bind:key="'card-slot-' + index"
             style="max-width: 60rem"
             class="mx-auto my-8"
        >
          <slot :name="'data-container.' + card.slotName">
            <v-card outlined>
              <v-card-title>
                {{ card.title }}
              </v-card-title>
              <v-card-text>
                <slot v-if="card.slotName" :name="'data-text.' + card.slotName"></slot>
                <div v-else>
                  <v-row v-for="(row, index) in card.information" v-bind:key="index">
                    <v-col cols="5" md="4" class="pr-8 pl-5 text-right text-overline">{{ row.name }}</v-col>
                    <v-col cols="7" md="8" class="align-self-center" v-html="row.value"/>
                  </v-row>
                  <slot name="actions"></slot>
                </div>
              </v-card-text>
            </v-card>
          </slot>
        </div>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommonDetailsDialog',
  props: {
    value: Boolean,
    title: String,
    dataToShow: Array
  },
  computed: {
    showDialog: {
      get () {
        return !!this.value
      },
      set (newVal) {
        if (!newVal) this.$emit('input', null)
      }
    }
  },
  methods: {
    close () {
      this.$emit('input', null)
    },
    formatDate (dateNumber) {
      return new Date(dateNumber).toLocaleDateString('it')
    }
  }
}
</script>

<style scoped>

</style>
