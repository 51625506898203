<template>
  <v-container class="text-center choose-container">
    <h1 class="text-sm-h2 text-h3 mt-2">{{ title }}</h1>
    <v-btn v-if="hints && hints.title" @click="openHints" class="mt-2" x-large outlined color="primary">
      {{ hints.title }}
    </v-btn>

    <div v-if="showBackButton" style="display: flex; flex-direction: row" class="mt-8">
      <v-icon @click="$emit('backButtonClick')">fa-arrow-left</v-icon>
      <span class="ml-3 text-h6 font-weight-regular">Indietro</span>
    </div>

    <div class="mt-10 w-100 d-flex justify-space-around flex-wrap">
      <v-card outlined
              v-for="(opt, index) in options"
              v-bind:key="index"
              class="choose-card"
              @click="opt.click"
      >
        <div class="h-100 d-flex align-center justify-center pa-5">
          <div class="text-center">
            <v-icon size="4rem">{{ opt.icon }}</v-icon>
            <div class="text-h5 mt-3">{{ opt.text }}</div>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<style scoped>

.choose-container {
  max-width: 73em!important;
}

.choose-card {
  width: 23rem;
  height: 18rem;
  margin: 2rem;

  cursor: pointer;
}

.choose-card:hover {
  font-size: 10px;
  cursor: pointer;
}

</style>

<script>
export default {
  name: 'ChoseCard',
  props: {
    title: {
      type: String,
      required: true
    },
    hints: Object,
    options: {
      type: Array,
      required: true
    },
    showBackButton: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    openHints () {
      this.$dialog.info({
        title: this.hints.title,
        text: this.hints.text,
        icon: false
      })
    }
  }
}
</script>
