<template>
  <div>
    <div v-if="resetToken">
      <validation-observer ref="resetInputObserver">
        <v-card flat outlined width="30em" class="mx-auto mt-12 login-card" @keyup.enter="resetPassword">
          <v-card-title class="mt-1"><h2 class="text-center">Reimposta la tua password</h2></v-card-title>
          <v-card-text>
            <text-field
                v-model="resetPasswordData.newPassword"
                label="Nuova password"
                rules="required"
                :append-icon="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                less-margin solo
            />
            <text-field
                v-model="confirmPassword"
                label="Conferma password"
                rules="required"
                :append-icon="showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                less-margin solo
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" :loading="loading" @click="resetPassword">Conferma nuova password</v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </div>
    <validation-observer ref="inputObserver" v-else-if="!emailSent">
      <v-card flat outlined width="30em" class="mx-auto mt-12 login-card" @keyup.enter="sendEmail">
        <v-card-title class="mt-1"><h2 class="text-center">Reimposta la tua password</h2></v-card-title>
        <v-card-text>
          <text-field
              v-model="sendEmailData.email"
              label="Inserisci la tua mail"
              rules="required|email"
              less-margin solo
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" :loading="loading" @click="sendEmail">Reimpostala ora</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>

    <div v-else>
      <v-card flat outlined width="30em" class="mx-auto mt-12 login-card" @keyup.enter="register">
        <v-card-title class="mt-1"><h2 class="text-center">Reimposta la tua password</h2></v-card-title>
        <v-card-text>
          <p>Abbiamo inviato una mail a <b>{{ sendEmailData.email }}</b> con le istruzioni per reimpostare la tua password.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" :loading="loading" to="/login">Ritorna al login</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      resetToken: this.$route.query.token,
      sendEmailData: {
        email: null
      },
      resetPasswordData: {
        token: this.$route.query.token,
        newPassword: null
      },
      confirmPassword: null,

      emailSent: false,
      showPassword: false,
      showConfirmPassword: false,
      loading: false
    }
  },
  async mounted () {
    if (this.resetToken) {
      const res = await this.$http.post('v1/users/password/reset/valid', {
        token: this.resetToken
      })
      console.log(res.data)
      if (!res.data) {
        this.$dialog.notify.error('Il link per resettare la password non è più valido').then()
        await this.$router.push('/login')
      }
    }
  },
  methods: {
    async sendEmail () {
      this.loading = true
      const valid = await this.$refs.inputObserver.validate()
      if (!valid) {
        this.loading = false
        return
      }
      try {
        await this.$http.post('v1/users/password/reset/email', this.sendEmailData)
        this.loading = false
        this.emailSent = true
      } catch (e) {
        this.loading = false
      }
    },
    async resetPassword () {
      this.loading = true
      const valid = await this.$refs.resetInputObserver.validate()
      if (!valid) {
        this.loading = false
        return
      }
      if (this.resetPasswordData.newPassword !== this.confirmPassword) {
        this.$dialog.notify.error('Le password non corrispondono').then()
        this.loading = false
        return
      }
      try {
        console.log(this.resetPasswordData)
        await this.$http.post('v1/users/password/reset', this.resetPasswordData)
        this.loading = false
        this.$dialog.notify.success('Password reimpostata con successo').then()
        await this.$router.push('/login')
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.login-card {
  margin-top: 13em;
}
</style>
