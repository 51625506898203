<template>
  <action-dialog
      v-model="show"
      :edit-mode="editMode"

      :submit-title="`Aggiungi un indirizzo a <b>${zoneName}</b>`"
      submit-success-message="Indirizzo aggiunto con successo!"
      edit-success-message="Indirizzo modificato con successo!"
      submit-btn-text="Aggiungi"

      :fields="fields"
      :data="zoneAddress"

      :submit-promise-callback="addZoneAddress"
      :edit-promise-callback="editZoneAddress"
      @close="close"
  >
   <template #field.notes="{ errors, label, classes, rules }">
    <v-textarea
        v-model="zoneAddress.notes"
        outlined
        :label="label"
        :class="classes"
        :error-messages="errors"
        :counter="256"
        :rules="rules"
    />
   </template>
  </action-dialog>
</template>

<script>
import ActionDialog from '@/components/ActionDialog/ActionDialog'
export default {
  name: 'ZoneAddressDialog',
  components: { ActionDialog },
  props: {
    value: {
      type: Boolean
    },
    zoneName: String,
    zoneId: Number,
    editMode: {
      type: Boolean,
      required: true
    },
    zoneAddress: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      zone: null,
      loadingAddAddress: false,
      fields: [
        { value: 'address', label: 'Indirizzo', rules: 'required', cols: 6 },
        { value: 'city', label: 'Città', rules: 'required', cols: 6 },
        { value: 'province', label: 'Provincia', rules: 'required', cols: 6 },
        { value: 'cap', label: 'CAP', rules: 'required', cols: 6 },
        { value: 'notes', label: 'Note aggiuntive', rules: 'max: 256', cols: 12 }
      ]
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    async addZoneAddress () {
      await this.$http.post(
          `/v1/zones/${this.zoneId}/address/create`,
          this.zoneAddress
      )
    },
    async editZoneAddress () {
      await this.$http.put(
          `/v1/zones/${this.zoneId}/address/${this.zoneAddress.id}`,
          this.zoneAddress
      )
    },
    close () {
      this.show = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
