<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
            v-model="query"
            label="Cerca prodotto o categoria"
            outlined
            placeholder="Inserisci il nome di un prodotto o di una categoria"
            @input="search"
            append-icon="fa-info-circle"
            @click:append="showSearchInfoDialog = true"
        />
      </v-col>
    </v-row>
    <search-info-dialog v-model="showSearchInfoDialog"/>
  </v-container>
</template>

<script>
import SearchInfoDialog from '@/components/ActionDialog/implementations/SearchInfoDialog'

export default {
  name: 'SearchBar',
  components: {
    SearchInfoDialog
  },
  data () {
    return {
      query: null,
      searchTimeout: null,
      showSearchInfoDialog: false
    }
  },
  methods: {
    search () {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(async () => {
        if (!this.query) {
          this.$emit('clear')
          return
        }

        const res = await this.$http.get(`/v1/products/search/${this.query}`)
        this.$emit('search', {
          query: this.query,
          data: res.data
        })
      }, 500)
    }
  }
}
</script>

<style scoped>

</style>
