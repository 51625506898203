<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-col>
        <span class="ml-1 text-left font-weight-bold">Categoria</span>
        <v-autocomplete
            v-model="firstCategoryId"
            :items="categories"
            item-value="id"
            item-text="name"
            label="Categorie"
            solo
            @change="onFirstCategoryChange"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <span class="ml-1 text-left font-weight-bold">Sub categoria</span>
        <v-autocomplete
            v-model="secondCategoryId"
            :items="subCategories"
            item-value="id"
            item-text="name"
            label="Sub categorie"
            solo
            @change="onSecondCategoryChange"
        >
        </v-autocomplete>
      </v-col>
      <v-card-actions>
        <v-btn color="primary" text @click.stop="show=false">Salva</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'SelectCategory',
  props: {
    showCategory: Boolean,
    value: Number
  },
  computed: {
    show: {
      get () {
        return this.showCategory
      },
      set (val) {
        if (!val) this.$emit('categorySelected', val)
      }
    }
  },
  data () {
    return {
      firstCategoryId: null,
      secondCategoryId: null,
      thirdCategoryId: null,
      categories: [],
      subCategories: []
    }
  },
  watch: {
    show (val) {
      if (val === true && this.value) {
        this.rightPositionCategory(this.value)
        this.getSubCategories(this.value)
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.getCategories()
    },
    async getCategories () {
      try {
        const res = await this.$http.get('/v1/categories/all')
        this.categories = res.data
      } catch (err) {
        this.showGetDataErrorDialog(err)
      }
    },
    async getSubCategories (category) {
      this.$http.get('/v1/categories/' + category + '/all')
        .then((res) => {
          this.subCategories = res.data.childCategories
        }).catch((err) => {
          this.showGetDataErrorDialog(err)
        })
    },
    async rightPositionCategory (category) {
      this.$http.get(`/v1/categories/${category}/path`)
        .then((res) => {
          if (res.data.parentCategory === null) {
            this.firstCategoryId = category
          } else {
            this.firstCategoryId = res.data.parentCategory.id
            this.getSubCategories(this.firstCategoryId)
            this.secondCategoryId = category
          }
        }).catch((err) => {
          this.showGetDataErrorDialog(err)
        })
    },
    onFirstCategoryChange (newVal) {
      this.secondCategoryId = null
      this.$emit('input', newVal)
      this.getSubCategories(newVal)
    },
    onSecondCategoryChange (newVal) {
      this.$emit('input', newVal)
    }
  }

}
</script>

<style scoped>

</style>
