<template>
  <v-banner
      color="accent"
    v-model="registrationBanner"
    sticky
  >
    Abbiamo bisogno di qualche informazione in più per poter fare acquisti. Inseriscili subito per essere pronto al prossimo ordine!

    <template v-slot:actions="{ dismiss }">
      <v-btn outlined text color="primary" @click="dismiss">
        Più tardi
      </v-btn>
      <v-btn class="primary--text ml-3" light @click="completeRegistration">
        Completa registrazione
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: 'RegistrationBanner',
  data () {
    return {
      showBanner: true
    }
  },
  computed: {
    registrationBanner: {
      get () {
        return this.$store.getters.isLogged &&
          !this.$store.getters.registrationComplete &&
          !this.$route.path.startsWith('/iscriviti/continua') &&
          this.showBanner
      },
      set (newVal) {
        this.showBanner = newVal
      }
    }
  },
  methods: {
    async completeRegistration () {
      try {
        await this.$router.push('/iscriviti/continua')
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>
