<template>
  <v-container>
    <h1 class="text-center display-2 font-weight mt-8 mb-6">
      Consegna
      <span v-if="delivery" class="font-weight-bold">{{formatDate(delivery.date)}}</span>
    </h1>

    <div v-if="delivery">
      <v-card elevation="4" class="mb-10 mx-auto cursor-pointer" style="max-width: 70rem">
        <div v-if="delivery.payment" class="pa-5" @click="goPayment">
          <v-row class="align-center">
            <v-col cols="12" sm="5" class="subtitle-1">
              Pagato il
              <span class="font-weight-bold">{{formatDate(delivery.payment.date)}}</span>
              <span v-if="manyDeliveries">
                insieme ad altre {{delivery.payment.deliveriesCount-1}} consegne
              </span>
            </v-col>
            <v-col cols="12" sm="4" class="text-caption">
              A breve sarà visibile qui il metodo di pagamento utilizzato.
              <payment-method v-if="delivery.payment.method" :method="delivery.payment.method"/>
            </v-col>
            <v-col cols="12" sm="3" class="text-center text-sm-right">
              <span class="text-caption">Totale pagato: </span>
              <span class="mr-3 text-h5">{{formatMoney(delivery.payment.total)}}</span>
            </v-col>
          </v-row>
        </div>
        <v-card-text v-else>
          Il pagamento della consegna non è ancora stato effettuato e verrà saldato alla fine del mese, insieme a tutte le altre consegne con pagamento sospeso.
        </v-card-text>
      </v-card>

      <h1 class="display-1 font-weight mt-8 mb-6">
        Ordini consegnati
      </h1>

      <paniere-in-cart class="mb-16 mt-5"
                       v-for="order in panieri"
                       :key="order.id"
                       :order="order"
                       not-editable show-real-total
      />
    </div>
  </v-container>
</template>

<script>
import PaniereInCart from '@/views/Cart/PaniereInCart'
import PaymentMethod from '@/components/deliveries_payments/PaymentMethod'
export default {
  name: 'DeliveriesDetails',
  components: { PaymentMethod, PaniereInCart },
  data () {
    return {
      delivery: null,
      panieri: null
    }
  },
  mounted () {
    this.loadDelivery()
  },
  computed: {
    manyDeliveries () {
      return this.delivery.payment?.deliveriesCount > 1
    }
  },
  methods: {
    async loadDelivery () {
      const response = await this.$http.get('v1/deliveries/' + this.$route.params.id)
      this.panieri = response.data.panieri
      this.delivery = response.data.delivery
    },
    goPayment () {
      this.$router.push('/pagamenti/' + this.delivery.payment.id)
    }
  }
}
</script>
