<template>
  <v-container>
    <h1 class="text-center display-2 font-weight mt-8 mb-6">Le mie consegne</h1>

    <div v-if="deliveries">
      <delivery-list-item v-for="d in deliveries"
                          :key="d.distSessionId"
                          :delivery="d"
      />
    </div>
  </v-container>
</template>

<script>
import DeliveryListItem from '@/components/deliveries_payments/DeliveryListItem'

export default {
  name: 'Deliveries',
  components: { DeliveryListItem },
  data () {
    return {
      deliveries: null
    }
  },
  mounted () {
    this.loadDeliveries()
  },
  methods: {
    async loadDeliveries () {
      const response = await this.$http.get('v1/deliveries')
      this.deliveries = response.data
    }
  }
}
</script>
