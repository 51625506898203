import { extend, localize, setInteractionMode } from 'vee-validate'
// eslint-disable-next-line camelcase
import { between, email, is_not, length, max, min, min_value, required } from 'vee-validate/dist/rules'
import it from 'vee-validate/dist/locale/it.json'

setInteractionMode('eager')

localize('it', it)

extend('required', required)
extend('email', email)
extend('min', min)
extend('min_value', min_value)
extend('length', length)
extend('between', between)
extend('max', max)
extend('is_not', is_not)

extend('greaterThanZero', {
  message: field => `Il campo ${field} deve essere maggiore di 0.`,
  validate: (value) => {
    return value > 0
  }
})
