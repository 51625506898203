<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg">
    <span v-if="!outlined && label" class="ml-1 text-left font-weight-bold">{{ label }}</span>
    <validation-provider
        :rules="rules"
        :name="placeholder || label"
        v-slot="{ errors }"
    >
      <slot v-bind="{ errors, label, classes }">
        <v-text-field
            v-model="text"
            :error-messages="errors"
            :label="outlined ? label : ''"
            :outlined="outlined"
            :disabled="disabled"
            :class="classes"
            :placeholder="label || placeholder"
            :type="typeof type === 'function' ? type() : type"
            :prepend-inner-icon="typeof prependInnerIcon === 'function' ? prependInnerIcon() : prependInnerIcon"
            :append-icon="typeof appendIcon === 'function' ? appendIcon() : appendIcon || (helpText ? 'fas fa-info-circle' : '')"
            :counter="counter"
            @click:append="helpText ? showHelpMessage() : $emit('click:append')"
            @change="(newVal) => $emit('change', newVal)"

            :style="{maxWidth}"
        />
      </slot>
    </validation-provider>
  </v-col>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    value: {},
    label: String,
    rules: [String, Object],
    placeholder: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    lessMargin: {
      type: Boolean,
      default: true
    },
    prependInnerIcon: {
      type: [String, Function],
      default: ''
    },
    appendIcon: {
      type: [String, Function],
      default: ''
    },
    type: {
      type: [String, Function],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cols: String,
    sm: String,
    md: String,
    lg: String,
    helpText: String,
    counter: {
      String,
      default: () => null
    },
    maxWidth: String
  },
  data () {
    return {
      classes: {
        'mb-n3': !this.lessMargin,
        'mb-n5': this.lessMargin,
        'mt-1': !this.outlined
      }
    }
  },
  computed: {
    text: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    showHelpMessage () {
      this.$dialog.info({
        title: 'Info ' + this.label || this.placeholder,
        text: this.helpText
      })
    }
  }
}
</script>

<style scoped>

</style>
