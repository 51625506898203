<template>
  <action-dialog
    v-model="fieldValue"
    :data="zone"
    :edit-mode="editMode"

    submit-title="Crea una zona"
    edit-title="Modifica zona"
    submit-success-message="Zona creata con successo!"
    edit-success-message="Zona modificata con successo!"

    :fields="fields"
    :field-default-options="fieldDefaultOptions"

    :submit-promise-callback="createCallback"
    :edit-promise-callback="editCallback"
    @close="close"
  />
</template>

<script>
import ActionDialog from '@/components/ActionDialog/ActionDialog'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'

export default {
  name: 'ZoneDialog',
  components: { ActionDialog },
  props: {
    value: {
      type: Boolean
    },
    zone: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12
      },
      fields: [
        { value: 'name', label: 'Nome', rules: 'required' }
      ]
    }
  },
  computed: {
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  mixins: [usersDataCardFields],
  methods: {
    createCallback () {
      return this.$http.post(
        'v1/zones',
        this.zone
      )
    },
    editCallback () {
      return this.$http.put('v1/zones/' + this.zone.id, this.zone)
    },
    close () {
      this.$emit('close')
      this.referenteChanged = false
    }
  }
}
</script>
