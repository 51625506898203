<template>
  <div class="ma-4">
    <h1 class="text-center text-h3">
      {{
        isBusiness && !isIndividualCompany
            ? 'Dati del legale rappresentante'
            : 'Completa con i tuoi dati'
      }}
    </h1>

    <v-container v-if="!isBusiness">
      <div style="display: flex; flex-direction: row" class="mt-8">
        <v-icon @click="backToIndividualCompanySelection">fa-arrow-left</v-icon>
        <span class="ml-3 text-h6 font-weight-regular">Indietro</span>
      </div>
    </v-container>

    <validation-observer ref="form">
      <form-card
        v-if="!userRegistrationComplete"
        title="Chi sono"
        :data="userData"
        :fields="whoAmIFields"
        class="mt-8"
      >
      </form-card>

      <form-card
        v-if="!memberRegistrationComplete && isMember"
        title="Informazioni personali"
        :data="memberData"
        :fields="personalInfoFields"
      >
        <template v-slot:field-birthDate="{ errors, value, classes }">
          <date-picker
            :errors="errors"
            :classes="classes"
            v-model="memberData[value]"
            min="1930-01-01"
            :max="new Date().toISOString().substr(0, 10)"
            year-first
            label="Data di nascita"
          />
        </template>
      </form-card>

      <form-card
        v-if="isMember"
        title="Dati fiscali"
        :data="memberData"
        :fields="fiscalDataFields"
      >
        <template v-slot:field-documentExpirationDate="{ errors, value, classes }">
          <date-picker
            :errors="errors"
            :classes="classes"
            v-model="memberData[value]"
            :min="documentDate.min"
            :max="documentDate.max"
            year-first
            label="Data di scadenza del documento"
          />
        </template>
      </form-card>

      <div class="w-100 x-margin mx-auto text-right px-sm-2 mt-6 mb-12">
        <v-btn color="success" @click="submit">Continua</v-btn>
      </div>
    </validation-observer>
  </div>
</template>

<style scoped>
.x-margin {
  max-width: 55em;
}
</style>

<script>
import FormCard from '@/components/Registration/FormCard'
import DatePicker from '@/components/Field/DatePicker'
import { mapGetters } from 'vuex'

export default {
  name: 'PersonDataRegistration',
  components: { DatePicker, FormCard },
  data () {
    const formatDate = (date) => date.toISOString().slice(0, 10)

    const minDocumentDate = new Date()
    minDocumentDate.setFullYear(minDocumentDate.getFullYear() + 11)

    return {
      documentDate: {
        max: formatDate(minDocumentDate),
        min: formatDate(new Date())
      },
      userData: {},
      memberData: {},
      birthDateMenu: false,
      whoAmIFields: [
        {
          value: 'name',
          label: 'Nome',
          rules: 'required|max:32',
          md: '4'
        },
        {
          value: 'surname',
          label: 'Cognome',
          rules: 'required|max:32',
          md: '4'
        },
        {
          value: 'phoneNumber',
          label: 'Numero di cellulare',
          rules: 'required|max:13',
          md: '4',
          helpText: 'Il numero di cellulare verrà utilizzato per le comunicazioni urgenti e di consegna della merce.'
        }
      ],
      personalInfoFields: [
        {
          value: 'birthDate',
          label: 'Data di nascita',
          rules: 'required',
          md: '4'
        },
        {
          value: 'birthCity',
          label: 'Città di nascita',
          rules: 'required|max:64',
          md: '5'
        },
        {
          value: 'birthProvince',
          label: 'Provincia di nascita',
          rules: 'required|max:64',
          md: '3'
        },
        {
          value: 'address',
          label: 'Indirizzo di residenza',
          rules: 'required|max:64',
          md: '6'
        },
        {
          value: 'city',
          label: 'Città di residenza',
          rules: 'required|max:64',
          md: '6'
        },
        {
          value: 'province',
          label: 'Provincia di residenza',
          rules: 'required|max:64',
          md: '6'
        },
        {
          value: 'cap',
          label: 'CAP di residenza',
          rules: 'required|max:6',
          md: '6'
        }
      ],
      fiscalDataFields: [
        {
          value: 'fiscalCode',
          label: 'Codice fiscale',
          rules: 'required|min:6|max:20',
          atInput: () => {
            this.memberData.fiscalCode = this.memberData.fiscalCode?.toUpperCase() || ''
          },
          md: '6'
        },
        {
          value: 'documentType',
          label: 'Tipo documento',
          placeholder: "Es.: Patente, Carta d'identità ...",
          rules: 'required|max:32',
          md: '6'
        },
        {
          value: 'documentNumber',
          label: 'Numero del documento',
          rules: 'required|min:6|max:16',
          atInput: () => {
            this.memberData.documentNumber = this.memberData.documentNumber?.toUpperCase() || ''
          },
          md: '6'
        },
        {
          value: 'documentExpirationDate',
          label: 'Scadenza del documento',
          rules: 'required',
          md: '6'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['isMember', 'userRegistrationComplete', 'memberRegistrationComplete', 'isIndividualCompany', 'isBusiness'])
  },
  methods: {
    async submit () {
      const valid = await this.$refs.form.validate()
      if (!valid) return

      if (!this.userRegistrationComplete) {
        await this.registerUser()
        this.$store.commit('userRegistered')
      }

      if (this.isMember) {
        await this.registerMember()
        this.$store.commit('memberRegistered')
      }

      await this.$router.push('finale')
    },

    async registerUser () {
      await this.$http.post('v1/users/register/user', this.userData)
    },
    async registerMember () {
      await this.$http.post('v1/users/register/member', this.memberData)
    },
    async backToIndividualCompanySelection () {
      if (this.$store.getters.isBusiness) {
        await this.$http.get('/v1/users/register/individualCompany/rollback')
        await this.$store.commit('chooseIndividualCompany', null)
      } else {
        await this.$http.get('/v1/users/register/business/rollback')
        await this.$store.commit('chooseBusiness', null)
      }

      await this.$router.push('/iscriviti/continua')
    }
  }
}
</script>
