<template>
    <v-card
        class="mx-auto grey lighten-5 clickable"
        outlined
        elevation="0"
    >
      <v-img
          class=""
          height="200px"
          :src="product.imageUrl || 'https://via.placeholder.com/4032x3024?text=Immagine+non+disponibile'"
      >
      </v-img>

      <div class="card-container">
        <div class="card-image-container">
          <div class="card-image primary">
            <i class="fas fa-carrot white--text"></i>
          </div>
        </div>
        <div class="card-text">
          <v-card-title class="justify-start world-divided">{{ product.name }}</v-card-title>
          <v-card-subtitle class="justify-start grey--text">{{ product.productType.value }}</v-card-subtitle>
          <v-card-text class="justify-start grey--text mt-0">A partire da {{ formatMoney(product.startingAt) }} </v-card-text>
        </div>
      </div>

      <v-list class="transparent">
        <v-list-item
            v-for="supplier in product.paniereProducts"
            :key="supplier.id"
            class="pl-0"
        >
          <v-list-item-title>{{ supplier.supplierName }}</v-list-item-title>

          <v-list-item-subtitle class="text-right text--darken-2">
            € {{ formatMoney(supplier.price) }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
</template>

<script>
import { stringUtils } from '@/mixins/stringUtils'

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  mixins: [stringUtils]
}
</script>

<style scoped>
.card-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 70px auto;
}

.card-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.card-image {
  border-radius: 5px;
}

.card-image i {
  padding: 15px;
}

.card-text {
  text-align: start;
}

.world-divided {
  word-break: keep-all;
}
.clickable {
  cursor: pointer;
}

</style>
