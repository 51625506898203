<template>
  <v-app id="app">
    <NavBar/>
    <v-main v-if="show">
      <registration-banner/>
      <router-view/>
    </v-main>
    <v-main v-else>
      <v-container>
        Caricamento in corso...
      </v-container>
    </v-main>
    <BottomBar/>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar/NavBar'
import RegistrationBanner from '@/components/NavBar/RegistrationBanner'
import BottomBar from '@/components/BottomBar/BottomBar'

export default {
  name: 'App',
  components: {
    BottomBar,
    RegistrationBanner,
    NavBar
  },
  data () {
    return {
      show: false
    }
  },
  async beforeMount () {
    if (this.$store.getters.isLogged) {
      await this.$store.dispatch('renewToken')
    }
    this.show = true
  }
}
</script>

<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
</style>

<style src="./app.scss" lang="scss"/>
