<template>
  <v-dialog
      v-model="dialog"
      v-if="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
      @close="close"
  >
      <v-card tile>
        <v-toolbar flat color="primary">
          <v-btn icon class="white--text" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">Dettagli dell'ordine di {{ name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <DataCard
              title=""
              :headers="headers"
              :items="value.paniereProducts"
              :search-label="`Cerca un prodotto nell'ordine di ${name}`"
          >
            <template v-slot:item.weight="{ value }">
              <span v-if="value">
                {{ value }}
                <small>kg</small>
              </span>
            </template>
            <template v-slot:item.price="{ value, item }">
              {{ formatMoney(value) }}
              <small>/{{ item.measureUnitCode }}</small>
            </template>
            <template v-slot:item.totalPrice="{ value }">
              {{ formatMoney(value) }}
            </template>
          </DataCard>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'
import { stringUtils } from '@/mixins/stringUtils'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'

export default {
  name: 'PaniereOrderDetailsDialog',
  components: {
    DataCard
  },
  props: {
    value: Object
  },
  data () {
    return {
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Peso', value: 'weight' },
        // { text: 'Quantità minima', value: 'minQuantity' },
        // { text: 'Moltiplicatore', value: 'multiplier' },
        { text: 'Prezzo', value: 'price' },
        { text: 'Tipo', value: 'orderMode' },
        { text: 'Quantità oirdinata', value: 'purchased' },
        { text: 'Subtotale', value: 'totalPrice' },
        { text: 'Fornitore', value: 'supplierBusinessName' }
      ]
    }
  },
  computed: {
    dialog: {
      get () {
        return !!this.value
      },
      set (newVal) {
        if (!newVal) this.$emit('input', null)
      }
    },
    name () {
      return usersDataCardFields.methods.nameField(this.value.user)
    }
  },
  mixins: [stringUtils, usersDataCardFields],
  methods: {
    close () {
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>

</style>
