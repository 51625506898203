<template src="./ActionDialog.html"/>
<!--<style scoped lang="scss" src="./ActionDialog.scss" />-->

<script>
import { setInteractionMode, ValidationObserver } from 'vee-validate'
import LoadingDialog from './implementations/LoadingDialog.vue'

// TODO: Remove? (vee-validate set interaction mode)
setInteractionMode('eager')

// export interface FieldOption {
//   value: string;
//   label: string;
//   roles: string;
//   cols?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
//   sm?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
//   md?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
//   lg?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
//   prependInnerIcon?: string;
//   type?: string;
// }
//
// export interface FieldDefaultOptions {
//   lessMargin?: boolean;
//   cols?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
//   sm?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
//   md?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
//   lg?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
// }

export default {
  name: 'ActionDialog',
  components: {
    ValidationObserver,
    LoadingDialog
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    editMode: {
      type: Boolean,
      default: () => false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fields: {
      type: Array,
      default: () => []
    },
    fieldDefaultOptions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    submitPromiseCallback: {
      type: Function,
      required: true
    },
    editPromiseCallback: {
      type: Function
    },
    submitSuccessMessage: {
      type: String,
      required: true
    },
    editSuccessMessage: {
      type: String
    },
    submitTitle: {
      type: String,
      required: true
    },
    submitBtnText: {
      type: String,
      default: () => 'Conferma'
    },
    cancelBtnText: {
      type: String,
      default: () => 'Annulla'
    },
    loadingText: {
      type: String,
      default: () => 'Caricamento in corso...'
    },
    editTitle: String,
    editBtnText: {
      String,
      default: () => 'Modifica'
    },
    dialogMaxWidth: {
      String,
      default: () => '600px'
    }
  },
  data () {
    return {
      loading: false,
      originalData: null
    }
  },
  computed: {
    createMode () {
      return !this.editMode
    },
    isNotChanged () {
      if (this.originalData === null) {
        return false
      }

      return this.checkDifferences(this.data, this.originalData)
    },
    modalVisible: {
      get () {
        return !!this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    value (newValue) {
      if (this.editMode && newValue) {
        this.originalData = Object.assign({}, this.data)
      } else {
        this.originalData = null
      }
    }
  },
  methods: {
    checkDifferences (obj1, obj2) {
      if (!obj1 && !obj2) {
        return true
      }
      if (!obj1 || !obj2) { // If one or another is empty (not both because of the previous condition)
        return false
      }

      for (const key of Object.keys(obj1)) {
        if (typeof obj1[key] !== typeof obj2[key]) {
          return false
        }
        if (typeof obj1[key] === 'object') {
          return this.checkDifferences(obj1[key], obj2[key])
        }
        if (obj1[key] !== obj2[key]) {
          return false
        }
      }
      return true
    },
    async create () {
      const valid = await this.$refs.dataObserver.validate()
      if (!valid) return

      this.loading = true
      try {
        await this.submitPromiseCallback()
        this.close()
        await this.$dialog.notify.success(this.submitSuccessMessage)
      } catch (e) {
        this.loading = false
      }
    },
    async edit () {
      const valid = await this.$refs.dataObserver.validate()
      if (!valid) return

      this.loading = true
      try {
        await this.editPromiseCallback()
        this.close()
        await this.$dialog.notify.success(this.editSuccessMessage)
      } catch (e) {
        this.loading = false
      }
    },
    close () {
      this.modalVisible = false
      this.$emit('close')
      this.loading = false
      if (this.data) {
        Object.keys(this.data).forEach(k => {
          this.data[k] = null
        })
      }
      this.$refs.dataObserver.reset()
    }
  }
}
</script>
