<template>
  <v-dialog
    v-model="show"
    hide-overlay
    persistent
    width="400"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        {{ text }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoadingDialog',
  props: {
    text: {
      type: String,
      default: 'Caricamento in corso'
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
