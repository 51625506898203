<template>
  <action-dialog
      v-model="fieldValue"
      :data="supplier"
      :edit-mode="editMode"

      submit-title="Crea una fornitore"
      edit-title="Modifica un fornitore"
      submit-success-message="Fornitore creato con successo!"
      edit-success-message="Fornitore modificato con successo!"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createCallback"
      :edit-promise-callback="editCallback"
      @close="close"
  />
</template>

<script>
import ActionDialog from '@/components/ActionDialog/ActionDialog'

export default {
  name: 'SupplierDialog',
  components: { ActionDialog },
  props: {
    value: Boolean,
    supplier: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true
      },
      fields: [
        { value: 'businessName', label: 'Nome', rules: 'required', cols: '12', sm: '6' },
        { value: 'email', label: 'Email (per invio ordini)', rules: 'required', cols: '12', sm: '6' },
        { value: 'codiceUnivoco', label: 'Codice univoco', rules: 'required', cols: '12', sm: '6', counter: '7' },
        { value: 'partitaIva', label: 'Partita IVA', rules: 'required', cols: '12', sm: '6', counter: '11' },
        { value: 'fiscalCode', label: 'Codice fiscale', rules: 'required', cols: '12', sm: '6', counter: '16' },
        { value: 'address', label: 'Indirizzo', rules: 'required', cols: '12', sm: '6' },
        { value: 'city', label: 'Città', rules: 'required', cols: '12', sm: '6' },
        { value: 'cap', label: 'CAP', rules: 'required', cols: '12', sm: '3' },
        { value: 'province', label: 'Provincia', rules: 'required', cols: '12', sm: '3' }
      ]
    }
  },
  computed: {
    fieldValue: {
      get () {
        return !!this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    createCallback () {
      return this.$http.post(
        'v1/suppliers',
        this.supplier
      )
    },
    editCallback () {
      return this.$http.put(
        'v1/suppliers/' + this.supplier.id,
        this.supplier
      )
    },
    close () {
      this.fieldValue = false
      this.$emit('close')
    }
  }
}
</script>
