import Vue from 'vue'
import Vuex from 'vuex'
import account from '@/store/modules/account'
import createPersistedState from 'vuex-persistedstate'
import distribution from '@/store/modules/distribution'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['account']
    })
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    distribution
  }
})
