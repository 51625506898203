import store from '@/store'
import Zone from '@/views/Admin/Zone/Zone'
import AdminApp from '@/views/Admin/AdminApp'
import Prodotti from '@/views/Admin/Prodotti/Prodotti'
import NewMembers from '@/views/Admin/Users/NewMembers'
import Suppliers from '@/views/Admin/Suppliers/Suppliers'
import CreateProduct from '@/views/Admin/CreateProduct/CreateProduct'
import EditProduct from '@/views/Admin/EditProduct/EditProduct'
import CreatePaniere from '@/views/Admin/CreatePaniere/CreatePaniere'
import AllRootCategories from '@/views/Admin/AllCategoryProduct/AllRootCategories'
import AllSubCategories from '@/views/Admin/AllCategoryProduct/AllSubCategories'
import DistZone from '@/views/Admin/Distribuzione/DistZone/DistZone'
import DistStartNew from '@/views/Admin/Distribuzione/DistStartNew/DistStartNew'
import DistListaUtenti from '@/views/Admin/Distribuzione/DistListaUtenti/DistListaUtenti'
import DistSelectPostazione from '@/views/Admin/Distribuzione/DistSelectPostazione/DistSelectPostazione'
import AdminTest from '@/views/Admin/AdminTest/AdminTest'
import ProductDetails from '@/views/ProductDetails/ProductDetails'
import ActualPanieri from '@/views/Admin/Panieri/ActualPanieri'
import PanieriHistory from '@/views/Admin/Panieri/PanieriHistory'
import Dashboard from '@/views/Admin/Dashboard/Dashboard'
import UnpaidPayments from '@/views/Admin/Payments/UnpaidPayments'
import Users from '@/views/Admin/Users/Users.vue'

function beforeEnter (to, from, next) {
  if (!store.getters.isLogged) {
    redirect(next, '/login')
  } else if (store.getters.isAdmin) {
    next()
  } else {
    redirect(next, '/')
  }
}

function redirect (next, path) {
  next({
    path: path,
    replace: true
  })
}

export default {
  path: '/amministrazione',
  component: AdminApp,
  beforeEnter,
  children: [
    {
      path: '',
      component: Dashboard
    },
    {
      path: 'zone',
      component: Zone
    },
    {
      path: 'prodotti',
      component: Prodotti
    },
    {
      path: 'prodotti/crea',
      component: CreateProduct
    },
    {
      path: 'prodotti/:productId',
      component: ProductDetails,
      meta: {
        adminPage: true,
        edited: false
      }
    },
    {
      path: 'editproduct/:productId',
      component: EditProduct
    },
    {
      path: 'tuttecategorieprodotti',
      component: AllRootCategories
    },
    {
      path: 'tuttesottocategorieprodotti/:categoryId',
      component: AllSubCategories
    },
    {
      path: 'utenti',
      component: Users
    },
    {
      path: 'nuovi-soci',
      component: NewMembers
    },
    {
      path: 'fornitori',
      component: Suppliers
    },
    {
      path: 'panieri/attuali',
      component: ActualPanieri
    },
    {
      path: 'panieri/storico',
      component: PanieriHistory
    },
    {
      path: 'panieri/crea',
      component: CreatePaniere
    },
    {
      path: 'panieri/crea/:paniereId',
      component: CreatePaniere,
      meta: {
        editMode: false
      }
    },
    {
      path: 'panieri/modifica/:paniereId',
      component: CreatePaniere,
      meta: {
        editMode: true
      }
    },
    {
      path: 'distribuzione/crea-nuova',
      component: DistStartNew
    },
    {
      path: 'distribuzione/seleziona-postazione',
      component: DistSelectPostazione
    },
    {
      path: 'distribuzione/zone',
      component: DistZone
    },
    {
      path: 'distribuzione/utenti/:zoneId',
      component: DistListaUtenti
    },
    {
      path: 'pagamenti/non-pagati',
      component: UnpaidPayments
    },
    {
      path: 'testing',
      component: AdminTest
    },
    {
      path: '*',
      redirectTo: 'zone'
    }
  ]
}
