import { render, staticRenderFns } from "./BusinessRegistration.vue?vue&type=template&id=174f1514&scoped=true"
import script from "./BusinessRegistration.vue?vue&type=script&lang=js"
export * from "./BusinessRegistration.vue?vue&type=script&lang=js"
import style0 from "./BusinessRegistration.vue?vue&type=style&index=0&id=174f1514&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "174f1514",
  null
  
)

export default component.exports