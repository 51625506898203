<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      @close="close"
  >
    <v-card tile>
      <v-toolbar flat color="primary">
        <v-btn icon class="white--text" @click="close">
          <v-icon>fa-times</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">Informazioni utente: {{ value && nameField(value) }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="dialog" class="my-4">
        <v-card max-width="60rem" class="mx-auto my-8" outlined v-if="['ales.zanola@gmail.com', 'matteonatale@startmail.com'].includes($store.getters.email) && $store.getters.isAdmin">
          <v-card-title>
            Visibile solo ad Ale e Matt
          </v-card-title>
          <v-card-text>
            <v-btn color="primary" outlined @click="loginAsUser">Accedi come se fossi {{value.name}} {{value.surname}}</v-btn>
          </v-card-text>
        </v-card>
        <v-card max-width="60rem" class="mx-auto my-8" outlined v-for="(card, index) in data" v-bind:key="index">
          <v-card-title>
            {{ card.title }}
          </v-card-title>
          <v-card-text>
            <v-row v-for="(row, index) in card.information" v-bind:key="index">
              <v-col cols="5" md="4" class="pr-8 pl-5 text-right text-overline">{{ row.name }}</v-col>
              <v-col cols="7" md="8" class="align-self-center">
                {{ row.value }}
                <v-icon :color="row.showBoolean ? 'green' : 'warning'"
                        v-if="row.showBoolean !== undefined" class="ml-2">
                  {{ row.showBoolean ? 'fa-check' : 'fa-circle-exclamation' }}
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import Vue from 'vue'

export default {
  name: 'UserDetailsDialog',
  props: {
    value: Object
  },
  mixins: [usersDataCardFields],
  computed: {
    dialog: {
      get () {
        return !!this.value
      },
      set (newVal) {
        if (!newVal) this.value = null
      }
    },
    data () {
      return [
        {
          title: 'Informazioni di base',
          information: [
            {
              show: true,
              name: 'Tipo di account',
              value: (this.value.isMember ? 'Socio' : 'Non socio') + ' - ' +
                  (!this.value.isBusiness ? 'Privato' : this.value.isIndividualCompany ? 'Ditta individuale' : 'Società')
            },
            {
              show: true,
              name: 'Email',
              value: this.value.email
            },
            {
              show: true,
              name: this.value.isBusiness && !this.value.isIndividualCompany ? 'Ragione sociale' : 'Cognome e nome',
              value: this.nameField(this.value)
            },
            {
              show: this.value.isBusiness,
              name: !this.value.isIndividualCompany ? 'Amministratore' : 'Intestazione ditta individuale',
              value: this.businessField(this.value)
            },
            {
              show: true,
              name: 'Cellulare',
              value: this.value.phoneNumber
            },
            {
              show: true,
              name: 'Data di registrazione',
              value: this.formatDate(this.value.startRegistrationDate)
            },
            {
              show: this.value.memberConfirmationDate,
              name: 'Data di accettazione socio',
              value: this.formatDate(this.value.memberConfirmationDate)
            },
            {
              show: true,
              name: 'Pagamento quota associativa',
              value: this.value.memberLastYearPaid ? this.value.memberLastYearPaid : 'Quota di iscrizione non ancora pagata',
              showBoolean: this.value.memberLastYearPaid >= new Date().getFullYear()
            }
          ].filter(({ show }) => show)
        },

        this.zoneInformation,
        ...(
          !this.value.isMember ? [] : !this.value.isBusiness ? [this.memberInformation]
            : this.value.isIndividualCompany ? [
              this.memberInformation, this.businessInformation
            ] : [
              this.businessInformation, this.memberInformation
            ]
        )
      ]
    },
    zoneInformation () {
      return {
        title: 'Zone',
        information: [
          {
            show: this.value.deliveryZone,
            name: 'Zona di appartenenza',
            value: this.value.deliveryZone?.name
          },
          {
            show: true,
            name: 'Disponibilità punto di ritiro',
            value: this.value.canBePickUpPoint ? 'Si!' : 'No'
          }
        ].filter(({ show }) => show)
      }
    },
    businessInformation () {
      return {
        title: 'Dati ' + (this.value.isIndividualCompany ? 'ditta individuale' : 'azienda'),
        information: [
          {
            show: true,
            name: this.value.isIndividualCompany ? 'Intestazione' : 'Ragione sociale',
            value: this.value.business.businessName
          },
          {
            show: true,
            name: 'Partita IVA',
            value: this.value.business.partitaIva
          },
          {
            show: true,
            name: 'Codice fiscale',
            value: this.value.business.fiscalCode
          },
          {
            show: this.value.business.codiceUnivoco,
            name: 'Codice univoco',
            value: this.value.business.codiceUnivoco
          },
          {
            show: this.value.business.pec,
            name: 'PEC',
            value: this.value.business.pec
          },
          {
            show: true,
            name: 'Sede legale',
            value: `${this.value.business.address}, ${this.value.business.city} (${this.value.business.province}) - ${this.value.business.cap}`
          }
        ].filter(({ show }) => show)
      }
    },
    memberInformation () {
      return {
        title: 'Dati ' + (!this.value.isBusiness ? 'personali' : this.value.isIndividualCompany ? 'titolare ditta individuale' : 'legale rappresentante'),
        information: [
          {
            show: true,
            name: 'Cognome e nome',
            value: `${this.value.surname} ${this.value.name}`
          },
          {
            show: true,
            name: 'Codice fiscale',
            value: this.value.member.fiscalCode
          },
          {
            show: true,
            name: 'Data di nascita',
            value: this.formatDate(this.value.member.birthDate)
          },
          {
            show: true,
            name: 'Luogo di nascita',
            value: `${this.value.member.birthCity} (${this.value.member.birthProvince})`
          },
          {
            show: true,
            name: 'Residenza',
            value: `${this.value.member.address}, ${this.value.member.city} (${this.value.member.province}) - ${this.value.member.cap}`
          },
          {
            show: true,
            name: 'Tipo documento',
            value: this.value.member.documentType
          },
          {
            show: true,
            name: 'Numero documento e data di scadenza',
            value: `${this.value.member.documentNumber}, ${this.formatDate(this.value.member.documentExpirationDate)}`
          }
        ].filter(({ show }) => show)
      }
    }
  },
  methods: {
    close () {
      this.$emit('input', null)
    },
    formatDate (dateNumber) {
      // const options = {
      //   weekday: 'long',
      //   year: 'numeric',
      //   month: 'long',
      //   day: 'numeric'
      // }
      return new Date(dateNumber).toLocaleDateString('it')
    },
    async loginAsUser () {
      const res = await Vue.prototype.$http.post('/v1/authentication/login/' + this.value.id)
      this.$store.commit('loginUser', res.data)
      this.$router.push('/').then()
    }
  }
}
</script>

<style scoped>

</style>
