<template>
  <v-card outlined class="mx-5 mb-6 cursor-pointer" @click="openDelivery">
    <div class="ma-5">
      <v-row class="align-center">
        <v-col>
          <span class="text-caption">Consegna del </span>
          <span class="font-weight-bold">{{ date }}</span>
        </v-col>
        <!--        <v-col v-if="delivery.panieriCount > 1">-->
        <!--          {{delivery.panieriCount}}-->
        <!--          <span class="text-caption">ordini consegnati</span>-->
        <!--        </v-col>-->
        <v-col class="">
          <div class="ml-auto"
               :class="!delivery.paid ? 'd-flex justify-space-between' : 'text-end'"
               :style="!delivery.paid ? 'min-width: 18rem; max-width: 19rem' : ''">
            <v-chip v-if="!delivery.paid">
              In attesa di pagamento
            </v-chip>
            <span class="mr-3 text-h5 ml-1">{{ total }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'DeliveryListItem',
  props: {
    delivery: {
      type: Object,
      required: true
    }
  },
  computed: {
    date () {
      return this.formatDate(this.delivery.date)
    },
    total () {
      return this.formatMoney(this.delivery.total)
    }
  },
  methods: {
    openDelivery () {
      this.$router.push('/consegne/' + this.delivery.distSessionId)
    }
  }
}
</script>
