<template>
  <div>
    <DataCard
        :headers="headers"
        :items="items"
        no-data-text="Non ci sono utenti registrati"
        search-label="Cerca utente"
        title="Utenti"
        row-pointer
        sort-by="name"
        @click:row="rowClick"
    >
      <template v-slot:card-top>
        <v-switch v-model="onlyMembers" label="Mostra solo soci"/>
      </template>

      <template v-slot:item.endRegistrationDate="{ item }">
        {{ formatDate(item.endRegistrationDate) }}
      </template>
      <template v-slot:item.isMember="{ value, item }">
        <v-icon v-if="value" :color="getMembershipPaid(item) ? 'green' : 'warning'">
          {{ getMembershipPaid(item) ? 'fa-check' : 'fa-circle-exclamation' }}
        </v-icon>
        {{ value && !getMembershipPaid(item) ? '(si ma non ancora pagato quest\'anno)' : '' }}
      </template>
    </DataCard>

    <user-details-dialog v-model="userToShow" />
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import UserDetailsDialog from '@/components/DetailsDialog/UserDetailsDialog'

export default {
  name: 'Users',
  components: {
    UserDetailsDialog,
    DataCard
  },
  mixins: [usersDataCardFields],
  data () {
    return {
      users: [],
      onlyMembers: false,
      headers: [
        { text: 'Cognome e nome o ragione sociale', value: 'name' },
        { text: 'Intestazione ditta individuale o amministratore', value: 'business' },
        { text: 'Registrato il', value: 'endRegistrationDate' },
        { text: 'Email', value: 'email' },
        { text: 'Cellulare', value: 'phoneNumber' },
        { text: 'Socio', value: 'isMember' }
      ],
      userToShow: null
    }
  },
  computed: {
    items () {
      if (this.onlyMembers)
        return this.users.filter(item => item.isMember)

      return this.users
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    updateUsers (users) {
      users.forEach(m => {
        m.name = this.nameField(m)
        m.business = this.businessField(m)
      })

      this.users = users
    },
    async init () {
      try {
        const response = await this.$http.get('v1/users')
        this.updateUsers(response.data)
      } catch (e) {}
    },
    getMembershipPaid (item) {
      return item.memberLastYearPaid >= new Date().getFullYear()
    },
    async rowClick (item) {
      this.userToShow = item
      try {
        const response = await this.$http.get('v1/users/' + item.id)
        this.userToShow = response.data
      } catch (e) {}
    }
  }
}
</script>

<style scoped>

</style>
