<template>
  <div class="mx-10">
    <h1 class="text-center display-2 font-weight mt-10">Seleziona la postazione</h1>

    <v-container class="text-center mt-10" v-if="session">
      <v-btn-toggle tile borderless>
        <v-btn :disabled="session.bilanciaCompleted" @click="selectPost(bilancia)">{{bilancia.value}}</v-btn>
        <v-btn :disabled="session.banconeCompleted" @click="selectPost(bancone)">{{bancone.value}}</v-btn>
      </v-btn-toggle>
      <h4 class="mt-5" v-if="session.bilanciaCompleted || session.banconeCompleted">
        La postazione {{ session.bilanciaCompleted ? 'BANCONE' : 'BILANCIA' }} non è ancora stata completata
      </h4>

      <users-with-failed-payment/>
    </v-container>
  </div>
</template>

<script>

import UsersWithFailedPayment from '@/views/Admin/Distribuzione/DistSelectPostazione/UsersWithFailedPayment'
export default {
  name: 'DistSelectPostazione',
  components: { UsersWithFailedPayment },
  data () {
    return {
      session: null,
      bancone: { code: 'BANCONE', value: 'Bancone' },
      bilancia: { code: 'BILANCIA', value: 'Bilancia' }
      // posts: []
    }
  },
  computed: {
  },
  watch: {
  },
  async mounted () {
    const res = await this.$http.get('v1/distribution/session')
    if (res.data) {
      this.session = res.data
    } else {
      await this.$router.push('/amministrazione/distribuzione/crea-nuova')
    }
    // await this.getPosts()
  },
  methods: {
    // async getPosts () {
    //   try {
    //     const response = await this.$http.get('v1/products/posts')
    //     this.posts = response.data
    //   } catch (e) {
    //   }
    // },
    selectPost (post) {
      const parsed = JSON.stringify(post)
      localStorage.setItem('post', parsed)
      this.$router.push('/amministrazione/distribuzione/zone')
    }
  }
}
</script>

<style>
</style>
