<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <span class="ml-1 text-left font-weight-bold">Immagine</span>
        <v-img class="white--text my-2 rounded" :aspect-ratio="1.33" :src="imageUrl"></v-img>
        <template>
          <v-tabs
              v-model="tab">
            <v-tab>Da file</v-tab>
            <v-tab>Da URL</v-tab>
          </v-tabs>
        </template>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="mt-2"/>
            <v-file-input
                label="Immagine"
                @change="previewImage"
                @click:clear="clearImage"
                v-model="value.image"
                outlined
                prepend-icon="mdi-camera"
                class="my-3"
            ></v-file-input>
          </v-tab-item>
          <v-tab-item>
            <text-field
                v-model="value.product.imageUrl"
                :rules="value.imageUrl ? '' : 'required'"
                less-margin
                placeholder="Url"
                cols="12"
                md="12"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <v-col cols="12" md="8" lg="8">
        <v-row>
          <text-field
              v-model="value.product.name"
              rules="required"
              label="Nome"
              placeholder="Nome"
              less-margin
              cols="12"
          />

          <text-field
              v-model="value.product.description"
              label="Descrizione"
              less-margin
              cols="12"
          >
            <template #default="{classes, label, errors}">
              <v-textarea
                  v-model="value.product.description"
                  outlined
                  :placeholder="label"
                  :class="classes"
                  :error-messages="errors"
                  auto-grow
              ></v-textarea>
            </template>
          </text-field>

          <text-field v-model="value.product.categoryId"
                      label="Categoria"
                      rules="required"
                      cols="12"
                      md="6"
                      lg="8"
          >
            <template #default="{classes, label, errors}">
              <div class="d-flex">
                <v-text-field :value="value.categoryPath"
                              disabled
                              outlined
                              :placeholder="label"
                              :class="classes"
                              :error-messages="errors"
                />
                <v-btn class="mt-2 ml-2" @click.stop="showSelectCategory=true">
                  Seleziona
                </v-btn>
              </div>
            </template>
          </text-field>

          <text-field v-model="value.product.ivaCode"
                      label="IVA"
                      rules="required"
                      cols="12"
                      md="6"
                      lg="4"
          >
            <template #default="{classes, label, errors}">
              <v-select
                  v-model="value.product.ivaCode"
                  :items="ivas"
                  item-value="code"
                  :item-text="(iva) => iva.code + '%'"
                  :label="label"
                  :error-messages="errors"
                  outlined
                  :class="classes"
                  class="my-1"
              />
            </template>
          </text-field>

          <text-field v-model="value.product.orderModeCode"
                      label="Modalità d'ordine"
                      rules="required"
                      cols="12"
                      md="6"
                      lg="4"
          >
            <template #default="{classes, label, errors}">
              <v-select
                  v-model="value.product.orderModeCode"
                  :items="orderModes"
                  item-value="code"
                  item-text="value"
                  :label="label"
                  :error-messages="errors"
                  outlined
                  :class="classes"
                  class="my-1"
              />
            </template>
          </text-field>

<!--          <text-field v-model="value.product.measureUnitCode"-->
<!--                      label="Unità di misura"-->
<!--                      rules="required"-->
<!--                      cols="12"-->
<!--                      md="6"-->
<!--                      lg="4"-->
<!--          >-->
<!--            <template #default="{classes, label, errors}">-->
<!--              <v-select-->
<!--                  v-model="value.product.measureUnitCode"-->
<!--                  :items="allowedMeasureUnits"-->
<!--                  no-data-text="Seleziona prima una modalità d'ordine"-->
<!--                  item-value="code"-->
<!--                  item-text="value"-->
<!--                  :label="label"-->
<!--                  :error-messages="errors"-->
<!--                  outlined-->
<!--                  :class="classes"-->
<!--                  class="my-1"-->
<!--              />-->
<!--            </template>-->
<!--          </text-field>-->

          <text-field v-model="value.product.productTypeCode"
                      label="Tipo di prodotto"
                      rules="required"
                      cols="12"
                      md="6"
                      lg="4"
          >
            <template #default="{classes, label, errors}">
              <v-select
                  v-model="value.product.productTypeCode"
                  :items="productTypes"
                  item-value="code"
                  item-text="value"
                  :label="label"
                  :error-messages="errors"
                  outlined
                  :class="classes"
                  class="my-1"
              />
            </template>
          </text-field>

          <text-field v-model="value.product.weightLevelCode"
                      label="Livello di fragilità"
                      rules="required"
                      cols="12"
                      md="6"
                      lg="4"
          >
            <template #default="{classes, label, errors}">
              <v-select
                  v-model="value.product.weightLevelCode"
                  :items="weightLevels"
                  item-value="code"
                  item-text="value"
                  :label="label"
                  :error-messages="errors"
                  outlined
                  :class="classes"
                  class="my-1"
              />
            </template>
          </text-field>

          <text-field
              v-model="value.product.multiplier"
              label="Moltiplicatore d'ordine"
              placeholder="Moltiplicatore d'ordine"
              :disabled="value.product.orderModeCode !== 'weight-weight'"
              @change="onMultiplierChange"
              type="number"
              rules="required|greaterThanZero"
              cols="12"
              md="6"
              lg="4"
          />

          <text-field
              v-model.number="value.product.minQuantity"
              @change="verifyMinQuantity"
              label="Quantità di ordine minima"
              placeholder="Quantità di ordine minima"
              type="number"
              rules="required|greaterThanZero"
              cols="12"
              md="6"
              lg="4"
          />

          <text-field v-model="value.product.post"
                      label="Postazione"
                      rules="required"
                      cols="12"
                      md="6"
                      lg="4"
          >
            <template #default="{classes, label, errors}">
              <v-select
                  v-model="value.product.post"
                  :items="posts"
                  item-value="code"
                  item-text="value"
                  :label="label"
                  :error-messages="errors"
                  outlined
                  :class="classes"
              />
            </template>
          </text-field>

        </v-row>
      </v-col>
    </v-row>

    <SelectCategory :showCategory="showSelectCategory" @categorySelected="categorySelected" v-model="value.product.categoryId"/>
  </div>
</template>

<script>
import TextField from '@/components/Field/TextField'
import SelectCategory from '@/components/ProductForm/SelectCategory'

export default {
  name: 'ProductForm',
  components: {
    SelectCategory,
    TextField
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageUrl () {
      return this.tab === 0
        ? (this.value.imageUrl ? this.value.imageUrl : '')
        : (this.value.product.imageUrl ? this.value.product.imageUrl : this.value.imageUrl)
    }
  },
  data () {
    return {
      ivas: [],
      // measureUnits: [],
      orderModes: [],
      productTypes: [],
      weightLevels: [],
      posts: [],
      showSelectCategory: false,
      parentCategory: null,
      tab: null
    }
  },
  mounted () {
    this.getData()
    if (!this.value.product.imageUrl) this.clearImage()
  },
  watch: {
    tab () {
      this.value.image = null
      this.clearImage()
      this.value.product.imageUrl = null
    },
    'value.product.orderModeCode' () {
      // watch is used instead of change event because the event is not triggered when the product must be edited
      // (but order mode code must be changed)
      this.value.product.multiplier = 1
      this.value.product.measureUnitCode = this.value.product.orderModeCode === 'weight-weight' ? 'kg' : 'pz'
    }
  },
  methods: {
    async categorySelected () {
      this.showSelectCategory = false
      if (this.value.product.categoryId) {
        await this.getParentCategory()
        this.value.categoryPath = null
        await this.pushPathCategory(this.parentCategory)
      }
    },
    getData () {
      this.getIvas()
      // this.getMeasureUnits()
      this.getOrderModes()
      this.getProductTypes()
      this.getWeightLevels()
      this.getPosts()
    },
    async getIvas () {
      try {
        const res = await this.$http.get('/v1/products/iva')
        this.ivas = res.data
      } catch (e) {
      }
    },
    async getMeasureUnits () {
      try {
        const res = await this.$http.get('/v1/products/measureUnits')
        this.measureUnits = res.data
      } catch (e) {
      }
    },
    onMultiplierChange () {
      if (!this.value.product.minQuantity) {
        this.value.product.minQuantity = this.value.product.multiplier
      }
    },
    verifyMinQuantity () {
      const { minQuantity, multiplier } = this.value.product

      if (multiplier) {
        let newVal = Math.round(minQuantity / multiplier) * multiplier
        if (newVal < multiplier) {
          newVal = multiplier
        }

        this.value.product.minQuantity = newVal
      }
    },
    async getOrderModes () {
      this.$http.get('/v1/products/orderModes')
        .then((res) => {
          this.orderModes = res.data
        }).catch()
    },
    async getProductTypes () {
      this.$http.get('/v1/products/productTypes')
        .then((res) => {
          this.productTypes = res.data
        }).catch()
    },
    async getWeightLevels () {
      this.$http.get('/v1/products/weightLevels')
        .then((res) => {
          this.weightLevels = res.data
        }).catch()
    },
    async getPosts () {
      this.$http.get('/v1/products/posts')
        .then((res) => {
          this.posts = res.data
        }).catch()
    },
    async getParentCategory () {
      try {
        const res = await this.$http.get('/v1/categories/' + this.value.product.categoryId + '/path')
        this.parentCategory = res.data
      } catch (err) {
        this.showGetDataErrorDialog(err)
      }
    },
    async pushPathCategory (parentCategory) {
      if (!parentCategory) {
        return 0
      } else {
        if (!this.value.categoryPath) {
          this.value.categoryPath = parentCategory.name
        } else {
          this.value.categoryPath = parentCategory.name.concat(' / ', this.value.categoryPath)
        }
        await this.pushPathCategory(parentCategory.parentCategory)
      }
    },
    previewImage () {
      this.value.imageUrl = URL.createObjectURL(this.value.image)
    },
    clearImage () {
      this.value.imageUrl = 'https://via.placeholder.com/4032x3024?text=Clicca+sotto+per+caricare+l\'immagine.'
    }
  }
}
</script>

<style scoped>

</style>
