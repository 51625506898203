<template>
  <div class="primary pa-6">
    <div class="d-flex justify-space-around mx-auto" style="max-width: 17rem">
      <!-- Iubenda: Privacy policy -->
      <a href="https://www.iubenda.com/privacy-policy/75270385"
         class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe pr-2" title="Privacy Policy ">
        Privacy Policy
      </a>
      <!-- Iubenda: END Privacy policy -->

      <!-- Iubenda: Termini e condizioni -->
      <a href="https://www.iubenda.com/termini-e-condizioni/75270385"
         class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Termini e Condizioni ">
        Termini e Condizioni
      </a>
      <!-- Iubenda: END Termini e condizioni -->
    </div>

    <!-- Scripts suggested by Iubenda are being inserted in index.html file -->
  </div>
</template>

<script>
export default {
  name: 'BottomBar'
}
</script>

<style scoped>

</style>
