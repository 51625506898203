<template>
  <div class="mx-10">
    <template>
      <div>
        <v-breadcrumbs
            large
            :items="breadcrumbItem"
        ></v-breadcrumbs>
      </div>
    </template>
    <h1 class="text-center display-2 font-weight mb-8">Le zone che hanno ordinato</h1>
    <v-data-table
        :headers="headers"
        :items="zones"
        item-key="id"
        class="elevation-1 row-pointer"
        @click:row="rowClick"
    >
      <template v-slot:item.pickUpPoint="{ value, item }">
<!--        {{ value.id ? nameField(value) : nameField(item.defaultPickUpPoint) + ' (non confermato)' }}-->
        {{ value.id ? formatAddress(value) : formatAddress(item.defaultPickUpPoint) + ' (non confermato)' }}
      </template>

      <template v-slot:item.bilanciaCompleted="{ value }">
        {{ value ? 'SI' : 'NO' }}
      </template>

      <template v-slot:item.banconeCompleted="{ value }">
        {{ value ? 'SI' : 'NO' }}
      </template>

    </v-data-table>

    <v-btn :disabled="!enabled"
           :loading="!enabled"
           @click="confirmDelivers"
           class="my-5 float-right"
    >Conferma tutte le consegne
    </v-btn>
  </div>
</template>

<script>

import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import { stringUtils } from '@/mixins/stringUtils'

export default {
  name: 'DistZone',
  mixins: [usersDataCardFields, stringUtils],
  data () {
    return {
      post: null,
      zones: [],
      enabled: true
    }
  },
  computed: {
    breadcrumbItem () {
      return [
        {
          text: this.post?.value ?? 'Postazione',
          disabled: false,
          to: '/amministrazione/distribuzione/seleziona-postazione'
        },
        {
          text: 'Zone',
          disabled: true,
          to: ''
        }
      ]
    },
    headers () {
      return [
        {
          text: 'Zona',
          value: 'name'
        },
        {
          text: 'Cassette preparate',
          value: this.post?.code === 'BILANCIA' ? 'bilanciaCompletedUsers' : 'banconeCompletedUsers'
        },
        {
          text: 'Punto di distribuzione',
          value: 'pickUpPoint'
        },
        {
          text: 'Completata',
          value: this.post?.code === 'BILANCIA' ? 'bilanciaCompleted' : 'banconeCompleted'
        }
      ]
    }
  },
  watch: {},
  mounted () {
    if (localStorage.getItem('post')) {
      try {
        this.post = JSON.parse(localStorage.getItem('post'))
      } catch (e) {
        localStorage.removeItem('post')
      }
    } else {
      // fixme: move router push to router guards
      this.$router.push('seleziona-postazione')
    }
    this.getZones()
  },
  methods: {
    async getZones () {
      try {
        const res = await this.$http.get('v1/distribution/zones/' + this.post.code)
        this.zones = res.data
      } catch (e) {
      }
    },
    async confirmDelivers () {
      try {
        this.enabled = false
        await this.$http.patch('v1/distribution/session/complete/' + this.post.code)
        this.$router.push('/amministrazione/distribuzione/seleziona-postazione').then()
        await this.$dialog.notify.success('Distribuzione completata con successo per la tua postazione!')
        localStorage.removeItem('post')
      } catch (e) {
        this.enabled = true
      }
    },
    async rowClick (item) {
      try {
        await this.$router.push('/amministrazione/distribuzione/utenti/' + item.zoneId)
      } catch (e) {
      }
    }
  }
}

</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
