<template>
  <v-card elevation="0" outlined class="pa-4">
    <h2>{{ order.name }}</h2>
    <v-row class="mt-2 mb-2">
      <v-col class="text-center">
        <p class="text--secondary mb-1">Ultimo giorno per ordinare</p>
        <p class="font-weight-medium text-body-1">{{ formatDate(order.expirationDate) }}</p>
      </v-col>
      <v-col class="text-center">
        <p class="text--secondary mb-1">Data di consegna</p>
        <p class="font-weight-medium text-body-1">{{ formatDate(order.deliveryDate) }}</p>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <div v-if="showRealTotal">
          <h2>Totale: {{ formatMoney(order.distUser.total) }}</h2>

          <span class="text-caption text--secondary">
            Totale inizialmente stimato: <b>{{ formatMoney(order.distUser.totalEstimated) }}</b>
          </span>
        </div>

        <v-badge v-else color="primary" dot>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <h2 v-on="on">Totale: {{ formatMoney(order.totalPrice) }}</h2>
            </template>
            <span>stimato</span>
          </v-tooltip>
        </v-badge>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="order.paniereProducts"
            @click:row="productClicked"
            class="cursor-pointer"
        >

          <template v-slot:item.price="{value, item}">
            <price-per-unit
                :price="value"
                :measure-unit="item.measureUnit"
                :show-kilos="isPieceWeight(item)"
            />
          </template>

          <template v-slot:item.totalPrice="{ value, item}">
            <v-badge v-if="isApproximate(item)" color="primary" dot>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="mr-2">~{{ formatMoney(value) }}</span>
                </template>
                <span>indicativo</span>
              </v-tooltip>
            </v-badge>
            <span v-else>{{ formatMoney(value) }}</span>
          </template>

          <template v-slot:item.notAvailable="{ value }">
            <!-- red cross -->
            <v-tooltip top v-if="value">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="warning" class="mr-2">mdi-alert-circle</v-icon>
              </template>
              <span>Articolo non disponibile</span>
            </v-tooltip>
          </template>

          <template v-slot:item.purchased="{item}">
            <div v-if="notEditable">
              {{item.purchased}}
              <small>{{item.measureUnit.code}}</small>
            </div>
            <PurchaseField
                v-else
                v-model="item.purchased"
                rules="min_value:0"
                label="Quantità"
                :id="item.id"
                :minQuantity="item.minQuantity"
                :multiplier="item.multiplier"
                @quantityUpdated="productQuantityUpdated(order)"
            />
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PurchaseField from '@/components/Field/PurchaseField'
import { stringUtils } from '@/mixins/stringUtils'
import PricePerUnit from '@/components/products/PricePerUnit.vue'
import { productUtils } from '@/mixins/productUtils'

export default {
  name: 'PaniereInCart',
  components: { PricePerUnit, PurchaseField },
  mixins: [stringUtils, productUtils],
  props: {
    order: {
      type: Object,
      required: true
    },
    notEditable: {
      type: Boolean,
      default: false
    },
    showRealTotal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Nome',
          value: 'name'
        },
        // { text: 'Descrizione', value: 'description' },
        {
          text: 'Fornitore',
          value: 'supplierBusinessName'
        },
        {
          text: 'Prezzo',
          value: 'price'
        },
        {
          text: 'Quantità',
          value: 'purchased'
        },
        {
          text: 'Prezzo totale',
          value: 'totalPrice'
        },
        {
          text: 'Disponibilità',
          value: 'notAvailable',
          hide: !this.notEditable
        }
      ].filter(h => !h.hide)
    }
  },
  methods: {
    async productQuantityUpdated (order) {
      const response = await this.$http.get('v1/carts/panieri/' + order.id)
      Object.assign(order, response.data)
    },
    productClicked (paniereProduct) {
      if (this.notEditable) return
      this.$router.push('prodotti/' + paniereProduct.productId)
    }
  }
}
</script>

<style scoped>

</style>
