<template>
  <div id="SubCategoriesPage" class="mt-2">
    <v-breadcrumbs :items="path">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item exact :to="item.href">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <h1 class="display-2 text-center" v-if="category">{{ category.name }}</h1>
    <v-container>
      <v-row v-if="category && category.childCategories.length" class="mt-1">
        <v-col
          v-for="subCategory in category.childCategories"
          :key="subCategory.id"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card class="mb-1 mx-auto text-center grey lighten-5" v-bind:class="{'grey lighten-4' : hover, 'on-hover': hover}" :elevation="hover ? 0 : 0" max-width="20em" @click="goToUnderCategory(subCategory.id)">
                <div class="card-container">
                  <div class="card-image-container">
                    <div class="card-image primary">
                      <i class="fas fa-box-open white--text"></i>
                    </div>
                  </div>
                  <div class="card-text pa-1">
                    <v-card-title class="justify-start world-divided">{{ subCategory.name }}</v-card-title>
                    <v-card-subtitle class="justify-start grey--text world-divided">{{ subCategory.paniereProductsCount }} prodott{{((subCategory.paniereProductsCount > 1) ? "i" : "o") }}</v-card-subtitle>
                  </div>
                </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

    <v-divider v-if="category && category.childCategories.length && products && products.length" class="my-2 mx-6"/>

    <div class="mx-4 mx-md-10 mx-lg-12" v-if="products">
      <div class="row justify-center text-center">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
             v-for="product in products"
             :key="product.id"
        >
          <product-purchase-card :product="product"/>
        </div>
      </div>
    </div>

    <category-dialog
      v-if="category"
      v-model="showCategoryDialog"
      :edit-mode="categoryDialogEditMode"
      :parent-category="category.id"
      @close="showCategoryDialog = false"
      @categoryCreated="reloadComponent"/>

  </div>
</template>

<script>
import CategoryDialog from '@/components/ActionDialog/implementations/CategoryDialog'
import { mapGetters } from 'vuex'
import ProductPurchaseCard from '@/components/ProductCard/ProductPurchaseCard.vue'

export default {
  name: 'SubCategories',
  components: {
    ProductPurchaseCard,
    CategoryDialog
  },
  data () {
    return {
      category: null,
      products: null,
      path: [],

      categoryDialogEditMode: false,
      showCategoryDialog: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    categoryId () {
      return this.$route.params.categoryId
    }
  },
  watch: {
    '$route' (to, from) {
      if (from.params.categoryId !== to.params.categoryId) {
        this.reloadComponent()
      }
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    reloadComponent () {
      this.loadData()
      this.path = []
    },
    goToUnderCategory (category) {
      this.$router.push('/categorie/' + category)
    },
    loadPath () {
      this.pushBreadcrumbsRoute(this.categoryParent)
    },
    pushBreadcrumbsRoute (category) {
      if (!category.parentCategory) {
        this.path = [{
          text: 'Home',
          href: '/categorie'
        }].concat(this.path)
        this.path.push({
          text: this.category.name,
          href: '/categorie/' + this.category.id
        })
        return 0
      } else {
        this.path = [{
          text: category.parentCategory.name,
          href: '/categorie/' + category.parentCategory.id
        }].concat(this.path)
        this.pushBreadcrumbsRoute(category.parentCategory)
      }
    },
    async loadData () {
      try {
        const catRes = await this.$http.get('/v1/categories/' + this.categoryId)
        this.category = catRes.data
        const prodRes = await this.$http.get(`/v1/categories/${this.categoryId}/products`)
        this.products = prodRes.data
        const pathRes = await this.$http.get(`/v1/categories/${this.categoryId}/path`)
        this.categoryParent = pathRes.data
        this.loadPath()
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>
  .card-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 70px auto;
  }
  .card-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
  .card-image{
    border-radius: 5px;
  }
  .card-image i{
    padding: 15px;
  }
  .card-text{
    text-align: start;
  }
  .world-divided {
    word-break: break-word;
  }
</style>
