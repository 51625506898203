<template src="./UserRegistration.html" />

<script>
import LoadingDialog from '@/components/ActionDialog/implementations/LoadingDialog'

export default {
  name: 'UserRegistration',
  components: {
    LoadingDialog
  },
  data () {
    return {
      data: {
        email: null,
        password: null,
        confirmPassword: null
      },

      showPassword: false,
      showConfirmPassword: false,
      showLoading: false
    }
  },
  methods: {
    async register () {
      const valid = await this.$refs.inputObserver.validate()
      if (!valid) return
      if (this.data.password !== this.data.confirmPassword) {
        await this.$dialog.notify.error('Le password non corrispondono')
        this.showLoading = false
        return
      }
      this.showLoading = true
      try {
        const response = await this.$http.post('v1/users/register', this.data)
        this.showLoading = false
        this.$store.commit('loginUser', response.data)
        await this.$router.push('/')
      } catch (e) {
        this.showLoading = false
      }
    }
  }
}
</script>
