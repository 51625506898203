<template>
  <div>
    <v-app-bar color="primary" clipped-left app dark flat>
      <v-app-bar-nav-icon v-if="enableSidebar" @click.stop="showSidebar = !showSidebar">
        <template v-slot:default>
          <v-icon size="1.5em" class="fas fa-bars"/>
        </template>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-btn text
               to="/"
               v-text="$vuetify.breakpoint.mobile ? 'PDO' : 'PDO Basso Garda'"
        />
      </v-toolbar-title>
      <v-spacer/>
      <div>
        <v-btn outlined to="/login" v-if="!isLogged">
          <i class="fas fa-sign-in-alt ms-1"></i>
          Accedi
        </v-btn>
        <span v-else>
          <v-btn to="/amministrazione" class="mr-2" v-if="!enableSidebar && isAdmin"
                 text :icon="$vuetify.breakpoint.mobile"
          >
            <i class="fas fa-tools"></i>
            <span class="ms-1" v-if="!$vuetify.breakpoint.mobile">Amministrazione</span>
          </v-btn>
          <v-menu offset-y left close-on-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :outlined="!$vuetify.breakpoint.mobile"
                  :icon="$vuetify.breakpoint.mobile"
                  :x-large="$vuetify.breakpoint.mobile"
                  v-bind="attrs"
                  v-on="on"
              >
                <i class="fas fa-user-alt"/>
                <div v-if="!$vuetify.breakpoint.mobile" class="ml-2">{{ menuText }}</div>
              </v-btn>
            </template>
            <v-list class="px-4" width="300">
              <v-list-item v-if="$store.getters.fullName">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ $store.getters.fullName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $store.getters.email }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="isLocalhost">id: {{ $store.getters.id }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else to="/iscriviti/continua">
                <v-list-item-content>
                  Completa la registrazione!
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-divider/>
              </v-list-item>

              <v-list-item to="/consegne">
                <v-list-item-icon>
                  <i class="fas fa-truck"/>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Le mie consegne</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/pagamenti">
                <v-list-item-icon>
                  <i class="fas fa-coins"/>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>I miei pagamenti</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/profilo">
                <v-list-item-icon>
                  <i class="fas fa-user-alt"/>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Profilo</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logoutUser">
                <v-list-item-icon>
                  <i class="fas fa-sign-out-alt"/>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Esci</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon x-large class="ml-3" to="/carrello" link>
            <i class="fas fa-shopping-cart"></i>
          </v-btn>
        </span>
        <v-btn @click="$router.push('/faq');" icon x-large class="ml-3">
          <i class="fas fa-circle-question"></i>
        </v-btn>
      </div>
    </v-app-bar>
    <v-btn v-if="!$vuetify.breakpoint.mobile" @click="$router.push('/faq');" light icon x-large class="mb-16 ml-n1" fab fixed left bottom>
      <i class="fas fa-circle-question"></i>
    </v-btn>
    <v-navigation-drawer v-if="enableSidebar" clipped app v-model="showSidebar" disable-resize-watcher>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-img src="../../assets/logo_pdo.png"></v-img>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Gestionale di amministrazione
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <div v-for="(item, i) in pages"
             :key="i"
        >
          <v-list-item v-if="!item">
            <v-divider/>
          </v-list-item>
          <v-list-item
              v-else-if="!item.children"
              :to="item.path"
              link
          >
            <v-list-item-icon>
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon small>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="child in item.children"
                :key="child.title"
                :to="child.path"
                link
            >
              <v-list-item-icon v-if="child.icon">
                <v-icon small>{{ child.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { isLocalhost } from '@/plugins/axios'

export default {
  name: 'NavBar',
  data () {
    return {
      isLocalhost,
      showSidebar: true,
      pages: [
        {
          title: 'Categorie e prodotti',
          path: '/amministrazione/tuttecategorieprodotti',
          icon: 'fas fa-store'
        },
        {
          title: 'Lista prodotti',
          path: '/amministrazione/prodotti',
          icon: 'fas fa-carrot'
        },
        {
          title: 'Fornitori',
          path: '/amministrazione/fornitori',
          icon: 'fas fa-truck'
        },
        undefined,
        {
          title: 'Panieri attuali',
          path: '/amministrazione/panieri/attuali',
          icon: 'fas fa-box-open'
        },
        {
          title: 'Panieri: storico',
          path: '/amministrazione/panieri/storico',
          icon: 'fas fa-box'
        },
        {
          title: 'Distribuzione',
          path: '/amministrazione/distribuzione/seleziona-postazione',
          icon: 'fas fa-truck-loading'
        },
        undefined,
        {
          title: 'Gestione pagamenti',
          path: '/amministrazione/pagamenti/non-pagati',
          icon: 'far fa-credit-card'
        },
        undefined,
        {
          title: 'Gestione utenti',
          icon: 'fas fa-users',
          children: [
            {
              title: 'Nuovi soci',
              icon: 'fas fa-handshake',
              path: '/amministrazione/nuovi-soci'
            },
            {
              title: 'Utenti',
              icon: 'fas fa-user',
              path: '/amministrazione/utenti'
            }
          ]
        },
        {
          title: 'Zone',
          path: '/amministrazione/zone',
          icon: 'fas fa-globe-europe'
        },
        undefined,
        {
          title: 'Testing',
          path: '/amministrazione/testing',
          icon: 'fas fa-hammer'
        }
      ].filter(i => !i?.hide)
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isLogged']),
    enableSidebar () {
      return this.isAdmin && this.$route.path.startsWith('/amministrazione')
    },
    menuText: {
      get () {
        if (this.$store.getters.name) {
          return 'Ciao, ' + this.$store.getters.name
        } else {
          return 'Menu'
        }
      },
      set (newVal) {

      }
    }
  },
  methods: {
    ...mapMutations(['logoutUser']),
    openCart () {
      if (this.$route.path !== '/carrello') {
        this.$router.push('/carrello')
      }
    }
  }
}
</script>

<style scoped>

</style>
