<template>
  <div>
    <loading-dialog text="Caricamento in corso" :show="loading"/>

    <v-dialog v-model="show" @click:outside="close" max-width="600px">
      <v-card>
        <v-card-title>
          <h5 class="text-h5">Aggiungi la data di conferma</h5>
        </v-card-title>
            <date-picker
                classes="mx-5 mt-5"
                v-model="date"
                label="Data di conferma"
            />
          <v-card-text class="px-6 pt-1 pb-0">
          </v-card-text>

          <v-card-actions class="pa-3">
            <v-spacer/>
            <v-btn color="blue" text @click="close">
              Chiudi
            </v-btn>
            <v-btn
                @click="submit"
                color="green"
                class="white--text"
            >
              Conferma
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from '@/components/Field/DatePicker'
import LoadingDialog from '@/components/ActionDialog/implementations/LoadingDialog'

export default {
  name: 'ConfirmMembersDialog',
  components: {
    DatePicker,
    LoadingDialog
  },
  props: {
    value: {
      type: Boolean
    },
    selectedMembers: {
      type: Array,
      required: true
    },
    confirmCallback: Function
  },
  data () {
    return {
      date: null,
      loading: false
    }
  },
  computed: {
    show: {
      get () {
        return !!this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  mounted () {
    this.setDefaultDate()
  },
  methods: {
    async submit () {
      if (!this.date) return
      this.loading = true
      const selected = []

      this.selectedMembers.forEach(el => {
        selected.push(el.id)
      })

      try {
        await this.$http.post('v1/users/members/confirm', {
          confirmDate: this.date,
          usersId: selected
        })
        this.close()
        await this.$dialog.notify.success(this.editSuccessMessage)
        this.$emit('submit')
      } catch (e) {
        this.loading = false
      }
    },
    setDefaultDate () {
      const date = new Date()
      this.date = date.getFullYear() + '-' + this.format(date.getMonth()) + '-' + this.format(date.getDay())
    },
    format (el) {
      el = el.toString()
      if (el.length === 1) {
        return '0' + el
      }
      return el
    },
    close () {
      this.show = false
      this.$emit('close')
      this.$refs.dataObserver.reset()
    }
  }
}
</script>

<style scoped>

</style>
